import { useState, useEffect, useContext } from "react";
import {
  deleteMobileCombustion,
  getAllMobileCombustions,
  insertMobileCombustions,
  updateMobileCombustion,
} from "../../../../api/Emissions.api";
import { Box, Button, Grid } from "@mui/material";
import StyledCard from "../../../../components/StyledCard";
import Modal from "../../../../components/Modal";
import { Add, Delete, Edit } from "@mui/icons-material";
import MobileForm from "./MobileForm";
import {
  getCatFuelSource,
  getCatUnitFuel,
  getCatVehicleType,
  getEdificios,
  getEmissionFactors,
  getInvetoryData,
  getSubactivityType,
} from "../../../../api/Core.api";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import { enqueueSnackbar } from "notistack";
import LoadingContext from "../../../../context/LoadingContext";
import { errorHandler } from "../../../../utils/ErrorHandler";
import { GridActionsCellItem } from "@mui/x-data-grid";

const newMobileCombustion = {
  id: "",
  facilityId: "",
  yearId: "",
  description: "",
  activityTypeId: "",
  fuelSourceId: "",
  customEmissionFactorId: "",
  vehicleTypeId: "",
  activity_amount: 0,
  unitId: "",
};

function MobileTable() {
  // const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [mobileData, setMobileData] = useState([]);
  const [facilityIds, setFacilityIds] = useState([]);
  const [years, setYears] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [fuelSources, setFuelSources] = useState([]);
  const [customEmissionFactors, setCustomEmissionFactors] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  // const [fuelSelectData, setFuelSelectData] = useState([]);
  // const [vehicleSelectData, setVehicleSelectData] = useState([]);
  const [units, setUnits] = useState([]);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);

  const [formData, setFormData] = useState(newMobileCombustion);

  const clearForm = () => {
    setFormData(newMobileCombustion);
  };

  const getMobileCombustions = async () => {
    try {
      const res = await getAllMobileCombustions();
      setMobileData(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchUnits = async () => {
    try {
      const res = await getCatUnitFuel();
      setUnits(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchCustomEmissionFactors = async () => {
    try {
      const params = {
        is_customEF: true,
        scope: "S1",
        activity_type: "Mobile Combustion",
      };
      const res = await getEmissionFactors(params);
      setCustomEmissionFactors(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchFuels = async () => {
    try {
      const res = await getCatFuelSource();
      setFuelSources(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchYears = async () => {
    try {
      const res = await getInvetoryData();
      setYears(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchFacilityIds = async () => {
    try {
      const res = await getEdificios();
      setFacilityIds(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchActivityTypes = async () => {
    try {
      const res = await getSubactivityType();
      setActivityTypes(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchVehicleType = async () => {
    try {
      const res = await getCatVehicleType();
      setVehicleTypes(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getAllData = async () => {
    startLoading("Cargando datos de Combustion Movil");
    try {
      await getMobileCombustions();
      await fetchFacilityIds();
      await fetchCustomEmissionFactors();
      await fetchFuels();
      await fetchUnits();
      await fetchYears();
      await fetchActivityTypes();
      await fetchVehicleType();
      stopLoading();
    } catch (error) {
      stopLoading();
      errorHandler(error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleChange = (event) => {
    if (event.target.name === "activity_amount") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
      return;
    }
    if (event.target.name == "activityTypeId") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        fuelSourceId: "",
        customEmissionFactorId: "",
        vehicleTypeId: "",
      });
      return;
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    startLoading("Guardando datos");
    console.log(formData);
    try {
      if (formData.id === "") {
        const res = await insertMobileCombustions(formData);
        const data = res.data;
        setMobileData([...mobileData, data]);
      } else {
        const res = await updateMobileCombustion(formData);
        const data = res.data;
        const idx = mobileData.findIndex((s) => s.id === data.id);
        const newMobile = mobileData;
        Object.keys(newMobile[idx]).forEach((key) => {
          newMobile[idx][key] = data[key];
        });
        setMobileData(newMobile);
      }
      setOpenAddModal(false);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado existosamente",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!openAddModal) {
      clearForm();
    }
  }, [openAddModal]);

  const columns = [
    {
      field: "id",
      headerName: "id",
    },
    {
      field: "year",
      headerName: "Año",
      valueGetter: (params) => {
        return `${params.row.year.year}`;
      },
    },
    {
      field: "description",
      headerName: "Descripcion",
    },
    {
      field: "facility_id",
      headerName: "Planta",
      valueGetter: (params) => {
        return `${params.row.facility_id.nick_name}`;
      },
    },
    {
      field: "custom_emission_factor",
      headerName: "Factor de emision personalizado",
      valueGetter: (params) => {
        return `${
          params.row.custom_emission_factor
            ? params.row.custom_emission_factor.name
            : "-"
        }`;
      },
    },
    {
      field: "fuel_source",
      headerName: "Fuente de combustible",
      valueGetter: (params) => {
        return `${params.row.fuel_source ? params.row.fuel_source.name : "-"}`;
      },
    },
    {
      field: "vehicle_type",
      headerName: "Tipo de vehiculo",
      valueGetter: (params) => {
        return `${
          params.row.vehicle_type ? params.row.vehicle_type.name : "-"
        }`;
      },
    },
    {
      field: "activity_amount",
      headerName: "Monto de la actividad",
    },
    {
      field: "unit",
      headerName: "Unidad",
      valueGetter: (params) => {
        return `${params.row.unit.unit}`;
      },
    },
    {
      field: "co2",
      headerName: "CO2",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "ch4",
      headerName: "CH4",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "n2o",
      headerName: "N2O",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "co2e",
      headerName: "CO2e",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "biofuel_co2",
      headerName: "Biofuel CO2",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "ef",
      headerName: "EF (kgCO2e/unit)",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "source",
      headerName: "Fuente",
      flex: 1,
    },
    {
      field: "action",
      sortable: false,
      headerName: "Acciones",
      autoHeight: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <GridActionsCellItem
              icon={<Edit />}
              label="Editar"
              onClick={() => {
                const row = params.row;
                const mobileObject = {};
                Object.keys(formData).forEach((key) => {
                  mobileObject[key] = row[key];
                });
                setFormData(mobileObject);
                setOpenAddModal(true);
              }}
            />
            <GridActionsCellItem
              icon={<Delete />}
              label="Borrar"
              onClick={() => {
                handleClickDelete(params.id);
              }}
              color="error"
            />
          </>
        );
      },
    },
  ];

  const handleClickDelete = (id) => {
    setOpenDeleteModal(true);
    setDeleteRecord(id);
  };

  const handleDeleteRecord = async () => {
    startLoading("Eliminando registro");
    try {
      await deleteMobileCombustion(deleteRecord);
      setMobileData(mobileData.filter((data) => data.id !== deleteRecord));
      stopLoading();
      setOpenDeleteModal(false);
      enqueueSnackbar({
        message: "Registro eliminado de manera exitosa",
        variant: "success",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error);
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteRecord(null);
    }
  }, [openDeleteModal]);

  return (
    <>
      <StyledCard title="Combustion Movil">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
            m: 2,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              console.log(openAddModal);
              setOpenAddModal(true);
            }}
            sx={{ flex: 0.1 }}
            startIcon={<Add />}
          >
            Agregar
          </Button>
        </Grid>
        {mobileData.length > 0 && (
          <CustomDataGrid
            rows={mobileData}
            columns={columns}
            sortModel={[{ field: "year.year", sort: "desc" }]}
          />
        )}
      </StyledCard>
      <Modal
        openModal={openAddModal}
        title={"Agregar nuevo Mobile Combustion"}
        setOpenModal={setOpenAddModal}
      >
        <MobileForm
          facilityIds={facilityIds}
          fuelSources={fuelSources}
          units={units}
          years={years}
          activityTypes={activityTypes}
          vehicleTypes={vehicleTypes}
          customEmissionFactors={customEmissionFactors}
          formData={formData}
          handleChange={handleChange}
          setFormData={setFormData}
          cancelAction={() => {
            setOpenAddModal(false);
          }}
          SaveAction={handleSubmit}
        />
      </Modal>
      <Modal
        openModal={openDeleteModal}
        title={"Desea borrar este registo?"}
        setOpenModal={setOpenDeleteModal}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" color="error" onClick={handleDeleteRecord}>
            Confirmar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default MobileTable;
