import { SaveAlt } from "@mui/icons-material";
import { Box, Button, FormControl, TextField } from "@mui/material";
import DropDown from "../../../../components/DropDown";
import PropTypes from "prop-types";

function EdificioForm({ formData, empresa, handleChange, saveAction, error }) {
  return (
    <>
      <Box sx={{ my: "10px" }}>
        <FormControl
          fullWidth
          sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
        >
          <TextField
            id="nick_name"
            name="nick_name"
            value={formData.nick_name}
            label="Alias"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("nick_name")}
            helperText={error.hasOwnProperty("nick_name") && error.nick_name}
          />
          <TextField
            id="calle"
            name="calle"
            value={formData.calle}
            label="Calle"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("calle")}
            helperText={error.hasOwnProperty("calle") && error.calle}
          />
          <TextField
            id="num_int"
            name="num_int"
            value={formData.num_int}
            label="Numero Interior"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("num_int")}
            helperText={error.hasOwnProperty("num_int") && error.num_int}
          />
          <TextField
            id="num_ext"
            name="num_ext"
            value={formData.num_ext}
            label="Numero Exterior"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("num_ext")}
            helperText={error.hasOwnProperty("num_ext") && error.num_ext}
          />
          <TextField
            id="colonia"
            name="colonia"
            value={formData.colonia}
            label="Colonia"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("colonia")}
            helperText={error.hasOwnProperty("colonia") && error.colonia}
          />
          <TextField
            id="delegacion"
            name="delegacion"
            value={formData.delegacion}
            label="Delegacion/Municipio"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("delegacion")}
            helperText={error.hasOwnProperty("delegacion") && error.delegacion}
          />
          <TextField
            id="estado"
            name="estado"
            value={formData.estado}
            label="Estado"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("estado")}
            helperText={error.hasOwnProperty("estado") && error.estado}
          />
          <TextField
            id="cp"
            name="cp"
            value={formData.cp}
            label="Codigo Postal"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("cp")}
            helperText={error.hasOwnProperty("cp") && error.cp}
          />
          <TextField
            id="lat"
            name="lat"
            value={formData.lat}
            label="Latitud"
            type="number"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("lat")}
            helperText={error.hasOwnProperty("lat") && error.lat}
          />
          <TextField
            id="lon"
            name="lon"
            value={formData.lon}
            label="Longitud"
            type="number"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("lon")}
            helperText={error.hasOwnProperty("lon") && error.lon}
          />
          <DropDown
            id="empresa_id"
            data={empresa}
            label="Empresa"
            value={formData.empresa_id}
            onChange={handleChange}
          />
          <Button
            variant="outlined"
            sx={{ mx: 1 }}
            startIcon={<SaveAlt />}
            onClick={saveAction}
            size="small"
          >
            Guardar
          </Button>
        </FormControl>
      </Box>
    </>
  );
}

EdificioForm.defaultProps = {
  empresa: {},
  error: {},
};

EdificioForm.prototype = {
  saveAction: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  empresa: PropTypes.object.isRequired,
  error: PropTypes.object,
};
export default EdificioForm;
