import React, { useContext, useState } from "react";
import StyledCard from "../../../../components/StyledCard";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import Modal from "../../../../components/Modal";
import { Box, Button } from "@mui/material";
import EdificioForm from "./EdificioForm";
import AuthContext from "../../../../context/AuthContext";
import { enqueueSnackbar } from "notistack";
import { insertEdificio, updateEdificio } from "../../../../api/Core.api";
import LoadingContext from "../../../../context/LoadingContext";
import { errorHandler } from "../../../../utils/ErrorHandler";

const columns = [
  {
    field: "nick_name",
    headerName: "Alias",
    flex: 1,
  },
  {
    field: "calle",
    headerName: "Calle",
    flex: 1,
  },
  {
    field: "num_int",
    headerName: "Numero Interior",
    flex: 1,
  },
  {
    field: "num_ext",
    headerName: "Numero Exterior",
    flex: 1,
  },
  {
    field: "colonia",
    headerName: "Colonia",
    flex: 1,
  },
  {
    field: "delegacion",
    headerName: "Municipio/Delegacion",
    flex: 1,
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
  },
  {
    field: "cp",
    headerName: "Codigo Postal",
    flex: 1,
  },
  {
    field: "lat",
    headerName: "Latitud",
    flex: 1,
  },
  {
    field: "lon",
    headerName: "Longitud",
    flex: 1,
  },
  {
    field: "empresa",
    headerName: "Empresa",
    valueGetter: (params) => {
      return `${params.row.empresa.nombre_comercial}`;
    },
    flex: 1,
  },
];

function EdificioTable({ data, empresa }) {
  const { user } = useContext(AuthContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [edificios, setEdificios] = useState(data);
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [formData, setFormData] = useState({
    id: null,
    empresa_id: null,
    nick_name: "",
    calle: "",
    num_int: null,
    num_ext: null,
    colonia: "",
    cp: "",
    delegacion: "",
    estado: "",
    lat: null,
    lon: null,
    empresas_id: "",
  });
  const [errors, setErrors] = useState({});

  const clearForm = () => {
    setFormData({
      id: "",
      empresa_id: "",
      nick_name: "",
      calle: "",
      num_int: "",
      num_ext: "",
      colonia: "",
      cp: "",
      delegacion: "",
      estado: "",
      lat: "",
      lon: "",
      empresas_id: "",
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading("Guardando datos");
    try {
      if (formData.id === "") {
        console.log(formData);
        const res = await insertEdificio(formData);
        setEdificios([...edificios, res.data]);
      } else {
        await updateEdificio(formData);
      }
      stopLoading();
      setOpenModal(false);
    } catch (error) {
      stopLoading();
      errorHandler(error)
    }
    setOpenModal(false);
  };

  const handleAdd = () => {
    clearForm();
    setTitleModal("Agregar nuevo edificio");
    setOpenModal(true);
  };

  const handleEdit = (param) => {
    if (!user?.permisos?.find((u) => u === "core.change_edificio")) {
      enqueueSnackbar({
        variant: "error",
        message: "Usuario sin permisos para realizar esta accion",
        autoHideDuration: 8000,
      });
      return;
    }
    setFormData(param.row);
    setTitleModal("Actualizar registro");
    setOpenModal(true);
  };

  return (
    <StyledCard title="Plantas">
      {user?.permisos?.find((u) => u === "core.add_edificio") && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mx: "1em" }}>
          <Button variant="outlined" onClick={handleAdd}>
            Agregar
          </Button>
        </Box>
      )}
      <CustomDataGrid
        rows={edificios}
        columns={columns}
        onRowDoubleClick={handleEdit}
      />
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title={titleModal}
      >
        <EdificioForm
          formData={formData}
          empresa={[{ value: empresa.id, label: empresa.nombre_comercial }]}
          handleChange={handleChange}
          saveAction={handleSubmit}
          error={errors}
        />
      </Modal>
    </StyledCard>
  );
}

export default EdificioTable;
