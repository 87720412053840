import { Backdrop, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import LoadingContext from "../context/LoadingContext";

function LoadingBackdrop({zIndex}) {
  const {loading, message} = useContext(LoadingContext)
  return (
    <Backdrop sx={{ color: "#fff", zIndex: zIndex }} open={loading}>
      <CircularProgress color="inherit" />
      <Typography variant="h6" mx={4}>
        {message || 'Cargando datos'}
      </Typography>
    </Backdrop>
  );
}

LoadingBackdrop.prototype = {
  zIndex: PropTypes.number,
};

LoadingBackdrop.defaultProps = {
  zIndex: 99999,
};

export default LoadingBackdrop;
