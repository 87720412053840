import { Box, Button, Grid, TextField } from "@mui/material";

export default function UserRegisterForm({
  formData,
  handleChange,
  handleSubmit,
  error,
}) {
  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              required
              fullWidth
              id="username"
              label="Nombre de usuario"
              name="username"
              onChange={handleChange}
              autoComplete="username"
              variant="standard"
              value={formData.username}
              error={error.hasOwnProperty("username")}
              helperText={error.hasOwnProperty("username") && error.username}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="Nombre"
              name="first_name"
              required
              fullWidth
              id="first_name"
              label="Nombre"
              onChange={handleChange}
              variant="standard"
              value={formData.first_name}
              error={error.hasOwnProperty("first_name")}
              helperText={
                error.hasOwnProperty("first_name") && error.first_name
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="last_name"
              label="Apellidos"
              name="last_name"
              onChange={handleChange}
              variant="standard"
              autoComplete="Apellidos"
              value={formData.last_name}
              error={error.hasOwnProperty("last_name")}
              helperText={error.hasOwnProperty("last_name") && error.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Correo electronico"
              name="email"
              type="email"
              onChange={handleChange}
              variant="standard"
              autoComplete="email"
              value={formData.email}
              error={error.hasOwnProperty("email")}
              helperText={error.hasOwnProperty("email") && error.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              onChange={handleChange}
              variant="standard"
              autoComplete="new-password"
              value={formData.password}
              error={error.hasOwnProperty("password")}
              helperText={error.hasOwnProperty("password") && error.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="password2"
              label="Confirmar contraseña"
              type="password"
              id="password2"
              onChange={handleChange}
              variant="standard"
              autoComplete="confirm-password"
              value={formData.password2}
              error={error.hasOwnProperty("password2")}
              helperText={error.hasOwnProperty("password2") && error.password2}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Registrar
        </Button>
      </Box>
    </Box>
  );
}
