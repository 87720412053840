import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DropDown from "../../../../components/DropDown";

export default function MaterialesCard({ formData, handleDelete }) {
  const certificacionIMMEX = [
    { label: "A", value: 0 },
    { label: "AA", value: 1 },
    { label: "AAA", value: 2 },
  ];

  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <TextField
              id="transferencia_virtual_IMMEX"
              name="transferencia_virtual_IMMEX"
              label={
                "47. ¿Se realizan transferencias virtuales a través del programa IMMEX?"
              }
              type="text"
              value={formData.transferencia_virtual_IMMEX}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <DropDown
              id={"tipo_certificacion_IMMEX"}
              label={
                "48. ¿Con qué tipo de certificación IMMEX cuenta la empresa? "
              }
              value={formData.tipo_certificacion_IMMEX}
              data={certificacionIMMEX}
              size={"small"}
              variant="standard"
              disabled
            />
            <TextField
              id="puede_facturar_desechos"
              name="puede_facturar_desechos"
              label={"49. ¿Puede facturar en México sus desechos?"}
              type="text"
              value={formData.puede_facturar_desechos}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              50. Materiales:
            </Typography>
            {formData.tipos_materiales?.map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "25px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <TextField
                    name="tipo_material"
                    label="Tipo de material"
                    type="text"
                    size="small"
                    value={data.tipo_material}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="volumen"
                    label="Volumen"
                    type="text"
                    size="small"
                    value={data.volumen}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="periodicidad"
                    label="Periodicidad"
                    type="text"
                    size="small"
                    value={data.periodicidad}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="caracteristicas"
                    label="Caracteristicas"
                    type="text"
                    size="small"
                    value={data.caracteristicas}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                </Box>
              );
            })}
            <TextField
              id="clasifica_materiales"
              name="clasifica_materiales"
              label={
                "51. ¿Clasificas tus materiales por composición físico-química?"
              }
              type="text"
              value={formData.clasifica_materiales}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="mezcla_materiales"
              name="mezcla_materiales"
              label={
                "52. En caso de que la respuesta sea NO, ¿se mezclan los materiales?"
              }
              type="text"
              value={formData.mezcla_materiales}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="que_mezclan"
              label={"53. Indica con qué se mezclan:"}
              type="text"
              value={formData.que_mezclan}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
              disabled
            />
            <TextField
              id="maquinaria_procesar_desecho"
              name="maquinaria_procesar_desecho"
              label={
                "54. ¿Cuenta con maquinaria para procesar el material de desecho?"
              }
              type="text"
              value={formData.maquinaria_procesar_desecho}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="especifica_maquinaria"
              label={"55. Especifica:"}
              type="text"
              value={formData.especifica_maquinaria}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              id="cuenta_espacio_maquinaria"
              name="cuenta_espacio_maquinaria"
              label={
                "56. En caso de que la respuesta sea NO, ¿cuenta con espacio para poner maquinaria?"
              }
              type="text"
              value={formData.cuenta_espacio_maquinaria}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="especifica_espacio"
              label={
                "57. En caso de que su respuesta sea SI, especificar espacio:"
              }
              type="text"
              value={formData.especifica_espacio}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              id="almacena_materiales_resguardo"
              name="almacena_materiales_resguardo"
              label={"58. ¿Almacena sus materiales en un lugar resguardado?"}
              type="text"
              value={formData.almacena_materiales_resguardo}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="condiciones"
              label={"59. Si su respuesta es NO, ¿en qué condiciones están?"}
              type="text"
              value={formData.condiciones}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              name="razon_tiempo_almacenamiento"
              label={"60. Especificar razón y tiempo de almacenamiento:"}
              type="text"
              value={formData.razon_tiempo_almacenamiento}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              name="certificaciones_procesos"
              label={
                "61. ¿Cuenta con algún tipo de certificaciones en sus procesos? ¿Cuáles?"
              }
              type="text"
              value={formData.certificaciones_procesos}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              name="comentarios"
              label={"62. Comentarios"}
              type="text"
              value={formData.comentarios}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="material"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
