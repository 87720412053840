import { SaveAlt } from "@mui/icons-material"
import { Button, Card, CardContent, FormControl, Grid, TextField } from "@mui/material"
import DropDown from "../../../../components/DropDown"


function PurchasedElectricityForm({formData,handleChange,SaveAction,facilityIds,years,typesEFs,calculationApproachs,customEmissionFactors,units}) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <FormControl fullWidth>
          <TextField 
            error={false}
            id="id"
            name="id"
            value={formData.id}
            size={"small"}
            onChange={handleChange}
            disabled
            sx={{display:"none"}}
            />
            <DropDown
              id={"yearId"}
              label={"Año"}
              value={formData.yearId}
              onChange={handleChange}
              data={years}
              size={"small"}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"facilityId"}
              label={"Planta"}
              value={formData.facilityId}
              onChange={handleChange}
              data={facilityIds}
              size={"small"}
              disabled={formData.id!==""}
            />
              <TextField
                error={false}
                id="amount"
                name="amount"
                label={"Monto"}
                type="number"
                value={formData.amount}
                size={"small"}
                onChange={handleChange}
              />
            <DropDown
              id={"unitId"}
              label={"Unidad"}
              value={formData.unitId}
              onChange={handleChange}
              data={units}
              size={"small"}
            />
            <DropDown
              id={"calc_approachId"}
              label={"Metodo de calculo"}
              value={formData.calc_approachId}
              onChange={handleChange}
              data={calculationApproachs}
              size={"small"}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"type_EFId"}
              label={"Tipo de factor de emision"}
              value={formData.type_EFId}
              onChange={handleChange}
              data={typesEFs}
              size={"small"}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"custom_EFId"}
              label={"Factor de emision personalizado"}
              value={formData.custom_EFId}
              onChange={handleChange}
              data={customEmissionFactors}
              size={"small"}
              disabled={formData.id!==""}
            />
          </FormControl>
          <Grid justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              sx={{ mx: 1 }}
              startIcon={<SaveAlt />}
              onClick={SaveAction}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PurchasedElectricityForm