import { Box, Button, Grid } from "@mui/material";
import TransportationForm from "../components/TransportationForm";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import Modal from "../../../../components/Modal";
import StyledCard from "../../../../components/StyledCard";
import { useContext, useEffect, useState } from "react";
import {
  getCategory,
  getEFDataset,
  getEdificios,
  getEmissionFactors,
  getInvetoryData,
  getModelTransportation,
  getS3ActivityTypes,
  getS3VehicleTypes,
  getTransportationUnit,
} from "../../../../api/Core.api";
import LoadingContext from "../../../../context/LoadingContext";
import { Add, Delete } from "@mui/icons-material";
import {
  deleteTransportation,
  getAllTransportation,
  insertTransportation,
} from "../../../../api/Emissions.api";
import { enqueueSnackbar } from "notistack";
import { errorHandler } from "../../../../utils/ErrorHandler";
import { GridActionsCellItem } from "@mui/x-data-grid";



function TransportationTable() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [transportationData, setTransportationData] = useState([]);
  const [facilityIds, setFacilityIds] = useState([]);
  const [years, setYears] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [modeTransports, setModeTransports] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [efDataset, setEfDataset] = useState([]);
  const [customEmisionFactors, setCustomEmisionFactors] = useState([]);
  const [unitMeasurements, setUnitMeasurements] = useState([]);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [formData, setFormData] = useState({
    facilityId: "",
    yearId: "",
    description: "",
    category_id: "",
    ef_dataset_id: "",
    mode_transport_id: "",
    activity_type_id: "",
    custom_emission_factorID: "",
    vehicle_typeId: "",
    amount_activity: 0,
    unit_measurement_id: "",
  });

  const fetchYears = async () => {
    try {
      const res = await getInvetoryData();
      setYears(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchFacilityIds = async () => {
    try {
      const res = await getEdificios();
      setFacilityIds(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchActivityTypes = async () => {
    try {
      const res = await getS3ActivityTypes();
      setActivityTypes(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchCategorys = async () => {
    try {
      const res = await getCategory();
      setCategorys(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchUnitMeasurements = async () => {
    try {
      const res = await getTransportationUnit();
      setUnitMeasurements(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchVehicleTypes = async () => {
    try {
      const res = await getS3VehicleTypes();
      setVehicleTypes(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchCustomEmissionFactors = async () => {
    try {
      const params = { is_customEF: true, scope: "S3" };
      const res = await getEmissionFactors(params);
      setCustomEmisionFactors(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchModeTransports = async () => {
    try {
      const res = await getModelTransportation();
      setModeTransports(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchEFDataset = async () => {
    try {
      const res = await getEFDataset();
      setEfDataset(res.data);
    } catch (error) {
      throw error;
    }
  };
  const loadTransportationData = async () => {
    try {
      const res = await getAllTransportation();
      setTransportationData(res.data);
    } catch (error) {
      throw error;
    }
  };

  const getAllData = async () => {
    startLoading("Cargando datos de Transporte");
    try {
      await fetchYears();
      await fetchFacilityIds();
      await fetchEFDataset();
      await fetchActivityTypes();
      await fetchVehicleTypes();
      await fetchCategorys();
      await fetchModeTransports();
      await fetchUnitMeasurements();
      await fetchCustomEmissionFactors();
      await loadTransportationData();
      stopLoading();
    } catch (error) {
      stopLoading();
      console.log(error);
      errorHandler(error)
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const clearForm = () => {
    setFormData({
      facilityId: "",
      yearId: "",
      description: "",
      category_id: "",
      ef_dataset_id: "",
      mode_transport_id: "",
      activity_type_id: "",
      custom_emission_factorID: "",
      vehicle_typeId: "",
      amount_activity: 0,
      unit_measurement_id: "",
    });
  };

  const handleChange = (evt) => {
    if (evt.target.name === "ef_dataset_id") {
      setFormData({
        ...formData,
        [evt.target.name]: evt.target.value,
        custom_emission_factorID: "",
        vehicle_typeId: "",
      });
      return;
    }
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(JSON.stringify(formData));
    startLoading("Guardando datos");
    try {
      const res = await insertTransportation(formData);
      const data = res.data;
      setTransportationData([...transportationData, data]);
      clearForm();
      setOpenAddModal(false);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado existosamente",
      });
    } catch (error) {
      stopLoading();
      errorHandler(error)
    }
  };

  const columns = [
    {
      field:"id",
      headerName:"id",
    },
    {
      field: "year",
      headerName: "Año",
      valueGetter: (params) => {
        return `${params.row.year.year}`;
      },
    },
    {
      field: "description",
      headerName: "Descripcion",
    },
    {
      field: "facility_id",
      headerName: "Planta",
      valueGetter: (params) => {
        return `${params.row.facility_id.nick_name}`;
      },
    },
    {
      field: "category",
      headerName: "Categoria",
      valueGetter: (params) => {
        return `${params.row.category.name}`;
      },
    },
    {
      field: "mode_transport",
      headerName: "Modo de transporte",
      valueGetter: (params) => {
        return `${params.row.mode_transport.name}`;
      },
    },
    {
      field: "activity_type",
      headerName: "Tipo de actividad",
      valueGetter: (params) => {
        return `${params.row.activity_type.name}`;
      },
    },
    {
      field: "custom_emission_factor",
      headerName: "Factor de emision personalizado",
      valueGetter: (params) => {
        return `${params.row.custom_emission_factor ? params.row.custom_emission_factor.name:'-'}`;
      },
    },
    {
      field: "vehicle_type",
      headerName: "Tipo de vehiculo",
      valueGetter: (params) => {
        return `${params.row.vehicle_type ? params.row.vehicle_type.name : '-'}`;
      },
    },
    {
      field: "activity_amount",
      headerName: "Monto del tipo de activdad",
    },
    {
      field: "unit_measurement",
      headerName: "Units of Measurement",
      valueGetter: (params) => {
        return `${params.row.unit_measurement.name}`;
      },
    },
    {
      field: "co2",
      headerName: "CO2",
      type: "number",
      valueFormatter:(params) =>{
        if(params.value===0){
          return params.value
        }
        return params.value.toFixed(7)
      },
    },
    {
      field: "ch4",
      headerName: "CH4",
      type: "number",
      valueFormatter:(params) =>{
        if(params.value===0){
          return params.value
        }
        return params.value.toFixed(7)
      },
    },
    {
      field: "n2o",
      headerName: "N2O",
      type: "number",
      valueFormatter:(params) =>{
        if(params.value===0){
          return params.value
        }
        return params.value.toFixed(7)
      },
    },
    {
      field: "co2e",
      headerName: "CO2e",
      type: "number",
      valueFormatter:(params) =>{
        if(params.value===0){
          return params.value
        }
        return params.value.toFixed(7)
      },
    },
    {
      field: "biofuel_co2",
      headerName: "Biofuel CO2",
      type: "number",
      valueFormatter:(params) =>{
        if(params.value===0){
          return params.value
        }
        return params.value.toFixed(7)
      },
    },
    {
      field: "ef",
      headerName: "EF (kgCO2e/unit)",
      type: "number",
      valueFormatter:(params) =>{
        if(params.value===0){
          return params.value
        }
        return params.value.toFixed(7)
      },
    },
    {
      field: "source",
      headerName: "Fuente",
    },
    {
      field: "action",
      sortable: false,
      headerName: "Acciones",
      autoHeight: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {/* <GridActionsCellItem icon={<Edit />} label="Editar" /> */}
            <GridActionsCellItem
              icon={<Delete />}
              label="Borrar"
              onClick={() => {
                handleClickDelete(params.id);
              }}
              color="error"
            />
          </>
        );
      },
    },
  ];

  const handleClickDelete = (id) => {
    setOpenDeleteModal(true);
    setDeleteRecord(id);
  };

  const handleDeleteRecord = async () => {
    startLoading("Eliminando registro");
    try {
      await deleteTransportation(deleteRecord);
      setTransportationData(
        transportationData.filter((data) => data.id !== deleteRecord)
      );
      stopLoading();
      setOpenDeleteModal(false);
      enqueueSnackbar({
        message: "Registro eliminado de manera exitosa",
        variant: "success",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error);
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteRecord(null);
    }
  }, [openDeleteModal]);

  return (
    <>
      <StyledCard title="Transporte">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
            m: 2,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              console.log(openAddModal);
              setOpenAddModal(true);
            }}
            sx={{ flex: 0.1 }}
            startIcon={<Add />}
          >
            Agregar
          </Button>
        </Grid>
        {transportationData.length > 0 && (
          <CustomDataGrid rows={transportationData} columns={columns} sortModel={[{field:'year', sort:'desc'}]} />
        )}
      </StyledCard>
      <Modal
        openModal={openAddModal}
        title={"Agregar nuevo Transportation"}
        setOpenModal={setOpenAddModal}
      >
        <TransportationForm
          facilityIds={facilityIds}
          modeTransports={modeTransports}
          unitMeasurements={unitMeasurements}
          emissionFactorDatasets={efDataset}
          years={years}
          categorys={categorys}
          activityTypes={activityTypes}
          vehicleTypes={vehicleTypes}
          customEmisionFactors={customEmisionFactors}
          formData={formData}
          handleChange={handleChange}
          cancelAction={() => {
            setOpenAddModal(false);
          }}
          SaveAction={handleSubmit}
        />
      </Modal>

      <Modal
        openModal={openDeleteModal}
        title={"Desea borrar este registo?"}
        setOpenModal={setOpenDeleteModal}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" color="error" onClick={handleDeleteRecord}>
            Confirmar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default TransportationTable;
