import React, { useContext, useEffect, useState } from "react";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import { Box, Button, Grid } from "@mui/material";
import StyledCard from "../../../../components/StyledCard";
import Modal from "../../../../components/Modal";
import RefrigerantForm from "./RefrigerantForm";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  getEdificios,
  getInvetoryData,
  getRefrigerantUsed,
} from "../../../../api/Core.api";
import { enqueueSnackbar } from "notistack";
import LoadingContext from "../../../../context/LoadingContext";
import {
  deleteRefrigerant,
  getAllRefrigerant,
  insertRefrigerant,
  updateRefrigerant,
} from "../../../../api/Emissions.api";
import { errorHandler } from "../../../../utils/ErrorHandler";
import { GridActionsCellItem } from "@mui/x-data-grid";

const newRefirgerant = {
  id:"",
  facilityId: "",
  yearId: "",
  refrigerantUsedId: "",
  type_of_ac: "",
  inv_begin: 0.0,
  inv_end: 0.0,
  purchesed_from: 0,
  returned_by_user: 0.0,
  returned_after: 0.0,
  charged_into: 0.0,
  delivered_to: 0.0,
  returned_to: 0.0,
  sent_off_recycle: 0.0,
  sent_off_site: 0.0,
  capacity_partial: 0.0,
  pressure_partial: 0.0,
  pressure_full: 0.0,
}


function RefrigerantTable() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [refrigerantData, setRefrigerantData] = useState([]);
  const [refrigerantUseds, setRefrigerantUseds] = useState([]);
  const [years, setYears] = useState([]);
  const [facilityIds, setFacilityIds] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [formData, setFormData] = useState(newRefirgerant);

  const fetchYears = async () => {
    try {
      const res = await getInvetoryData();
      setYears(res.data.map((u) => ({ label: u.year, value: u.id })));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchFacilityIds = async () => {
    try {
      const res = await getEdificios();
      setFacilityIds(
        res.data.map((f) => ({ label: f.nick_name, value: f.id }))
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchRefrigerantUsed = async () => {
    try {
      const res = await getRefrigerantUsed();
      setRefrigerantUseds(
        res.data.map((f) => ({ label: f.name, value: f.id }))
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const loadRefrigerants = async () => {
    try {
      const res = await getAllRefrigerant();
      setRefrigerantData(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const clearForm = () => {
    setFormData(newRefirgerant);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    startLoading("Guardando datos");
    console.log(formData);
    try {
      if(formData.id===""){
        const res = await insertRefrigerant(formData);
        const data = res.data;
        setRefrigerantData([...refrigerantData, data]);
      }else{
        const res = await updateRefrigerant(formData);
        const data = res.data;
        const idx = refrigerantData.findIndex(r=>r.id === data.id)
        const newRefirgerant = refrigerantData
        Object.keys(newRefirgerant[idx]).forEach(key=>{
          newRefirgerant[idx][key] = data[key];
        })
        setRefrigerantData(newRefirgerant)
      }
      setOpenAddModal(false);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado existosamente",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error)
    }
  };

  useEffect(() => {
    const loadData = async () => {
      startLoading("Cargando datos de Refrigerantes");
      try {
        await fetchYears();
        await fetchFacilityIds();
        await fetchRefrigerantUsed();
        await loadRefrigerants();
        stopLoading();
      } catch (error) {
        stopLoading();
        errorHandler(error)
      }
    };
    loadData();
  }, []);

  const columns = [
    {
      field:"id",
      headerName:"id",
    },
    {
      field: "facility_id",
      headerName: "Planta",
      valueGetter: (params) => {
        return `${params.row.facility_id.nick_name}`;
      },
      flex: 0.25,
    },
    {
      field: "year.year",
      headerName: "Año",
      valueGetter: (params) => {
        return `${params.row.year.year}`;
      },
    },
    {
      field: "type_of_ac",
      headerName: "Tipo de aire acondicionado",
    },
    {
      field: "refrigerant_used",
      headerName: "Refrigerante usado",
      valueGetter: (params) => {
        return `${params.row.refrigerant_used.name}`;
      },
    },
    {
      field: "inv_begin",
      headerName:
      "Invantario de refrigerante (almacenado, no en el equipo) al comienzo del año",
    },
    {
      field: "inv_end",
      headerName:
        "Invantario de refrigerante (almacenado, no en el equipo) al fin del año",
    },
    {
      field: "purchesed_from",
      headerName: "Refrigerante comprado a productores/distribuidores",
    },
    {
      field: "returned_by_user",
      headerName: "Regrigerante devuelto por los usuarios",
    },
    {
      field: "returned_after",
      headerName: "Refrigerante devuelto tras su reciclado o regeneración fuera de las instalaciones",
    },
    {
      field: "charged_into",
      headerName: "Refrigerante cargado en el equipo",
    },
    {
      field: "delivered_to",
      headerName: "Refrigerante suministrado a los usuarios en contenedores",
    },
    {
      field: "returned_to",
      headerName: "Refrigerante devuelto a los productores de refrigerante",
    },
    {
      field: "sent_off_recycle",
      headerName: "Refrigerante enviado fuera de las instalaciones para su reciclado o recuperación",
    },
    {
      field: "sent_off_site",
      headerName: "Refrigerante enviado fuera de las instalaciones para su destrucción",
    },
    {
      field: "co2",
      headerName: "Emisiones equivalentes de CO2 (toneladas)",
    },
    {
      field: "capacity_partial",
      headerName: "Capacidad nominal de los equipos parcialmente cargados",
    },
    {
      field: "pressure_partial",
      headerName: "Densidad o presión** de la carga partical",
    },
    {
      field: "pressure_full",
      headerName: "Densidad o presión** de carga completa",
    },
    {
      field: "charged_into_equipment",
      headerName: "Refrigerante cargado en el equipo Z = WX/Y",
    },
    {
      field: "action",
      sortable: false,
      headerName: "Acciones",
      autoHeight: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <GridActionsCellItem icon={<Edit />} label="Editar" onClick={()=>{
              const row = params.row
              const refrigerantObject = {}
              Object.keys(formData).forEach(key=>{
                refrigerantObject[key] = row[key]
              })
              setFormData(refrigerantObject)
              setOpenAddModal(true);
            }}/>
            <GridActionsCellItem
              icon={<Delete />}
              label="Borrar"
              onClick={() => {
                handleClickDelete(params.id);
              }}
              color="error"
            />
          </>
        );
      },
    },
  ];

  const handleClickDelete = (id) => {
    setOpenDeleteModal(true);
    setDeleteRecord(id);
  };

  const handleDeleteRecord = async () => {
    startLoading("Eliminando registro");
    try {
      await deleteRefrigerant(deleteRecord);
      setRefrigerantData(
        refrigerantData.filter((data) => data.id !== deleteRecord)
      );
      stopLoading();
      setOpenDeleteModal(false);
      enqueueSnackbar({
        message: "Registro eliminado de manera exitosa",
        variant: "success",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error);
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteRecord(null);
    }
  }, [openDeleteModal]);

  useEffect(() => {
    if(!openAddModal){
      clearForm();
    }
  }, [openAddModal]);

  return (
    <>
      <StyledCard title="Refrigerantes">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
            m: 2,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              console.log(openAddModal);
              setOpenAddModal(true);
            }}
            sx={{ flex: 0.1 }}
            startIcon={<Add />}
          >
            Agregar
          </Button>
        </Grid>
        {refrigerantData.length > 0 && (
          <CustomDataGrid columns={columns} rows={refrigerantData} sortModel={[{field:'year.year', sort:'desc'}]} />
        )}
      </StyledCard>

      <Modal
        openModal={openAddModal}
        title={"Agregar nuevo Refrigerant"}
        setOpenModal={setOpenAddModal}
      >
        <RefrigerantForm
          facilityIds={facilityIds}
          years={years}
          refrigerantuseds={refrigerantUseds}
          formData={formData}
          handleChange={handleChange}
          cancelAction={() => {
            setOpenAddModal(false);
          }}
          saveAction={handleSubmit}
        />
      </Modal>
      <Modal
        openModal={openDeleteModal}
        title={"Desea borrar este registo?"}
        setOpenModal={setOpenDeleteModal}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" color="error" onClick={handleDeleteRecord}>
            Confirmar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default RefrigerantTable;
