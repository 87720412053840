import { Marker, Polygon } from "@react-google-maps/api";
import React, { useState } from "react";

function PolygonLabel({ paths, polygonsOption, title, center }) {
  return (
    <>
      <Polygon paths={paths} options={polygonsOption} />
      <Marker position={center} label={title} icon={"deleted"} />
    </>
  );
}

export default PolygonLabel;
