import StyledCard from "../../../../components/StyledCard";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import FilterDensityToolBar from "../../../../components/FilterDensityToolBar";

const columns = [
  {
    field: "name",
    headerName: "Fuel Type",
    flex:0.5
  },
  {
    field: "co2",
    headerName: "CO2 Factor\n\r(kg CO2 per mmBtu)",
    flex:0.25
  },
  {
    field: "ch4",
    headerName: "CH4 Factor\n\r(g CH4 per mmBtu)",
    flex:0.25
  },
  {
    field: "n2o",
    headerName: "N2O Factor\n\r(g N2O per mmBtu)",
    flex:0.25
  },
  {
    field: "biofuel_co2",
    headerName: "Biogenic CO2 Factor\n\r(kg Biogenic CO2 per mmBtu)",
    flex:0.25
  },
  {
    field: "ar4",
    headerName: "AR4\n\r(kgCO2e)",
    flex:0.25
  },
  {
    field: "ar5",
    headerName: "AR5\n\r(kgCO2e)",
    flex:0.25
  },
  {
    field: "num_unit",
    headerName: "Units",
  },
  {
    field: "source",
    headerName: "Source",
    flex:1
  },
];

function TrnsportFactors({ rows }) {
  return (
    <StyledCard title="Statonary Combustion">
      <CustomDataGrid rows={rows} columns={columns} customToolBarComp={FilterDensityToolBar}/>
    </StyledCard>
  );
}

export default TrnsportFactors;
