import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import DropDown from "../../../../components/DropDown";
import { SaveAlt } from "@mui/icons-material";
import { useEffect, useState } from "react";

function MobileForm({
  formData,
  handleChange,
  SaveAction,
  facilityIds,
  years,
  activityTypes,
  fuelSources,
  vehicleTypes,
  units,
  customEmissionFactors,
  setFormData,
}) {
  const [disableCustomEmissionFactor, setDisableCustomEmissionFactor] =
    useState(true);
  const [disableFuelSource, setDisableFuelSource] = useState(false);
  const [disableVehicleType, setdisableVehicleType] = useState(false);
  useEffect(() => {
    const handleDropdowns = () => {
      const activityType = activityTypes.find(
        (at) => at.id === formData.activityTypeId
      );
      if (
        activityType &&
        activityType.name.toLowerCase() === "custom emission factor"
      ) {
        setDisableCustomEmissionFactor(false);
        setDisableFuelSource(true);
        setdisableVehicleType(true);
      } else {
        setDisableCustomEmissionFactor(true);
        setDisableFuelSource(false);
        setdisableVehicleType(false);
      }
    };

    handleDropdowns();
  }, [formData.activityTypeId]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <FormControl fullWidth>
          <TextField 
            error={false}
            id="id"
            name="id"
            value={formData.id}
            size={"small"}
            onChange={handleChange}
            disabled
            sx={{display:"none"}}
            />
            <DropDown
              id={"facilityId"}
              label={"Planta"}
              value={formData.facilityId}
              onChange={handleChange}
              data={facilityIds.map((f) => ({
                label: f.nick_name,
                value: f.id,
              }))}
              size={"small"}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"yearId"}
              label={"Año"}
              value={formData.yearId}
              onChange={handleChange}
              data={years.map((u) => ({ label: u.year, value: u.id }))}
              size={"small"}
              disabled={formData.id!==""}
            />
            <TextField
              id="description"
              name="description"
              value={formData.description}
              label="Descripcion"
              onChange={handleChange}
              size="small"
              disabled={formData.id!==""}
            />
            <DropDown
              id={"activityTypeId"}
              label={"Tipo de actividad"}
              value={formData.activityTypeId}
              onChange={handleChange}
              data={activityTypes.map((a) => ({ label: a.name, value: a.id }))}
              size={"small"}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"fuelSourceId"}
              label={"Fuente de combustible"}
              value={formData.fuelSourceId}
              onChange={handleChange}
              data={fuelSources.map((u) => ({ label: u.name, value: u.id }))}
              size={"small"}
              disabled={disableFuelSource || formData.id!==""}
            />
            <DropDown
              id={"customEmissionFactorId"}
              label={"Factor de emision personalizado"}
              value={formData.customEmissionFactorId}
              onChange={handleChange}
              data={customEmissionFactors.map((u) => ({
                label: u.name,
                value: u.id,
              }))}
              size={"small"}
              disabled={disableCustomEmissionFactor || formData.id!==""}
            />
            <DropDown
              id={"vehicleTypeId"}
              label={"Tipo de vehiculo"}
              value={formData.vehicleTypeId}
              onChange={handleChange}
              data={vehicleTypes
                .filter((c) => c.fuel.id === formData.fuelSourceId)
                .map((c) => ({ label: c.name, value: c.id }))}
              size={"small"}
              disabled={disableVehicleType || formData.id!==""}
            />
            <TextField
              error={false}
              id="activity_amount"
              name="activity_amount"
              label={"Monto de la actividad"}
              type="number"
              value={formData.activity_amount}
              size={"small"}
              onChange={handleChange}
            />
            <DropDown
              id={"unitId"}
              label={"Unidad"}
              value={formData.unitId}
              onChange={handleChange}
              data={units.map((c) => ({ label: c.unit, value: c.id }))}
              size={"small"}
            />
          </FormControl>
          <Grid justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              sx={{ mx: 1 }}
              startIcon={<SaveAlt />}
              onClick={SaveAction}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default MobileForm;
