import PropTypes from "prop-types";
import { MenuItem, Box, TextField } from "@mui/material";

function DropDown({
  data,
  label,
  id,
  value,
  onChange,
  size,
  disabled,
  required,
  helperText,
  variant,
  error,
}) {
  return (
    <Box py={1}>
      <TextField
        error={error}
        label={label}
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        size={size}
        select
        fullWidth
        disabled={disabled}
        required={required}
        helperText={helperText}
        variant={variant}
      >
        <MenuItem value={""} key={0}>
          Seleccionar una opcion
        </MenuItem>
        {data &&
          data.length > 0 &&
          data.map((d, index) => (
            <MenuItem value={d.value} key={index + 1}>
              {d.label}
            </MenuItem>
          ))}
      </TextField>
    </Box>
  );
}

DropDown.defaultProps = {
  size: "normal",
  disabled: false,
  required: false,
  helperText: "",
  variant: "outlined",
  error: false,
};

DropDown.propTypess = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.bool,
};

export default DropDown;
