import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getEdificios } from "../../../api/Core.api";
import DropDown from "../../../components/DropDown";
import { Add, Delete } from "@mui/icons-material";
import GuiaFlujo from "../../../assets/guia_flujo.png";
import LoadingContext from "../../../context/LoadingContext";
import { insertSistemaInterno } from "../../../api/EconomiaCircular.api";
import { enqueueSnackbar } from "notistack";
import { errorHandler } from "../../../utils/ErrorHandler";

function SistemaInternoForm({ setStepCount, stepCount, edificioId }) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [errorMessage, setErrorMessage] = useState({});
  const [materiasPrimas, setMateriasPrimas] = useState([
    {
      materia_prima: "",
      cantidad: "",
      costo: "",
      origen: "",
    },
  ]);
  const [residuos, setResiduos] = useState([
    {
      tipo_residuos: "",
      cantidad: "",
      gestor: "",
      coste: "",
    },
  ]);
  const [aguaResidual, setAguaResidual] = useState([
    {
      origen: "",
      cantidad: "",
      calidad: "",
      coste: "",
    },
  ]);
  const [formData, setFormData] = useState({
    materias_locales: "",
    material_escaso: "",
    son_renovables: "",
    compradas_bajo_estrategia: "",
    nivel_consumo_agua: "",
    caracteristicas_entrada_agua: "",
    utiliza_agua_regenerada: "",
    // pendiente recibo_agua
    medidores_electricos: "",
    capacidad_subestacion_servicio: "",
    iniciativa_energia_renovable: "",
    monitoreo_consumo: "",
    codigo_RED: "",
    movilidad_electrica: "",
    recibo_electricidad: null,
    consumo_energia_termica: "",
    programa_eficiencia_termica: "",
    recibo_gas_natural: null,
    servicios_externos_especializados: "",
    iniciativas_distintas_gestion_actual: "",
    // proporciona_imagen_residuo: "",
    existe_energia_termica_residual: "",
    donde_genera: "",
    cual_es_el_vector: "",
    exedente_espacio: "",
    tejados_desuso: "",
    exedente_logistico: "",
    proceso_logistica_inversa: "",
    recursos_limitantes_in_out: "",
    punto_critico: "",
    mejorables: "",
    minimizacion_recursos: "",
    comentarios: "",
    materias_primas: [],
    residuos: [],
    aguas_residuales: [],
    edificioId: edificioId,
  });
  // const [edificio, setEdificio] = useState([]);
  const [disableMPBtn, setDisableMPBtn] = useState(false);
  const [disableRBtn, setDisableRBtn] = useState(false);
  const [disableARBtn, setDisableARBtn] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };

  const handleAddMateriaPrima = () => {
    const mpArray = [
      ...materiasPrimas,
      {
        materia_prima: "",
        cantidad: "",
        costo: "",
        origen: "",
      },
    ];
    mpArray.length > 1 && setDisableMPBtn(true);
    setMateriasPrimas(mpArray);
  };

  const handleChangeMateriaPrima = (e, i) => {
    const inputData = [...materiasPrimas];
    inputData[i][e.target.name] = e.target.value;
    setMateriasPrimas(inputData);
  };

  const handleDeleteMateriaPrima = (i) => {
    const deleteMateriaPrima = [...materiasPrimas];
    deleteMateriaPrima.length > 1 && deleteMateriaPrima.splice(i, 1);
    deleteMateriaPrima.length < 2 && setDisableMPBtn(false);
    setMateriasPrimas(deleteMateriaPrima);
  };

  useEffect(() => {
    const handleChildChange = () => {
      setFormData({
        ...formData,
        materias_primas: materiasPrimas,
        residuos: residuos,
        aguas_residuales: aguaResidual,
      });
    };
    handleChildChange();
  }, [materiasPrimas, residuos, aguaResidual]);

  const handleAddResiduo = () => {
    const residuosArray = [
      ...residuos,
      { tipo_residuos: "", cantidad: "", gestor: "", coste: "" },
    ];
    residuosArray.length > 1 && setDisableRBtn(true);
    setResiduos(residuosArray);
  };

  const handleChangeResiduo = (e, i) => {
    const inputData = [...residuos];
    inputData[i][e.target.name] = e.target.value;
    setResiduos(inputData);
  };

  const handleDeleteResiduo = (i) => {
    const deleteResiduo = [...residuos];
    deleteResiduo.length > 1 && deleteResiduo.splice(i, 1);
    deleteResiduo.length < 2 && setDisableRBtn(false);
    setResiduos(deleteResiduo);
  };

  const handleAddAguaResidual = () => {
    const aguaResidualArray = [
      ...aguaResidual,
      {
        origen: "",
        cantidad: "",
        calidad: "",
        coste: "",
      },
    ];
    aguaResidualArray.length > 1 && setDisableARBtn(true);
    setAguaResidual(aguaResidualArray);
  };

  const handleChangeAguaResidual = (e, i) => {
    const inputData = [...aguaResidual];
    inputData[i][e.target.name] = e.target.value;
    setAguaResidual(inputData);
  };

  const handleDeleteAguaResidual = (i) => {
    const deleteAguaResidual = [...aguaResidual];
    deleteAguaResidual.length > 1 && deleteAguaResidual.splice(i, 1);
    deleteAguaResidual.length < 2 && setDisableARBtn(false);
    setAguaResidual(deleteAguaResidual);
  };

  // useEffect(() => {
  //   const loadEdificio = async () => {
  //     try {
  //       const res = await getEdificios("sistemaInterno");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificio();
  // }, []);

  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertSistemaInterno(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      setErrorMessage(error.response?.data);
      console.log(error);
      errorHandler(error)
    }
  };
  return (
    <>
      <>
        <CardContent>
          <Grid container spacing={2} sx={{ p: 4 }}>
            <FormControl fullWidth>
              <Typography variant="h5">Sistema Interno</Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={GuiaFlujo}
                  alt=""
                  style={{ maxWidth: "700px", width: "fit-content" }}
                />
              </Box>
              <Typography variant="h6">Inputs | Entradas</Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                12. Materias primas principales/críticas:
              </Typography>
              {materiasPrimas.map((data, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      gap: "25px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 5,
                    }}
                  >
                    <TextField
                      name="materia_prima"
                      label="Materia prima"
                      type="text"
                      size="small"
                      value={data.materia_prima}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeMateriaPrima(e, i);
                      }}
                    />
                    <TextField
                      name="cantidad"
                      label="Cantidad"
                      type="text"
                      size="small"
                      value={data.cantidad}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeMateriaPrima(e, i);
                      }}
                    />
                    <TextField
                      name="costo"
                      label="Costo"
                      type="text"
                      size="small"
                      value={data.costo}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeMateriaPrima(e, i);
                      }}
                    />
                    <TextField
                      name="origen"
                      label="Origen"
                      type="text"
                      size="small"
                      value={data.origen}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeMateriaPrima(e, i);
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ flexGrow: 1 }}
                      onClick={(e) => {
                        handleDeleteMateriaPrima(i);
                      }}
                      disabled={!disableMPBtn}
                    >
                      <Delete />
                    </Button>
                  </Box>
                );
              })}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => handleAddMateriaPrima()}
                  sx={{ marginBottom: 5 }}
                >
                  <Add />
                  <Typography>Agregar materia prima</Typography>
                </Button>
              </Box>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("materias_locales")
                }
                // error={false}
                id="materias_locales"
                name="materias_locales"
                label={"13. ¿Las materias primas podrían ser locales?"}
                type="text"
                value={formData.materias_locales}
                size={"small"}
                onChange={handleChange}
                fullWidth
                variant="standard"
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("materias_locales") &&
                  errorMessage.materias_locales
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.materias_locales}
                    name="materias_locales"
                    id="materias_locales"
                    onChange={handleChange}
                  />
                }
                label="¿Las materias primas podrían ser locales?"
              /> */}
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("material_escaso")
                }
                // error={false}
                id="material_escaso"
                name="material_escaso"
                label={
                  "14. ¿Alguna de las materias primas está considerado material escaso?"
                }
                type="text"
                value={formData.material_escaso}
                size={"small"}
                onChange={handleChange}
                fullWidth
                variant="standard"
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("material_escaso") &&
                  errorMessage.material_escaso
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.material_escaso}
                    name="material_escaso"
                    id="material_escaso"
                    onChange={handleChange}
                  />
                }
                label="¿Alguna de las materias primas está considerado material escaso?"
              /> */}
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("son_renovables")
                }
                // error={false}
                id="son_renovables"
                name="son_renovables"
                label={"15. ¿Las materias primas podrían ser renovables?"}
                type="text"
                value={formData.son_renovables}
                size={"small"}
                onChange={handleChange}
                fullWidth
                variant="standard"
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("son_renovables") &&
                  errorMessage.son_renovables
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.son_renovables}
                    name="son_renovables"
                    id="son_renovables"
                    onChange={handleChange}
                  />
                }
                label="¿Las materias primas podrían ser renovables?"
              /> */}
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("son_recicladas")
                }
                // error={false}
                id="son_recicladas"
                name="son_recicladas"
                label={"16. ¿Las materias primas podrían ser recicladas?"}
                type="text"
                value={formData.son_recicladas}
                size={"small"}
                onChange={handleChange}
                fullWidth
                variant="standard"
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("son_recicladas") &&
                  errorMessage.son_recicladas
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.son_recicladas}
                    name="son_recicladas"
                    id="son_recicladas"
                    onChange={handleChange}
                  />
                }
                label="¿Las materias primas podrían ser recicladas?"
              /> */}
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("compradas_bajo_estrategia")
                }
                // error={false}
                id="compradas_bajo_estrategia"
                name="compradas_bajo_estrategia"
                label={
                  "17. ¿Se realizan compras bajo alguna estrategia de sostenibilidad?"
                }
                type="text"
                value={formData.compradas_bajo_estrategia}
                size={"small"}
                onChange={handleChange}
                fullWidth
                variant="standard"
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("compradas_bajo_estrategia") &&
                  errorMessage.compradas_bajo_estrategia
                }
              />

              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.compradas_bajo_estrategia}
                    name="compradas_bajo_estrategia"
                    id="compradas_bajo_estrategia"
                    onChange={handleChange}
                  />
                }
                label="¿Se realizan compras bajo alguna estrategia de sostenibilidad?"
              /> */}
              <Typography variant="subtitle1" fontWeight="bold">
                Consumo de Agua*:
              </Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("nivel_consumo_agua")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("nivel_consumo_agua") &&
                  errorMessage.nivel_consumo_agua
                }
                id="nivel_consumo_agua"
                name="nivel_consumo_agua"
                label={"18. ¿Qué nivel de consumo de agua tienen?"}
                type="text"
                value={formData.nivel_consumo_agua}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("caracteristicas_entrada_agua")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("caracteristicas_entrada_agua") &&
                  errorMessage.caracteristicas_entrada_agua
                }
                id="caracteristicas_entrada_agua"
                name="caracteristicas_entrada_agua"
                label={"19. ¿Qué características debe tener el agua de entrada?"}
                type="text"
                value={formData.caracteristicas_entrada_agua}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("utiliza_agua_regenerada")
                }
                // error={false}
                id="utiliza_agua_regenerada"
                name="utiliza_agua_regenerada"
                label={"20. ¿Podrían utilizar agua regenerada?"}
                type="text"
                value={formData.utiliza_agua_regenerada}
                size={"small"}
                onChange={handleChange}
                fullWidth
                variant="standard"
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("utiliza_agua_regenerada") &&
                  errorMessage.utiliza_agua_regenerada
                }
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.utiliza_agua_regenerada}
                    name="utiliza_agua_regenerada"
                    id="utiliza_agua_regenerada"
                    onChange={handleChange}
                  />
                }
                label="¿Podrían utilizar agua regenerada? "
              /> */}
              <Typography variant="subtitle1" fontWeight="bold">
                Energía Eléctrica*:
              </Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("medidores_electricos")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("medidores_electricos") &&
                  errorMessage.medidores_electricos
                }
                id="medidores_electricos"
                name="medidores_electricos"
                label={"21. Con cuántos servicios (medidores) se cuenta:"}
                type="text"
                value={formData.medidores_electricos}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("capacidad_subestacion_servicio")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty(
                    "capacidad_subestacion_servicio"
                  ) &&
                  errorMessage.capacidad_subestacion_servicio
                }
                id="capacidad_subestacion_servicio"
                name="capacidad_subestacion_servicio"
                label={"22. Capacidad de subestación por servicio [kVAs]:"}
                type="text"
                value={formData.capacidad_subestacion_servicio}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("iniciativa_energia_renovable")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("iniciativa_energia_renovable") &&
                  errorMessage.iniciativa_energia_renovable
                }
                id="iniciativa_energia_renovable"
                name="iniciativa_energia_renovable"
                label={
                  "23. ¿Existe interés o alguna iniciativa para el uso de energía renovable? ¿Cuál?"
                }
                type="text"
                value={formData.iniciativa_energia_renovable}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("monitoreo_consumo")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("monitoreo_consumo") &&
                  errorMessage.monitoreo_consumo
                }
                id="monitoreo_consumo"
                name="monitoreo_consumo"
                label={
                  "24. ¿Se cuenta actualmente con monitoreo en tiempo real de consumo?"
                }
                type="text"
                value={formData.monitoreo_consumo}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("codigo_RED")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("codigo_RED") &&
                  errorMessage.codigo_RED
                }
                id="codigo_RED"
                name="codigo_RED"
                label={"25. ¿Cuentan con Código de RED en orden?"}
                type="text"
                value={formData.codigo_RED}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("movilidad_electrica")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("movilidad_electrica") &&
                  errorMessage.movilidad_electrica
                }
                id="movilidad_electrica"
                name="movilidad_electrica"
                label={"26. ¿Existe interés en la movilidad eléctrica?"}
                type="text"
                value={formData.movilidad_electrica}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <Typography variant="subtitle1" fontWeight="bold">
                Energía Térmica*:
              </Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("fuente_energia_termica")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("fuente_energia_termica") &&
                  errorMessage.fuente_energia_termica
                }
                id="fuente_energia_termica"
                name="fuente_energia_termica"
                label={"27. Fuente de energía"}
                type="text"
                value={formData.fuente_energia_termica}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("consumo_energia_termica")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("consumo_energia_termica") &&
                  errorMessage.consumo_energia_termica
                }
                id="consumo_energia_termica"
                name="consumo_energia_termica"
                label={"28. Consumo"}
                type="text"
                value={formData.consumo_energia_termica}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("programa_eficiencia_termica")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("programa_eficiencia_termica") &&
                  errorMessage.programa_eficiencia_termica
                }
                id="programa_eficiencia_termica"
                name="programa_eficiencia_termica"
                label={"29. ¿Hay algún programa de eficiencia? ¿Cuál?"}
                type="text"
                value={formData.programa_eficiencia_termica}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={2}
              />
              <Typography variant="subtitle1" fontWeight="bold">
                Servicios:
              </Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty(
                    "servicios_externos_especializados"
                  )
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty(
                    "servicios_externos_especializados"
                  ) &&
                  errorMessage.servicios_externos_especializados
                }
                id="servicios_externos_especializados"
                name="servicios_externos_especializados"
                label={
                  "30. ¿Con qué servicios especializados externos dispone la empresa? (p.ej. tóner, impresoras, iluminación, transporte de empleados, gestión de la depuradora, etc.)"
                }
                type="text"
                value={formData.servicios_externos_especializados}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={4}
              />
              <Typography variant="h6">Outputs | Salidas</Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                31. Residuos:
              </Typography>
              {residuos.map((data, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      gap: "25px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 5,
                    }}
                  >
                    <TextField
                      name="tipo_residuo"
                      label="Tipo de residuo"
                      type="text"
                      size="small"
                      value={data.tipo_residuo}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeResiduo(e, i);
                      }}
                    />
                    <TextField
                      name="cantidad"
                      label="Cantidad"
                      type="text"
                      size="small"
                      value={data.cantidad}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeResiduo(e, i);
                      }}
                    />
                    <TextField
                      name="gestor"
                      label="Gestor"
                      type="text"
                      size="small"
                      value={data.gestor}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeResiduo(e, i);
                      }}
                    />
                    <TextField
                      name="coste"
                      label="Coste"
                      type="text"
                      size="small"
                      value={data.coste}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeResiduo(e, i);
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ flexGrow: 1 }}
                      onClick={(e) => {
                        handleDeleteResiduo(i);
                      }}
                      disabled={!disableRBtn}
                    >
                      <Delete />
                    </Button>
                  </Box>
                );
              })}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => handleAddResiduo()}
                  sx={{ marginBottom: 5 }}
                >
                  <Add />
                  <Typography>Agregar residuo</Typography>
                </Button>
              </Box>
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("uso_residuos")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("uso_residuos") &&
                  errorMessage.uso_residuos
                }
                id="uso_residuos"
                name="uso_residuos"
                label={
                  "32. ¿Se conoce el destino o uso que el gestor le da a los residuos? ¿Cuál es?"
                }
                type="text"
                value={formData.uso_residuos}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={4}
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty(
                    "iniciativas_distintas_gestion_actual"
                  )
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty(
                    "iniciativas_distintas_gestion_actual"
                  ) &&
                  errorMessage.iniciativas_distintas_gestion_actual
                }
                id="iniciativas_distintas_gestion_actual"
                name="iniciativas_distintas_gestion_actual"
                label={
                  "33. ¿Han pensado alguna vez iniciativas distintas a la gestión actual de estos residuos?"
                }
                type="text"
                value={formData.iniciativas_distintas_gestion_actual}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={4}
              />
              <Typography variant="subtitle1" fontWeight="bold">
                34. Agua residual:
              </Typography>
              {aguaResidual.map((data, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      gap: "25px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 5,
                    }}
                  >
                    <TextField
                      name="origen"
                      label="Origen"
                      type="text"
                      size="small"
                      value={data.origen}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeAguaResidual(e, i);
                      }}
                    />
                    <TextField
                      name="cantidad"
                      label="Cantidad"
                      type="text"
                      size="small"
                      value={data.cantidad}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeAguaResidual(e, i);
                      }}
                    />
                    <TextField
                      name="calidad"
                      label="Calidad"
                      type="text"
                      size="small"
                      value={data.calidad}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeAguaResidual(e, i);
                      }}
                    />
                    <TextField
                      name="coste"
                      label="Coste"
                      type="text"
                      size="small"
                      value={data.coste}
                      variant="standard"
                      sx={{ flexGrow: 10 }}
                      onChange={(e) => {
                        handleChangeAguaResidual(e, i);
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ flexGrow: 1 }}
                      onClick={(e) => {
                        handleDeleteAguaResidual(i);
                      }}
                      disabled={!disableARBtn}
                    >
                      <Delete />
                    </Button>
                  </Box>
                );
              })}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => handleAddAguaResidual()}
                  sx={{ marginBottom: 5 }}
                >
                  <Add />
                  <Typography>Agregar agua residual</Typography>
                </Button>
              </Box>
              <Typography variant="subtitle1" fontWeight="bold">
                Calor residual:
              </Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("existe_energia_termica_residual")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty(
                    "existe_energia_termica_residual"
                  ) &&
                  errorMessage.existe_energia_termica_residual
                }
                id="existe_energia_termica_residual"
                name="existe_energia_termica_residual"
                label={"35. ¿Existen flujos de energía térmica residual?"}
                type="text"
                value={formData.existe_energia_termica_residual}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("donde_genera")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("donde_genera") &&
                  errorMessage.donde_genera
                }
                id="donde_genera"
                name="donde_genera"
                label={"¿Dónde se genera esta energía?"}
                type="text"
                value={formData.donde_genera}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("cual_es_el_vector")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("cual_es_el_vector") &&
                  errorMessage.cual_es_el_vector
                }
                id="cual_es_el_vector"
                name="cual_es_el_vector"
                label={"36. ¿Cuál es el vector (agua/gas/radiación...)?"}
                type="text"
                value={formData.cual_es_el_vector}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("realizo_estudio_eficiencia")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("realizo_estudio_eficiencia") &&
                  errorMessage.realizo_estudio_eficiencia
                }
                id="realizo_estudio_eficiencia"
                name="realizo_estudio_eficiencia"
                label={
                  "37. ¿Se ha realizado algún estudio de eficiencia energética?"
                }
                type="text"
                value={formData.realizo_estudio_eficiencia}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <Typography variant="subtitle1" fontWeight="bold">
                Espacio sobrante:
              </Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("exedente_espacio")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("exedente_espacio") &&
                  errorMessage.exedente_espacio
                }
                id="exedente_espacio"
                name="exedente_espacio"
                label={"38. ¿Existe algún excedente de espacio en la empresa?"}
                type="text"
                value={formData.exedente_espacio}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("tejados_desuso")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("tejados_desuso") &&
                  errorMessage.tejados_desuso
                }
                id="tejados_desuso"
                name="tejados_desuso"
                label={"39. ¿Se dispone de cubiertas o tejados en desuso?"}
                type="text"
                value={formData.tejados_desuso}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <Typography variant="subtitle1" fontWeight="bold">
                Logística:
              </Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("exedente_logistico")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("exedente_logistico") &&
                  errorMessage.exedente_logistico
                }
                id="exedente_logistico"
                name="exedente_logistico"
                label={"40. ¿Existe algún excedente logístico? ¿Cuál?"}
                type="text"
                value={formData.exedente_logistico}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("proceso_logistica_inversa")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("proceso_logistica_inversa") &&
                  errorMessage.proceso_logistica_inversa
                }
                id="proceso_logistica_inversa"
                name="proceso_logistica_inversa"
                label={
                  "41. ¿Se ha estudiado o se realiza algún proceso de logística inversa? ¿Cuál?"
                }
                type="text"
                value={formData.proceso_logistica_inversa}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <Typography variant="h6">Proceso</Typography>
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("recursos_limitantes_in_out")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("recursos_limitantes_in_out") &&
                  errorMessage.recursos_limitantes_in_out
                }
                id="recursos_limitantes_in_out"
                name="recursos_limitantes_in_out"
                label={
                  "42. De los recursos de entrada y salida ¿cuáles consideras que son los recursos limitantes?"
                }
                type="text"
                value={formData.recursos_limitantes_in_out}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("punto_critico")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("punto_critico") &&
                  errorMessage.punto_critico
                }
                id="punto_critico"
                name="punto_critico"
                label={
                  "43. ¿Dónde está el punto crítico? P.ej. volatilidad del precio de la energía eléctrica, el transporte del residuo..."
                }
                type="text"
                value={formData.punto_critico}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("mejorables")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("mejorables") &&
                  errorMessage.mejorables
                }
                id="mejorables"
                name="mejorables"
                label={"44. ¿Cuáles podrían ser mejorables?"}
                type="text"
                value={formData.mejorables}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <TextField
                error={
                  errorMessage &&
                  errorMessage.hasOwnProperty("minimizacion_recursos")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("minimizacion_recursos") &&
                  errorMessage.minimizacion_recursos
                }
                id="minimizacion_recursos"
                name="minimizacion_recursos"
                label={
                  "45. ¿El proceso productivo y el producto han sido ideados teniendo en cuenta la minimización en el uso de los recursos?"
                }
                type="text"
                value={formData.minimizacion_recursos}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
              />
              <Typography variant="h6">46. Otros comentarios</Typography>
              <TextField
                error={
                  errorMessage && errorMessage.hasOwnProperty("comentarios")
                }
                helperText={
                  errorMessage &&
                  errorMessage.hasOwnProperty("comentarios") &&
                  errorMessage.comentarios
                }
                id="comentarios"
                name="comentarios"
                label={""}
                type="text"
                value={formData.comentarios}
                size={"small"}
                onChange={handleChange}
                variant="standard"
                fullWidth
                multiline
                minRows={4}
              />
              {/* <DropDown
                id={"edificioId"}
                label={"Edificio"}
                value={formData.edificioId}
                onChange={handleChange}
                data={edificio.map((e) => ({
                  label: e.nick_name,
                  value: e.id,
                }))}
                size={"small"}
                variant="standard"
                helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
              /> */}
            </FormControl>
          </Grid>
        </CardContent>
        <CardActions>
        <Button variant="outlined" color="info" onClick={handleSubmit}>
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setStepCount(stepCount + 1);
          }}
        >
          Omitir
        </Button>
        <Button variant="outlined" color="success" onClick={()=>{setStepCount(8)}}>
          Finalizar
        </Button>
        </CardActions>
      </>
    </>
  );
}

export default SistemaInternoForm;
