import React from "react";
import StyledCard from "../../../../components/StyledCard";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import FilterDensityToolBar from "../../../../components/FilterDensityToolBar";

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex:1,
  },
  {
    field: "co2",
    headerName: "CO2 Factor",
    flex: 0.25,
  },
  {
    field: "ch4",
    headerName: "CH4",
    flex: 0.25,
  },
  {
    field: "n2o",
    headerName: "N2O",
    flex: 0.25,
  },
  {
    field: "ar4",
    headerName: "AR4",
    flex: 0.25,
  },
  {
    field: "ar5",
    headerName: "AR5",
    flex: 0.25,
  },
  {
    field: "num_unit",
    headerName: "Units",
    flex: 0.45,
  },
  {
    field: "source",
    headerName: "Source",
    flex: 1,
  },
];

function PurchasedElectricityFactors({ rows }) {
  return (
    <StyledCard title="Purchased Electricity">
      <CustomDataGrid columns={columns} rows={rows} customToolBarComp={FilterDensityToolBar}/>
    </StyledCard>
  );
}

export default PurchasedElectricityFactors;
