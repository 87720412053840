import React from "react";
import RefrigerantTable from "./components/RefrigerantTable";
import { Grid } from "@mui/material";

function Refrigerant() {
  return (
    <Grid container>
      <RefrigerantTable></RefrigerantTable>
    </Grid>
  );
}

export default Refrigerant;
