import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import DropDown from "../../../../components/DropDown";

export default function CompromisoEconomiaCircularCard({
  formData,
  handleDelete,
}) {
  const options = [
    { label: "No se menciona", value: 0 },
    { label: "Relevante", value: 1 },
    { label: "Muy relevante", value: 2 },
  ];
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <TextField
              id="tiene_conocimiento"
              name="tiene_conocimiento"
              label={
                "8. ¿La empresa tiene conocimiento sobre lo que es la Simbiosis Industrial, Economía Circular, Eco-diseño?"
              }
              type="text"
              value={formData.tiene_conocimiento}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="existe_estrategia_sostenibilidad"
              name="existe_estrategia_sostenibilidad"
              label={
                "9. ¿Existe estrategia de sostenibilidad/Economía Circular en la empresa?"
              }
              type="text"
              value={formData.existe_estrategia_sostenibilidad}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <DropDown
              id={"relevante_CEO"}
              label={
                "10. ¿Qué tan relevante es para el CEO promover la Economía Circular dentro de la empresa?"
              }
              value={formData.relevante_CEO}
              data={options}
              size={"small"}
              variant="standard"
              disabled
            />
            <DropDown
              id={"relevante_equipo"}
              label={"11. ¿y el equipo directivo?"}
              value={formData.relevante_equipo}
              data={options}
              size={"small"}
              variant="standard"
              disabled
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="compromiso"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
