import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";

export default function AnalisisPreliminarCard({ formData, handleDelete }) {
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <TextField
              id="nombre"
              name="nombre"
              label={"1. Nombre"}
              type="text"
              value={formData.nombre}
              size={"small"}
              //   onChange={handleChange}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="num_empleados"
              name="num_empleados"
              label={"2. Numero de empleados"}
              type="number"
              value={formData.num_empleados}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="facturacion"
              name="facturacion"
              label={"3. Facturación"}
              type="text"
              value={formData.facturacion}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="actividad_principal"
              name="actividad_principal"
              label={"4. Actividad Principal"}
              type="text"
              value={formData.actividad_principal}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="pertence_organismo_empresarial"
              name="pertence_organismo_empresarial"
              label={"5. ¿Pertenece la empresa a algún organismo empresarial?"}
              type="text"
              value={formData.pertence_organismo_empresarial}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="relacionado_otros_negocios"
              name="relacionado_otros_negocios"
              label={
                "6. ¿La empresa tiene relación relevante con otros negocios en la zona?"
              }
              type="text"
              value={formData.relacionado_otros_negocios}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="cual_relacion"
              name="cual_relacion"
              label={"7. ¿Cuál?"}
              type="textarea"
              value={formData.cual_relacion}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={4}
              disabled
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="analisis"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
