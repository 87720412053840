import { Box, Button, Grid, TextField } from "@mui/material";

export default function EmpresaRegisterForm({
  formData,
  handleChange,
  handleSubmit,
  error,
}) {
  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete="RFC"
              name="rfc"
              required
              fullWidth
              id="rfc"
              label="R.F.C."
              onChange={handleChange}
              variant="standard"
              value={formData.first_name}
              error={error.hasOwnProperty("rfc")}
              helperText={error.hasOwnProperty("rfc") && error.rfc}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id="nombre_fiscal"
              label="Nombre fiscal"
              name="nombre_fiscal"
              onChange={handleChange}
              variant="standard"
              autoComplete="Nombre fiscal"
              value={formData.last_name}
              error={error.hasOwnProperty("nombre_fiscal")}
              helperText={error.hasOwnProperty("nombre_fiscal") && error.nombre_fiscal}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              id="nombre_comercial"
              label="Nombre comercial"
              name="nombre_comercial"
              onChange={handleChange}
              autoComplete="nombre comercial"
              variant="standard"
              value={formData.nombre_comercial}
              error={error.hasOwnProperty("nombre_comercial")}
              helperText={error.hasOwnProperty("nombre_comercial") && error.nombre_comercial}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="dom_fiscal"
              label="Domicilio fiscal"
              name="dom_fiscal"
              onChange={handleChange}
              variant="standard"
              autoComplete="Domicilio fiscal"
              value={formData.email}
              error={error.hasOwnProperty("dom_fiscal")}
              helperText={error.hasOwnProperty("dom_fiscal") && error.dom_fiscal}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="contacto"
              label="Contacto"
              id="contacto"
              onChange={handleChange}
              variant="standard"
              autoComplete="Contacto"
              value={formData.password}
              error={error.hasOwnProperty("contacto")}
              helperText={error.hasOwnProperty("contacto") && error.contacto}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="puesto"
              label="Puesto"
              id="puesto"
              onChange={handleChange}
              variant="standard"
              autoComplete="Puesto"
              value={formData.password2}
              error={error.hasOwnProperty("puesto")}
              helperText={error.hasOwnProperty("puesto") && error.puesto}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Guardar y continuar
        </Button>
      </Box>
    </Box>
  );
}
