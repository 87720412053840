import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

export default function BioenergiaCard({ formData, handleDelete }) {
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" fontWeight="bold">
              127. Residuos orgánicos que genera la empresa:{" "}
            </Typography>
            {formData.residuos_organicos?.map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "10px 30px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <TextField
                    id="tipo_residuo"
                    name="tipo_residuo"
                    label={"Tipo de residuo"}
                    type="text"
                    size={"small"}
                    value={data.tipo_residuo}
                    variant="standard"
                    sx={{ flexGrow: 1 }}
                    disabled
                  />
                  <TextField
                    id="cantidad"
                    name="cantidad"
                    label={"Cantidad"}
                    type="text"
                    size={"small"}
                    value={data.cantidad}
                    variant="standard"
                    sx={{ flexGrow: 1 }}
                    disabled
                  />
                  <TextField
                    id="periodo"
                    name="periodo"
                    label={"Periodo"}
                    type="text"
                    size={"small"}
                    value={data.periodo}
                    variant="standard"
                    sx={{ flexGrow: 1 }}
                    disabled
                  />
                </Box>
              );
            })}
            <TextField
              id="tiene_agua_residual_alta_carga_organica"
              name="tiene_agua_residual_alta_carga_organica"
              label={"128. ¿Generan aguas residuales con alta carga orgánica?"}
              type="text"
              value={formData.tiene_agua_residual_alta_carga_organica}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="caudal_agua_residual"
              label="129. ¿Qué caudal de agua residual se genera y en qué periodo?"
              type="text"
              size={"small"}
              value={formData.caudal_agua_residual}
              variant="standard"
              disabled
            />
            <TextField
              id="lodos_residuales"
              name="lodos_residuales"
              label={"130. ¿Generan lodos residuales del tratamiento de agua?"}
              type="text"
              value={formData.lodos_residuales}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="cantidad"
              label="131. ¿Qué cantidad y en qué periodo?"
              type="text"
              size={"small"}
              value={formData.cantidad}
              variant="standard"
              disabled
            />
            <TextField
              id="tiene_reactor_digestor"
              name="tiene_reactor_digestor"
              label={
                "132. ¿Cuentan con reactor digestor para tratamiento de agua?"
              }
              type="text"
              value={formData.tiene_reactor_digestor}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="caudal_agua_residual"
              label="133. ¿De qué capacidad es?"
              type="text"
              size={"small"}
              value={formData.caudal_agua_residual}
              variant="standard"
              disabled
            />
            <TextField
              id="tiene_ptar"
              name="tiene_ptar"
              label={"134. ¿Se cuenta con PTAR?"}
              type="text"
              value={formData.tiene_ptar}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="ppm_entrada_ptar"
              label="134. PPM a la entrada a PTAR"
              type="text"
              size={"small"}
              value={formData.ppm_entrada_ptar}
              variant="standard"
              disabled
            />
            <TextField
              name="ppm_salida_ptar"
              label="136. PPM a la salida de PTAR"
              type="text"
              size={"small"}
              value={formData.ppm_salida_ptar}
              variant="standard"
              disabled
            />
            <TextField
              name="planta_tratamiento_agua_cercana"
              label="137. ¿Cuál es la planta de tratamiento de agua más cercana a la empresa?"
              type="text"
              size={"small"}
              value={formData.planta_tratamiento_agua_cercana}
              variant="standard"
              disabled
            />
            <TextField
              name="tratamiento_residuos"
              label="138. ¿Se les da algún tratamiento a estos residuos? P. ej. secado al sol"
              type="text"
              size={"small"}
              value={formData.tratamiento_residuos}
              variant="standard"
              disabled
            />
            <TextField
              name="distancia_reciduos_organicos"
              label="139. ¿A qué distancia se encuentra la fuente(s) de los residuos orgánicos del lugar de
              disposición? ¿hay algún almacenamiento previo a su disposición?"
              type="text"
              size={"small"}
              value={formData.distancia_reciduos_organicos}
              variant="standard"
              disabled
            />
            <TextField
              name="tiempo_estimado_almacenamiento"
              label="140. ¿Hay algún tiempo estimado de almacenamiento de los residuos orgánicos para
              su posterior disposición? ¿Esto se realiza a cielo abierto o como se realiza?"
              type="text"
              size={"small"}
              value={formData.tiempo_estimado_almacenamiento}
              variant="standard"
              disabled
            />
            <TextField
              name="localizacion_recibos"
              label="141. ¿Cuál es la localización del lugar donde se disponen estos residuos? ¿implica un
              costo extra esta disposición?"
              type="text"
              size={"small"}
              value={formData.localizacion_recibos}
              variant="standard"
              disabled
            />
            <TextField
              name="tipo_vehiculos_para_transporte"
              label="142. ¿Qué tipo de vehículo y cuantas unidades utilizan para transportar los residuos
              orgánicos? ¿con que periodicidad?"
              type="text"
              size={"small"}
              value={formData.tipo_vehiculos_para_transporte}
              variant="standard"
              disabled
            />
            <TextField
              name="conoce_cuantificacion_emisiones"
              label="143. ¿Conocen la cuantificación de emisiones (medidas) del proceso de producción
              por mes? Por ej. Emisiones de gases medidos, agua residual al alcantarillado,
              emisiones al suelo (polvos, residuos, etc.)"
              type="text"
              size={"small"}
              value={formData.conoce_cuantificacion_emisiones}
              variant="standard"
              disabled
            />
            <TextField
              id="tiene_biodigestor"
              name="tiene_biodigestor"
              label={"144. ¿Se cuenta con biodigestor?"}
              type="text"
              value={formData.tiene_biodigestor}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="capacidad"
              label="145. Capacidad [m3]"
              type="text"
              size={"small"}
              value={formData.capacidad}
              variant="standard"
              disabled
            />
            <TextField
              name="toneladas_entreda"
              label="146. Indicar Toneladas o m3 diarios de entrada"
              type="text"
              size={"small"}
              value={formData.toneladas_entreda}
              variant="standard"
              disabled
            />
            <TextField
              id="filtran_biogas"
              name="filtran_biogas"
              label={"147. ¿Filtran el biogás? (eliminan ácido sulfhídrico)"}
              type="text"
              value={formData.filtran_biogas}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              name="temperatura_biogas"
              label="148. Temperatura del biogás"
              type="text"
              size={"small"}
              value={formData.temperatura_biogas}
              variant="standard"
              disabled
            />
            <TextField
              name="humedad"
              label="149. Humedad"
              type="number"
              size={"small"}
              value={formData.humedad}
              variant="standard"
              disabled
            />
            <TextField
              name="desc_proceso"
              label="150. Breve descripción del proceso"
              type="text"
              size={"small"}
              value={formData.desc_proceso}
              variant="standard"
              multiline
              minRows={6}
              disabled
            />
            <TextField
              name="comentarios"
              label="151. Comentarios"
              type="text"
              size={"small"}
              value={formData.comentarios}
              variant="standard"
              multiline
              minRows={6}
              disabled
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="bioenergia"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
