import { Grid } from "@mui/material"
import PurchasedElectricityTable from "./components/PurchasedElectricityTable"


function PurchasedElectricity() {
  return (
    <Grid container>
        <PurchasedElectricityTable />
    </Grid>
  )
}

export default PurchasedElectricity