import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import DropDown from "../../../../components/DropDown";
import { SaveAlt } from "@mui/icons-material";
import { useEffect, useState } from "react";

function StationaryForm({
  saveAction,
  units,
  fuels,
  years,
  facilityIds,
  formData,
  handleChange,
}) {
  const [fuel, setFuel] = useState([])
  const handleFuel = () => {
    setFuel(fuels.filter(f=>f.is_custom==formData.is_custom_EF).map(f=>({label:f.name,value:f.id})))
  }
  useEffect(()=>{
    handleFuel()
  },[formData.is_custom_EF])

  useEffect(()=>{
    handleFuel()
  },[])
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <FormControl fullWidth>
            <TextField 
            error={false}
            id="id"
            name="id"
            value={formData.id}
            size={"small"}
            onChange={handleChange}
            disabled
            sx={{display:"none"}}
            />
            <DropDown
              id={"facilityId"}
              label={"Planta"}
              value={formData.facilityId}
              onChange={handleChange}
              data={facilityIds}
              size={"small"}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"yearId"}
              label={"Año"}
              value={formData.yearId}
              onChange={handleChange}
              data={years}
              size={"small"}
              disabled={formData.id!==""}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.is_custom_EF}
                  name="is_custom_EF"
                  id="is_custom_EF"
                  onChange={handleChange}
                  disabled={formData.id!==""}
                />
              }
              label="Es factor de emision personalizado?"
            />
            <DropDown
              id={"fuelId"}
              label={"Combustible"}
              value={formData.fuelId}
              onChange={handleChange}
              data={fuel}
              size={"small"}
              disabled={formData.id!==""}
            />
            <TextField
              error={false}
              id="amount"
              name="amount"
              label={"Monto de combustible"}
              type="number"
              value={formData.amount}
              size={"small"}
              onChange={handleChange}
            />
            <DropDown
              id={"unitId"}
              label={"Unidad"}
              value={formData.unitId}
              onChange={handleChange}
              data={units}
              size={"small"}
            />
          </FormControl>
          <Grid justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              sx={{ mx: 1 }}
              startIcon={<SaveAlt />}
              onClick={saveAction}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

StationaryForm.defaultProps = {
  saveAction: () => {
    console.log("Saved");
  },
};

StationaryForm.prototype = {
  saveAction: PropTypes.func,
  units: PropTypes.array,
  facilityIds: PropTypes.array,
  fuels: PropTypes.array,
  years: PropTypes.array,
  formData: PropTypes.object,
};

export default StationaryForm;
