export const unauthenticatedUser = () => {
  window.location = "/logout";
};

export const errorValidation = (error) => {
  if (error?.response.status === 401) {
    unauthenticatedUser();
  }
  throw Error(error)
};

export const getToken = () => {
  const token = localStorage.authTokens
  ? JSON.parse(localStorage.authTokens).access
  : null;
  return token
}
