import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  emissionListItem,
  entregablesDashboardListItem,
  entregablesListItem,
  formulariosDashboardListItem,
  formulariosListItem,
  homeListItem,
  polygonListItem,
} from "./listItems";
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import AuthContext from "../context/AuthContext";
import Logo from "../assets/cuugreen.png";
import {
  Calculate,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import Footer from "../components/Footer"

const drawerWidth = 250;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: theme.spacing(6),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Dashboard({ children }) {
  const { user, logoutUser, profile } = React.useContext(AuthContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEmissions, setOpenEmissions] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    if (!user) {
      setOpen(false);
      return;
    }
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setOpen(false);
    logoutUser();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {user && (
        <AppBar position="fixed" open={open}>
          <Toolbar>
            {/* {user && ( */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            {/* )} */}
            <Box
              sx={{
                marginLeft: "auto",
                marginRight: 0,
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              {user ? (
                <Box sx={{ flexGrow: 0 }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <Avatar alt={user.fullName} src="../public/" />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem>{user.fullName}</MenuItem>
                    <MenuItem onClick={profile}>Perfil</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Avatar src={Logo} />
          <Typography variant="subtitle2" noWrap>
            Chihuahua Green
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {homeListItem}
        {entregablesListItem}
        {user?.empresa.toLowerCase()==="chihuahua green" ? entregablesDashboardListItem : ""}
        {formulariosListItem}
        {user?.empresa.toLowerCase()==="chihuahua green" ? formulariosDashboardListItem : ""}
        {/* {polygonListItem} */}
        <List>
          <ListItem>
            <ListItemButton
              onClick={() => {
                setOpenEmissions(!openEmissions);
              }}
            >
              <ListItemIcon>
                <Calculate />
              </ListItemIcon>
              <ListItemText primary="Calculo de Emisiones">
                {openEmissions ? <ExpandLess /> : <ExpandMore />}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Collapse in={openEmissions} timeout="auto" unmountOnExit>
          {emissionListItem}
        </Collapse>
      </Drawer>
      <Main open={open}>
        {/* <DrawerHeader /> */}
        <Box xs='auto' md='auto' lg='auto' sx={{ mt: 6, display:'flex', flexWrap:'wrap', justifyContent:'center'}}>{children}</Box>
        { user && <Footer /> }
      </Main>
    </Box>
  );
}
