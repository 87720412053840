import { Grid } from "@mui/material";
import StationaryTable from "./components/StationaryTable";

function Stationary() {
  return (
    <Grid container>
      <StationaryTable />
    </Grid>
  );
}

export default Stationary;
