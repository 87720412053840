import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getEdificios } from "../../api/Core.api";
import LoadingContext from "../../context/LoadingContext";
import DropDown from "../../components/DropDown";
import { SaveAlt } from "@mui/icons-material";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import Logo from "../../assets/cuugreen.png"
import Fondo from "../../assets/formulario/fondo.png"
import Fondo1 from "../../assets/formulario/fondo1.png"
import BarraColores from "../../components/BarraColores";
import { green } from "@mui/material/colors";
import { errorHandler } from "../../utils/ErrorHandler";

function SelectEdificioForm({
  edificioId,
  handleChange,
  handleSubmit,
  cancelAction,
}) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [edificios, setEdificios] = useState([]);
  useEffect(() => {
    const getPlantas = async () => {
      startLoading();
      try {
        const res = await getEdificios();
        setEdificios(res.data);
        stopLoading();
      } catch (error) {
        console.log(error);
        stopLoading();
        errorHandler(error)
      }
    };
    getPlantas();
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <FormControl fullWidth>
            <DropDown
              id={"edificioId"}
              label={"Planta"}
              value={edificioId}
              onChange={handleChange}
              data={edificios.map((f) => ({
                label: f.nick_name,
                value: f.id,
              }))}
              size={"small"}
            />
          </FormControl>
          <Grid justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              sx={{ mx: 1 }}
              // startIcon={<SaveAlt />}
              onClick={handleSubmit}
            >
              Seleccionar y continuar
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ mx: 1 }}
              // startIcon={<SaveAlt />}
              onClick={cancelAction}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Disclaimer() {
  const navigate = useNavigate();
  const [openEdificioModal, setOpenEdificioModal] = useState(false);
  const [edificioId, setEdificioId] = useState("");
  const handleChange = (e) => {
    setEdificioId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(edificioId===""){
      enqueueSnackbar({
        variant: "error",
        message: "Seleccione una planta valida",
        autoHideDuration: 8000,
      });
      return
    }
    const url = `/formulario/add?planta=${edificioId}`;
    navigate(url);
  };
  return (
    // <Box
    //   sx={{
    //     px: 4,
    //     textAlign: "justify",
    //     display: "flex",
    //     flexDirection: "column",
    //     gap: "1rem",
    //   }}
    // >
    //   <Typography variant="body1">
    //     El documento contiene un conjunto de preguntas preliminares (formulario)
    //     enfocadas a conocer la realidad de la empresa/industria. Las preguntas
    //     se dividen en tres bloques: datos generales de la empresa, datos de
    //     proceso y preguntas de Simbiosis Industrial.
    //   </Typography>
    //   <Typography variant="body1">
    //     De la manera más amable y atenta, les pedimos que el documento sea
    //     respondido y retornado al equipo de Chihuahua Green City.
    //   </Typography>
    //   <Typography variant="subtitle1" fontWeight="bold">
    //     OBJETIVO DEL FORMULARIO
    //   </Typography>
    //   <ul>
    //     <li>
    //       Apoyar a las empresas en la búsqueda de generar ahorros e ingresos a
    //       partir del aprovechamiento de sus residuos materiales, energéticos, de
    //       agua, espacio, logística, infraestructura y conocimiento.
    //     </li>
    //     <li>
    //       Tener un conocimiento más profundo sobre las empresas que participan
    //       en el proyecto Chihuahua Green City, sus procesos, organización,
    //       necesidades y objetivos.
    //     </li>
    //     <li>
    //       Realizar un análisis sobre la eficiencia en el uso de los recursos de
    //       las empresas.
    //     </li>
    //     <li>
    //       Plantear soluciones para mejorar la eficiencia en el uso de esos
    //       recursos a través de la colaboración entre empresas.
    //     </li>
    //     <li>
    //       Ayudar a las empresas en su transición individual hacia la Economía
    //       Circular, con base en su participación dentro de algún proyecto
    //       colectivo implantado en el ecosistema industrial local
    //     </li>
    //   </ul>
    //   <Typography variant="subtitle1" fontWeight="bold">
    //     RECOMENDACIONES
    //   </Typography>
    //   <Typography variant="body1">
    //     Favor de considerar que nuestra área de expertos en cada área estará
    //     apoyando a la captura de datos de este formato.
    //   </Typography>
    //   <Typography variant="subtitle1" fontWeight="bold">
    //     Aviso de Confidencialidad y Privacidad
    //   </Typography>
    //   <Typography variant="body1">
    //     En Chihuahua Green valoramos tu privacidad. Recopilamos información
    //     personal, como tu nombre y correo electrónico, para personalizar tu
    //     experiencia y proporcionarte nuestros servicios. No compartimos tus
    //     datos con terceros sin tu consentimiento, salvo para cumplir con la ley.
    //     Utilizamos cookies para mejorar la funcionalidad del sitio. Puedes
    //     administrar las preferencias de cookies en la configuración de tu
    //     navegador. Tienes derecho a acceder, corregir o eliminar tus datos
    //     personales. Si tienes preguntas o preocupaciones sobre tu privacidad,
    //     contáctanos a través de contacto@chihuahua.green Gracias por confiar en
    //     nosotros.
    //   </Typography>
    //   {/* <Typography variant="subtitle1" fontWeight="bold">
    //     CONFIDENCIABILIDAD | DISCLAIMER
    //   </Typography>
    //   <Typography variant="body1">
    //     La Unión Europea bajo convenio de colaboración con la COPARMEX en
    //     Chihuahua, entes de gobierno, y otros organismos empresariales, ponen a
    //     su disposición este aviso de confidencialidad para el adecuado manejo de
    //     datos empresariales.
    //   </Typography>
    //   <Typography variant="body1">
    //     La Unión Europea está comprometida en la protección y respeto de la
    //     privacidad de sus datos. Para el propósito de este proyecto piloto:
    //     “Chihuahua Green City”, que se llevará a cabo del 15 de julio de 2021 al
    //     31 de marzo de 2022, recopilaremos y procesaremos sus datos corporativos
    //     de conformidad con el Reglamento (CE) No. 45/2001 del Parlamento Europeo
    //     y del Consejo de 18 de diciembre de 2000 sobre la protección de las
    //     personas en lo que respecta al tratamiento de datos personales por parte
    //     de las instituciones y organismos comunitarios y sobre la libre
    //     circulación de dichos datos, en lo sucesivo denominado el "Reglamento".
    //     La base legal para cualquier operación de procesamiento de sus datos
    //     corporativos es el Reglamento RECAST (CE) No 1339/2008 Artículo 2
    //     Funciones.
    //   </Typography> */}
    //   <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
    //     <Button
    //       variant="outlined"
    //       color="success"
    //       onClick={() => {
    //         setOpenEdificioModal(true);
    //       }}
    //     >
    //       Continuar
    //     </Button>
    //     <Button variant="outlined" color="error" href="/">
    //       Cancelar
    //     </Button>
    //   </Box>
    //   <Modal
    //     openModal={openEdificioModal}
    //     title={"Seleccionar la planta a llenar"}
    //     setOpenModal={setOpenEdificioModal}
    //   >
    //     <SelectEdificioForm
    //       handleChange={handleChange}
    //       handleSubmit={handleSubmit}
    //       cancelAction={() => {
    //         setOpenEdificioModal(false);
    //       }}
    //       edificioId={edificioId}
    //     />
    //   </Modal>
    // </Box>
    <Grid container spacing={4}>
      <Grid item xs={12} sx={{display:"flex", flexDirection:"column", gap:4, justifyContent:"center", alignItems:"center",backgroundImage: `url(${Fondo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          p:6}}>
        <Box sx={{display:"flex",justifyContent:"center"}}>
        <img alt="logo" src={Logo} width={300} height={300}/>
        </Box>
        <Typography variant="h3" textAlign="center">¡Gracias por acceder a nuestro formulario!</Typography>
        <Typography variant="h5" textAlign="center">Nuestro objetivo fundamental es ser guía de las empresas que buscan generar ahorros e ingresos a partir del aprovechamiento de sus residuos materiales: energéticos, de agua, espacio, logística, infraestructura y conocimiento.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4} sx={{px:6}}>
          <Grid item xs={12} md={6} sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <img alt="hand" width={350} height={350} src={Fondo1} />
          </Grid>
          <Grid item xs={12} md={6} sx={{display:"flex",gap:4, flexDirection:"column"}}>
            <Typography variant="h3" textAlign="">Tres bloques de preguntas</Typography>
            <Typography variant="body1" textAlign="justify">Estás a punto de contestar preguntas específicas, minuciosamente estructuradas por expertos, enfocadas a conocer la realidad del ecosistema empresarial en el estado de Chihuahua. Estas se dividen en tres bloques:</Typography>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4}>
              <Typography variant="h6" sx={{backgroundColor:"#F89C29",color:"#FFFFFF", borderRadius:"50%", width:"2.5rem", height:"2.5rem", justifyContent:"center", alignItems:"center", display:"flex"}}>1</Typography>
              <Typography variant="h6">Datos generales de la empresa</Typography>
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4}>
              <Typography variant="h6" sx={{backgroundColor:"#E01483",color:"#FFFFFF", borderRadius:"50%", width:"2.5rem", height:"2.5rem", justifyContent:"center", alignItems:"center", display:"flex"}}>2</Typography>
              <Typography variant="h6">Datos de procesos</Typography>
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4}>
              <Typography variant="h6" sx={{backgroundColor:"#11972D",color:"#FFFFFF", borderRadius:"50%", width:"2.5rem", height:"2.5rem", justifyContent:"center", alignItems:"center", display:"flex"}}>3</Typography>
              <Typography variant="h6">Preguntas de Simbiosis Industrial</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="justify">
            Al responder el formulario, conocerás a fondo los procesos, organización, necesidades y objetivos de las empresas que participan en Chihuahua Green; visualizarás un análisis sobre la eficiencia en el uso de los recursos de las diversas industrias; obtendrás soluciones para mejorar la eficiencia en el uso de tus recursos mediante colaboración con empresas, y así podrás transitar hacia la Economía Circular, ya sea en lo individual o participando en algún proyecto colectivo implantado en el ecosistema industrial del estado.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <BarraColores />
      <Grid item xs={12}>
        <Box sx={{p:6, backgroundColor:green[600],color:"#FFFFFF", borderRadius:"15px", mx:10}}>
          <Typography variant="h3" textAlign="center">RECOMENDACIONES</Typography>
          <Typography variant="body1" textAlign="center">Si tienes dudas sobre cómo responder alguna pregunta, puedes contactarnos a <strong><a href="mailto:contacto@chihuahua.green" target="_blank" rel="noreferrer">contacto@chihuahua.green</a></strong> en donde con gusto te apoyaremos.</Typography>
        </Box>
        <Box sx={{p:6, mx:10}}>
          <Typography variant="h3" textAlign="center">AVISO DE PRIVACIDAD</Typography>
        </Box>
        <Box sx={{p:6, mx:10}}>
          <Typography variant="body1">En Chihuahua Green valoramos tu privacidad y queremos que sepas que la información que nos proporciones será tratada bajo los más amplios estándares de resguardo. Recopilamos información personal, como tu nombre y correo electrónico, para personalizar tu experiencia y proporcionarte nuestros servicios. No compartimos tus datos con terceros sin tu consentimiento, salvo para cumplir con la ley. Utilizamos cookies para mejorar la funcionalidad del sitio. Puedes administrar las preferencias de cookies en la configuración de tu navegador. Tienes derecho a acceder, corregir o eliminar tus datos personales. Si tienes preguntas o preocupaciones sobre tu privacidad, contáctanos a través de contacto@chihuahua.green</Typography>
        </Box>
        <Box sx={{p:6, mx:10}}>
          <Typography variant="h5">¡Gracias por brindarnos tu confianza!</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
             <Button
               variant="contained"
               color="primary"
               onClick={() => {
                 setOpenEdificioModal(true);
               }}
             >
               Empezar
             </Button>
        </Box>
      </Grid>
      <Modal
        openModal={openEdificioModal}
        title={"Seleccionar la planta a llenar"}
        setOpenModal={setOpenEdificioModal}
      >
        <SelectEdificioForm
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          cancelAction={() => {
            setOpenEdificioModal(false);
          }}
          edificioId={edificioId}
        />
      </Modal>
    </Grid>
  );
}

export default Disclaimer;
