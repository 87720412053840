import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import Modal from "../../components/Modal";
import UserUpdateForm from "./components/UserUpdateForm";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import LoadingContext from "../../context/LoadingContext";
import { changePassword, updateUser } from "../../api/CoreUser";
import ChangePasswordForm from "./components/ChangePasswordForm";
import { enqueueSnackbar } from "notistack";
import { errorHandler } from "../../utils/ErrorHandler";

export default function User() {
  const { user, refreshUser } = useContext(AuthContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [formData, setFormData] = useState(user);
  const [passwordForm, setPasswordForm] = useState({
    old_password: "",
    new_password: "",
    new_password2: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalPass, setOpenModalPass] = useState(false);
  const [error, setError] = useState({});
  const [errorPass, setErrorPass] = useState({});
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading("Guardando datos");
    try {
      const res = await updateUser(formData);
      refreshUser(res.data);
      setError({});
      stopLoading();
      setOpenModal(false);
    } catch (error) {
      console.log(error);
      errorHandler(error)
      setError(error.response.data);
      stopLoading();
    }
  };
  const cancelAction = (e) => {
    e.preventDefault();
    setFormData(user);
    setError({});
    setOpenModal(false);
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  };
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (passwordForm.new_password !== passwordForm.new_password2) {
      setErrorPass({ new_password2: "Las nuevas contraseñas no coinciden" });
      return;
    }
    startLoading("Guardando datos");
    try {
      const res = await changePassword(passwordForm);
      console.log(res);
      setErrorPass({});
      stopLoading();
      setOpenModalPass(false);
      enqueueSnackbar({
        variant: "success",
        message: "Contraseña cambiada exitosamente",
        autoHideDuration: 8000,
      });
    } catch (error) {
      console.log(error);
      errorHandler(error)
      setErrorPass(error.response.data);
      stopLoading();
    }
  };
  useEffect(() => {
    setFormData(user);
  }, [user]);

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Typography variant="h5">
            {user.fullName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Nombre
          </Typography>
          <Typography>{user.first_name}</Typography>
          <Typography variant="body2" color="text.secondary">
            Apellidos
          </Typography>
          <Typography>{user.last_name}</Typography>
          <Typography variant="body2" color="text.secondary">
            Correo electronico
          </Typography>
          <Typography>{user.email}</Typography>
          <Typography variant="body2" color="text.secondary">
            Nombre de usuario
          </Typography>
          <Typography>{user.username}</Typography>
        </CardContent>
      </Card>
      <CardActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Editar
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenModalPass(true);
          }}
        >
          Cambiar contraseña
        </Button>
      </CardActions>

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Editar informacion del usuario"
      >
        <UserUpdateForm
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          error={error}
          cancel={cancelAction}
        />
      </Modal>
      <Modal
        openModal={openModalPass}
        setOpenModal={setOpenModalPass}
        title="Cambiar contraseña"
      >
        <ChangePasswordForm
          formData={passwordForm}
          handleChange={handleChangePassword}
          handleSubmit={handleSubmitPassword}
          error={errorPass}
          cancel={cancelAction}
        />
      </Modal>
    </>
  );
}
