import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

export default function EnergiaElectricaCard({ formData, handleDelete }) {
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <Box sx={{ my: 4 }}>
              <Typography variant="body2">
                95. Agregar ultimos 12 recibos de energia
              </Typography>
              {/* <input type="file" name="recibos_energia" multiple accept="image/png, .jpeg, .jpg, image/gif"/> */}
            </Box>
            <TextField
              id="voltaje_trasmision_CFE"
              name="voltaje_trasmision_CFE"
              label={"96. Voltaje de transmisión por parte de CFE"}
              type="text"
              value={formData.voltaje_trasmision_CFE}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="tipo_instalacion"
              name="tipo_instalacion"
              label={"97. Tipo de instalación"}
              type="text"
              value={formData.tipo_instalacion}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="kVAs"
              name="kVAs"
              label={"98. [kVAs]:"}
              type="text"
              value={formData.kVAs}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="voltaje_corriente_CFE"
              name="voltaje_corriente_CFE"
              label={
                "99. Voltaje y corriente del servicio actual (el que entrega CFE)"
              }
              type="text"
              value={formData.voltaje_corriente_CFE}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              id="instalacion"
              name="instalacion"
              label={"100. Indique si es Monofásico, Bifásico o Trifásico"}
              type="text"
              value={formData.instalacion}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="cantidad_servicios"
              name="cantidad_servicios"
              label={
                "101. ¿Con cuántos servicios (recibos) de CFE cuenta el cliente?"
              }
              type="text"
              value={formData.cantidad_servicios}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Techado:
            </Typography>
            <TextField
              id="tipo_techo_condicion_actual"
              name="tipo_techo_condicion_actual"
              label={
                "102. ¿Cuál es el tipo de techo y condición actual de este?"
              }
              type="text"
              value={formData.tipo_techo_condicion_actual}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <Typography variant="subtitle1">Especificar:</Typography>
            <TextField
              id="material_tipo_construccion"
              name="material_tipo_construccion"
              label={"103. Material y tipo de construcción:"}
              type="text"
              value={formData.material_tipo_construccion}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="analisis_estructural_carga_soportada"
              name="analisis_estructural_carga_soportada"
              label={
                "104. ¿Se cuenta con un análisis estructural actual de la techumbre y de la carga que puede soportar?"
              }
              type="text"
              value={formData.analisis_estructural_carga_soportada}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Carport:
            </Typography>
            <TextField
              id="tiene_planos_estacionamiento"
              name="tiene_planos_estacionamiento"
              label={
                "105. En caso de buscar la opción de instalación de un Carport, ¿se tiene plano del estacionamiento (autocad)? ¿Existen los planos actualizados en que se muestren claramente y sea posible ubicar las instalaciones / servicios subterráneos?"
              }
              type="text"
              value={formData.tiene_planos_estacionamiento}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={7}
              disabled
            />
            <Box sx={{ my: 4 }}>
              <Typography variant="body2">
                106. Agregar diagrama unifilar
              </Typography>
              {/* <input type="file" name="diagrama_unifilar" multiple accept="image/png, .jpeg, .jpg, image/gif"/> */}
            </Box>
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="energia-electrica"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
