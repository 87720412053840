import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

export default function Logout() {
    const {logoutUser} = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
    
      logoutUser();
    }, [])
    
  return (
    <></>
  )
}
