import React from "react";
import EdificioTable from "./components/EdificioTable";

function Edificio({ edificios, empresa }) {
  return (
    <>
      <EdificioTable data={edificios} empresa={empresa} />
    </>
  );
}

export default Edificio;
