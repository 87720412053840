import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { useState } from "react";
import {
  deleteAgua,
  deleteAnalisisPreliminar,
  deleteBioenergia,
  deleteCompromisoEconomiaCircular,
  deleteDisipacionEnergiaTermica,
  deleteEnergiaElectrica,
  deleteMaterial,
  deleteSistemaInterno,
  getDashboardFormularios,
} from "../../api/EconomiaCircular.api";
import { errorHandler } from "../../utils/ErrorHandler";
import LoadingContext from "../../context/LoadingContext";
import StyledCard from "../../components/StyledCard";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Check, Error } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import AnalisisPreliminarCard from "./AnalisisPreliminar/components/AnalisisPreliminarCard";
import CompromisoEconomiaCircularCard from "./CompromisoEconomiaCircular/components/CompromisoEconomiaCircularCard";
import ModalFullScreen from "../../components/ModalFullScreen";
import MaterialesCard from "./Materiales/components/MaterialesCard";
import BioenergiaCard from "./Bioenergia/components/BioenergiaCard";
import AguaCard from "./Agua/components/AguaCard";
import DisipacionTermicaCard from "./DisipacionTermica/components/DisipacionTermicaCard";
import EnergiaElectricaCard from "./EnergiaElectrica/components/EnergiaElectricaCard";
import { enqueueSnackbar } from "notistack";
import SistemaInternoCard from "./SistemaInterno/components/SistemaInternoCard";

export default function DashboardEC() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [DashboardEC, setDashboardEC] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [children, setChildren] = useState(null);
  const [title, setTitle] = useState("");

  const handleDelete = async (evt) => {
    const name = evt.target.name;
    const id = evt.target.id;
    startLoading("Eliminando Registro");
    switch (name) {
      case "analisis":
        try {
          await deleteAnalisisPreliminar(id);
          setOpenModal(false);
          await fetchData();
          stopLoading();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      case "compromiso":
        try {
          await deleteCompromisoEconomiaCircular(id);
          setOpenModal(false);
          await fetchData();
          stopLoading();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      case "sistema-interno":
        try {
          await deleteSistemaInterno(id);
          setOpenModal(false);
          stopLoading();
          await fetchData();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      case "material":
        try {
          await deleteMaterial(id);
          setOpenModal(false);
          await fetchData();
          stopLoading();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      case "agua":
        try {
          await deleteAgua(id);
          setOpenModal(false);
          stopLoading();
          await fetchData();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      case "energia-electrica":
        try {
          await deleteEnergiaElectrica(id);
          setOpenModal(false);
          await fetchData();
          stopLoading();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      case "disipacion-energia-termica":
        try {
          await deleteDisipacionEnergiaTermica(id);
          setOpenModal(false);
          await fetchData();
          stopLoading();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      case "bioenergia":
        try {
          await deleteBioenergia(id);
          setOpenModal(false);
          await fetchData();
          stopLoading();
          enqueueSnackbar({
            variant: "success",
            message: "Registro eliminado exitosamente",
            autoHideDuration: 8000,
          });
        } catch (error) {
          stopLoading();
          errorHandler(error);
        }
        break;
      default:
        stopLoading();
        console.error("Opcion no valida");
    }
  };

  useEffect(() => {
    if (!openModal) {
      setChildren(null);
      setTitle("");
    }
  }, [openModal]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "empresa",
      headerName: "Empresa",
      flex: 1,
    },
    {
      field: "planta",
      headerName: "Planta",
      flex: 1,
    },
    {
      field: "analisis_preliminar",
      headerName: "Informacion General",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.analisis_preliminar) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <AnalisisPreliminarCard
                  formData={params.row.analisis_preliminar[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Informacion General de la Empresa");
              setOpenModal(true);
            }}
          />
        );
      },
    },
    {
      field: "compromiso_economia_circular",
      headerName: "Compromiso hacia la Economia Circular",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.compromiso_economia_circular) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <CompromisoEconomiaCircularCard
                  formData={params.row.compromiso_economia_circular[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Compromiso hacia la Economía Circular");
              setOpenModal(true);
            }}
          />
        );
      },
    },
    {
      field: "sistema_interno",
      headerName: "Sistema Interno",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.sistema_interno) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <SistemaInternoCard
                  formData={params.row.sistema_interno[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Sistema Interno");
              setOpenModal(true);
            }}
          />
        );
      },
    },
    {
      field: "material",
      headerName: "Materiales",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.material) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <MaterialesCard
                  formData={params.row.material[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Materiales");
              setOpenModal(true);
            }}
          />
        );
      },
    },
    {
      field: "agua",
      headerName: "Agua",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.agua) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <AguaCard
                  formData={params.row.agua[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Agua");
              setOpenModal(true);
            }}
          />
        );
      },
    },
    {
      field: "energia_electrica",
      headerName: "Energia Electrica",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.energia_electrica) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <EnergiaElectricaCard
                  formData={params.row.energia_electrica[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Energia Electrica");
              setOpenModal(true);
            }}
          />
        );
      },
    },
    {
      field: "disipacion_energia_termica",
      headerName: "Disipacion de Energia Termica",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.disipacion_energia_termica) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <DisipacionTermicaCard
                  formData={params.row.disipacion_energia_termica[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Disipacion de Emergia Termica");
              setOpenModal(true);
            }}
          />
        );
      },
    },
    {
      field: "bioenergia",
      headerName: "Bioenergia",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.bioenergia) {
          return <Error color="error" />;
        }
        return (
          <GridActionsCellItem
            icon={<Check />}
            color="success"
            title="Clic para ver contenido"
            label="Ver contenido"
            onClick={() => {
              setChildren(
                <BioenergiaCard
                  formData={params.row.bioenergia[0]}
                  handleDelete={handleDelete}
                />
              );
              setTitle("Bioenergia");
              setOpenModal(true);
            }}
          />
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      const res = await getDashboardFormularios();
      setDashboardEC(res.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      startLoading();
      try {
        await fetchData();
        stopLoading();
      } catch (error) {
        stopLoading();
        errorHandler(error);
      }
    };
    loadData();
  }, []);

  return (
    <Grid container>
      <StyledCard title="Administracion Economia Circular">
        <CustomDataGrid
          columns={columns}
          rows={DashboardEC}
          sortModel={[
            { field: "empresa", sort: "asc" },
            { field: "planta", sort: "asc" },
          ]}
        />
      </StyledCard>
      <ModalFullScreen
        openModal={openModal}
        title={title}
        setOpenModal={setOpenModal}
      >
        {children}
      </ModalFullScreen>
    </Grid>
  );
}
