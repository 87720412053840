import { useState } from "react";
import AnalisisPreliminarForm from "./AnalisisPreliminar/AnalisisPreliminarForm";
import {
  Card,
  Container,
  // Container,
  // CssBaseline,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import CompromisoEconomiaCircularForm from "./CompromisoEconomiaCircular/CompromisoEconomiaCircularForm";
import SistemaInternoForm from "./SistemaInterno/SistemaInternoForm";
import AguaForm from "./Agua/AguaForm";
import EnergiaElectricaForm from "./EnergiaElectrica/EnergiaElectricaForm";
import DisipacionTermicaForm from "./DisipacionTermica/DisipacionTermicaForm";
import MaterialesForm from "./Materiales/MaterialesForm";
import BioenergiaForm from "./Bioenergia/BioenergiaForm";
import { useSearchParams } from "react-router-dom";
import FinalizarFormulario from "./FinalizarFormulario";
// import TSEntradasForm from "./TextilesSostenibles/TSEntradasForm";
// import TSSalidasForm from "./TextilesSostenibles/TSSalidasForm";
// import TSOportunidadesForm from "./TextilesSostenibles/TSOportunidadesForm";
// import EstrategiaCircularForm from "./EstrategiaCircular/EstrategiaCircularForm";

const steps = [
  "INFORMACION GENERAL DE LA EMPRESA",
  "COMPROMISO HACIA LA ECONOMIA CIRCULAR",
  "SISTEMA INTERNO",
  "MATERIALES",
  "AGUA",
  "ENERGÍA ELÉCTRICA",
  "DISIPACIÓN DE ENERGÍA TÉRMICA",
  "BIOENERGÍA",
  // "TEXTILES SOSTENIBLES ENTRADAS",
  // "TEXTILES SOSTENIBLES SALIDAS",
  // "TEXTILES SOSTENIBLES OPORTUNIDADES",
  // "Estrategias Circulares",
];

function Formularios() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [edificioId, setEdificioId] = useState(searchParams.get("planta"));
  const [activeStep, setActiveStep] = useState(0);
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AnalisisPreliminarForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 1:
        return (
          <CompromisoEconomiaCircularForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 2:
        return (
          <SistemaInternoForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 3:
        return (
          <MaterialesForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 4:
        return (
          <AguaForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 5:
        return (
          <EnergiaElectricaForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 6:
        return (
          <DisipacionTermicaForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 7:
        return (
          <BioenergiaForm
            stepCount={activeStep}
            setStepCount={setActiveStep}
            edificioId={edificioId}
          />
        );
      case 8:
        return (
          <FinalizarFormulario />
        )
      // case 8:
      //   return (
      //     <TSEntradasForm stepCount={activeStep} setStepCount={setActiveStep} />
      //   );
      // case 9:
      //   return (
      //     <TSSalidasForm stepCount={activeStep} setStepCount={setActiveStep} />
      //   );
      // case 10:
      //   return (
      //     <TSOportunidadesForm
      //       stepCount={activeStep}
      //       setStepCount={setActiveStep}
      //     />
      //   );
      // case 11:
      //   return (
      //     <EstrategiaCircularForm
      //       stepCount={activeStep}
      //       setStepCount={setActiveStep}
      //     />
      //   );
      default:
        throw new Error("Paso desconocido");
    }
  };
  return (
    <>
      <Container maxWidth="lg">
        <Stepper
          activeStep={activeStep}
          sx={{ pt: 3, pb: 5, display: "flex", flexWrap: "wrap", gap: "10px" }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>{getStepContent(activeStep)}</>
      </Container>
    </>
  );
}

export default Formularios;
