import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DropDown from "../../../../components/DropDown";

export default function AguaCard({ formData, handleDelete }) {
  const tipoAguaEntrada = [
    { label: "Tratada", value: 0 },
    { label: "Potable", value: 1 },
  ];
  const tipoFuenteAgua = [
    { label: "Potable", value: 0 },
    { label: "Regenerada", value: 1 },
  ];
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <TextField
              id="utiliza_agua_en_procesos"
              name="utiliza_agua_en_procesos"
              label={"63. ¿Utilizas agua en tus procesos industriales?"}
              type="text"
              value={formData.utiliza_agua_en_procesos}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="en_que_procesos"
              name="en_que_procesos"
              label={
                "64. En caso de sí, ¿cuáles son tus procesos que utilizan agua?"
              }
              type="text"
              value={formData.en_que_procesos}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <DropDown
              id={"fuente_agua"}
              label={"65. ¿Cuál es la fuente de agua?"}
              value={formData.fuente_agua}
              data={tipoFuenteAgua}
              size={"small"}
              variant="standard"
              disabled
            />
            <TextField
              id="agua_utilizada_mes"
              name="agua_utilizada_mes"
              label={"66. ¿Cuánta agua utilizas al mes?"}
              type="text"
              value={formData.agua_utilizada_mes}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="agua_residual_generada"
              name="agua_residual_generada"
              label={"67. ¿Cuánta agua residual generas?"}
              type="text"
              value={formData.agua_residual_generada}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="donde_va_agua_residual"
              name="donde_va_agua_residual"
              label={"68. ¿A dónde se va tu agua residual?"}
              type="text"
              value={formData.donde_va_agua_residual}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="proceso_regeneracion"
              name="proceso_regeneracion"
              label={"69. ¿Tienes algún proceso de regeneración de agua?"}
              type="text"
              value={formData.proceso_regeneracion}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="interesado_planta_tratadora"
              name="interesado_planta_tratadora"
              label={"70. ¿Estas interesado en poner una planta tratadora?"}
              type="text"
              value={formData.interesado_planta_tratadora}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="cuenta_plano_tuberias_agua"
              name="cuenta_plano_tuberias_agua"
              label={
                "71. ¿Se cuenta con plano general de la planta, donde especifique las tuberías de agua que hay en la planta?"
              }
              type="text"
              value={formData.cuenta_plano_tuberias_agua}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="cuanto_gasta_agua"
              name="cuanto_gasta_agua"
              label={"72. ¿Cuánto gastan en agua?"}
              type="text"
              value={formData.cuanto_gasta_agua}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="iniciativa_manejo_eficiente"
              name="iniciativa_manejo_eficiente"
              label={
                "73. ¿Tienes alguna iniciativa para manejo de agua eficiente en tu empresa? ¿Cuál?"
              }
              type="text"
              value={formData.iniciativa_manejo_eficiente}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
              disabled
            />
            <TextField
              id="antiguedad_instalaciones_hidraulicas"
              name="antiguedad_instalaciones_hidraulicas"
              label={
                "74. Describe la antigüedad de las instalaciones hidráulicas de tu empresa"
              }
              type="text"
              value={formData.antiguedad_instalaciones_hidraulicas}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              id="cuenta_croquis_sistema_hidraulico"
              name="cuenta_croquis_sistema_hidraulico"
              label={"75. ¿Cuentas con un croquis del sistema de hidráulico?"}
              type="text"
              value={formData.cuenta_croquis_sistema_hidraulico}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="estudio_balance_agua"
              name="estudio_balance_agua"
              label={"76. ¿Cuentas con algún estudio de balance de agua?"}
              type="text"
              value={formData.estudio_balance_agua}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="usos_dados"
              name="usos_dados"
              label={
                "77. ¿Cuáles son los usos que se le dan al agua en tu empresa?"
              }
              type="text"
              value={formData.usos_dados}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="abastecimiento_agua_caliente"
              name="abastecimiento_agua_caliente"
              label={"78. ¿En qué áreas o sistemas se abastece agua caliente?"}
              type="text"
              value={formData.abastecimiento_agua_caliente}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
              disabled
            />
            <TextField
              id="abastecimiento_agua_fria"
              name="abastecimiento_agua_fria"
              label={"79. ¿Y agua fría?"}
              type="text"
              value={formData.abastecimiento_agua_fria}
              size={"small"}
              fullWidth
              variant="standard"
              minRows={4}
              disabled
            />
            <TextField
              id="instalacion_fuentes_naturales"
              name="instalacion_fuentes_naturales"
              label={
                "80. ¿Existen instalaciones de captación de agua, como extracción de fuentes naturales como pozos?"
              }
              type="text"
              value={formData.instalacion_fuentes_naturales}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="cuenta_planta_potabilizadora"
              name="cuenta_planta_potabilizadora"
              label={"81. ¿Su empresa cuenta con planta potabilizadora?"}
              type="text"
              value={formData.cuenta_planta_potabilizadora}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="cuenta_depositos_distribucion"
              name="cuenta_depositos_distribucion"
              label={
                "82. ¿Cuentan con tanques de depósito de agua para su distribución?"
              }
              type="text"
              value={formData.cuenta_depositos_distribucion}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="cuantos_capacidad"
              name="cuantos_capacidad"
              label={"83. ¿Cuántos y de qué capacidad?"}
              type="text"
              value={formData.cuantos_capacidad}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="redes_secundarias_tanque"
              name="redes_secundarias_tanque"
              label={
                "84. A partir de la red primaria, ¿cuántas redes secundarias se ramifican en el tanque de distribución?"
              }
              type="text"
              value={formData.redes_secundarias_tanque}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="identifica_hallazgos"
              name="identifica_hallazgos"
              label={
                "85. ¿Ha identificado goteos, humedad, pérdidas, baja presión u otros hallazgos de ineficiencia en el sistema?"
              }
              type="text"
              value={formData.identifica_hallazgos}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="cuantos_capacidad"
              name="cuantos_capacidad"
              label={"86. ¿Cuántos y de qué capacidad?"}
              type="text"
              value={formData.cuantos_capacidad}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="redes_secundarias_tanque"
              name="redes_secundarias_tanque"
              label={
                "87. A partir de la red primaria, ¿cuántas redes secundarias se ramifican en el tanque de distribución?"
              }
              type="text"
              value={formData.redes_secundarias_tanque}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <DropDown
              id={"entrada_agua"}
              label={
                "88. ¿El agua de entrada a la empresa es agua potable o agua tratada?"
              }
              value={formData.entrada_agua}
              data={tipoAguaEntrada}
              size={"small"}
              variant="standard"
              disabled
            />
            <TextField
              id="usa_agua_tratada"
              name="usa_agua_tratada"
              label={
                "89. ¿Alguno de sus procesos usa agua tratada? En caso de que no, ¿podría usarla?"
              }
              type="text"
              value={formData.usa_agua_tratada}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="necesita_calidad_agua"
              name="necesita_calidad_agua"
              label={
                "90. Necesita una cierta calidad de agua para sus procesos que consumen mayor cantidad de agua? ¿Cuál?"
              }
              type="text"
              value={formData.necesita_calidad_agua}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
              disabled
            />
            <TextField
              id="sistemas_osmosis_inversa"
              name="sistemas_osmosis_inversa"
              label={
                "91. ¿Cuentan con sistemas de ósmosis inversa, suavizadores, etc.? Descríbelos."
              }
              type="text"
              value={formData.sistemas_osmosis_inversa}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="conectividad_agua_salida"
              name="conectividad_agua_salida"
              label={"92. ¿Qué conductividad tiene el agua de salida?"}
              type="text"
              value={formData.conectividad_agua_salida}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <Typography component={"span"} variant="body1">
              93. ¿Cuentan con la medición de los parámetros físico-químicos del
              agua? Por ejemplo:
              <ol>
                <li>Temperatura del agua</li>
                <li>Contenido de solidos disueltos y totales</li>
                <li>
                  Análisis fisicoquímico del agua para detección de
                  contaminantes de acuerdo con el giro o industria (detergentes,
                  solventes, etc.)
                </li>
              </ol>
            </Typography>
            <TextField
              id="medicion_parametros"
              name="medicion_parametros"
              label={"94. Descríbelos."}
              type="text"
              value={formData.medicion_parametros}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="agua"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
