import axios from "axios";
import { errorValidation } from "../utils/Utils";

export const baseUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/green/`;

export const login = async (dataObj) => {
  const { username, password } = dataObj;
  const url = `${baseUrl}token/`;
  const data = { username: username, password: password };
  // try {
    const res = await axios.post(url, data);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const register = async (dataObj) => {
  const url = `${baseUrl}register`;
  const data = dataObj;
  // try {
    const res = await axios.post(url, data);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  //   throw new Error(error)
  // }
};

export const refreshToken = async (refreshToken) => {
  const url = `${baseUrl}token/refresh/`;
  const data = { refresh: refreshToken };
  try {
    const res = await axios.post(url, data);
    return res;
  } catch (error) {
    errorValidation(error);
  }
};
