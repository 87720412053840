import axios from "axios";
import { errorValidation, getToken } from "../utils/Utils";

const coreUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/core/api/`;

export const getEmpresa = async (empresaId = null) => {

  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}empresa/${empresaId ? empresaId + "/" : ""}`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getEdificios = async (param = null) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}facility/?${param}`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getActivityTypes = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}activity/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getFuel = async (isCustomEF = false) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
    params: {
      is_customEF: isCustomEF,
      scope: "S1",
      activity_type: "Stationary Combustion",
    },
  };
  const url = `${coreUrl}emission-factor/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getEmissionFactors = async (params = {}) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
    params,
  };
  const url = `${coreUrl}emission-factor/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getInvetoryData = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}inventory-data/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getEnergyUnits = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}energy-unit/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getCatUnitFuel = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}fuel-unit/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getCatVehicleType = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}vehicle-type/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getCatFuelSource = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}fuel-source/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getSubactivityType = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}subactivity-type/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getRefrigerantUsed = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}refrigerant-used/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getCalculationApproach = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}calculation-approach/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getTypeEmissionFactor = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}type-emission-factor/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getS3ActivityTypes = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}s3activity/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getModelTransportation = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}model-transportation/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getEFDataset = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}efdataset/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getCategory = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}category/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getTransportationUnit = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}transportation-unit/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getS3VehicleTypes = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}s3vehicletype/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertEmpresa = async (data) => {
  const config = {
    // headers: {
    //   Authorization: `Token ${getToken()}`,
    // },
  };
  const url = `${coreUrl}empresa/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  //   console.log(error)
  //   throw Error(error.response)
  // }
};

export const updateEmpresa = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}empresa/${data.id}/`;
  // try {
    const res = await axios.patch(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertEdificio = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}facility/`;
  // try {
    return axios.post(url, data, config);
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const updateEdificio = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}facility/${data.id}/`;
  // try {
    const res = await axios.patch(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};
