import { Button, Card, CardContent, Chip, Divider, FormControl, Grid, TextField } from '@mui/material'
import React from 'react'
import DropDown from '../../../../components/DropDown'
import { SaveAlt } from '@mui/icons-material'

function RefrigerantForm({
  saveAction,
  refrigerantuseds,
  years,
  facilityIds,
  formData,
  handleChange,
}) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <FormControl fullWidth sx={{ gap:'15px'}} size='small'>
          <TextField 
            error={false}
            id="id"
            name="id"
            value={formData.id}
            size={"small"}
            onChange={handleChange}
            disabled
            sx={{display:"none"}}
            />
          <DropDown
              id={"facilityId"}
              label={"Planta"}
              value={formData.facilityId}
              onChange={handleChange}
              data={facilityIds}
              size={"small"}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"yearId"}
              label={"Año"}
              value={formData.yearId}
              onChange={handleChange}
              data={years}
              size={"small"}
              disabled={formData.id!==""}
            />
            <TextField
              error={false}
              id="type_of_ac"
              name="type_of_ac"
              label={"Tipo de aire acondicionado"}
              type="text"
              value={formData.type_of_ac}
              size={"small"}
              onChange={handleChange}
              disabled={formData.id!==""}
            />
            <DropDown
              id={"refrigerantUsedId"}
              label={"Refrigerante usado"}
              value={formData.refrigerantUsedId}
              onChange={handleChange}
              data={refrigerantuseds}
              size={"small"}
              disabled={formData.id!==""}
            />
            <TextField
              error={false}
              id="inv_begin"
              name="inv_begin"
              label={"Invantario de refrigerante (almacenado, no en el equipo) al comienzo del año"}
              type="number"
              value={formData.inv_begin}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="inv_end"
              name="inv_end"
              label={"Invantario de refrigerante (almacenado, no en el equipo) al fin del año"}
              type="number"
              value={formData.inv_end}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="purchesed_from"
              name="purchesed_from"
              label={"Refrigerante comprado a productores/distribuidores"}
              type="number"
              value={formData.purchesed_from}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="returned_by_user"
              name="returned_by_user"
              label={"Regrigerante devuelto por los usuarios"}
              type="number"
              value={formData.returned_by_user}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="charged_into"
              name="charged_into"
              label={"Refrigerante cargado en el equipo"}
              type="number"
              value={formData.charged_into}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="delivered_to"
              name="delivered_to"
              label={"Refrigerante suministrado a los usuarios en contenedores"}
              type="number"
              value={formData.delivered_to}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="returned_to"
              name="returned_to"
              label={"Refrigerante devuelto a los productores de refrigerante"}
              type="number"
              value={formData.returned_to}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="sent_off_recycle"
              name="sent_off_recycle"
              label={"Refrigerante enviado fuera de las instalaciones para su reciclado o recuperación"}
              type="number"
              value={formData.sent_off_recycle}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="sent_off_site"
              name="sent_off_site"
              label={"Refrigerant sent off-site for destruction"}
              type="number"
              value={formData.sent_off_site}
              size={"small"}
              onChange={handleChange}
            />
            <Divider>
              <Chip label="Paso A: Calcular refrigerante cargado en el equipo" />
            </Divider>
            <TextField
              error={false}
              id="capacity_partial"
              name="capacity_partial"
              label={"Capacidad nominal de los equipos parcialmente cargados"}
              type="number"
              value={formData.capacity_partial}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="pressure_partial"
              name="pressure_partial"
              label={"Densidad o presión de la carga partical"}
              type="number"
              value={formData.pressure_partial}
              size={"small"}
              onChange={handleChange}
            />
            <TextField
              error={false}
              id="pressure_full"
              name="pressure_full"
              label={"Densidad o presión de la carga partical"}
              type="number"
              value={formData.pressure_full}
              size={"small"}
              onChange={handleChange}
            />
          <Grid justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              sx={{ mx: 1 }}
              startIcon={<SaveAlt />}
              onClick={saveAction}
            >
              Guardar
            </Button>
          </Grid>
          </FormControl>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default RefrigerantForm