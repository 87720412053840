import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Typography,Button, Box } from "@mui/material";
import PropTypes from "prop-types";

function Modal({ title, children, openModal, setOpenModal, handleCloseModal }) {
  return (
    <Dialog open={openModal} fullWidth>
      <DialogTitle>
        <Box style={{display:'flex'}}>
        <Typography variant="h6" style={{flexGrow:1}}>
          {title}
        </Typography>
        <Button variant="text" onClick={()=>{setOpenModal(false)}}><Close /></Button>
        </Box>
      </DialogTitle>
      <DialogContent sx={{overflow:'auto'}}>{children}</DialogContent>
    </Dialog>
  );
}

Modal.defaultProps = {
  open:false,
}
Modal.prototype = {
  title: PropTypes.string.isRequired,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired
}
export default Modal;
