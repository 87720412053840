import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DropDown from "../../../components/DropDown";
import { getEdificios } from "../../../api/Core.api";
import LoadingContext from "../../../context/LoadingContext";
import { insertCompromisoEconomiaCircular } from "../../../api/EconomiaCircular.api";
import { enqueueSnackbar } from "notistack";
import { errorHandler } from "../../../utils/ErrorHandler";

function CompromisoEconomiaCircularForm({
  setStepCount,
  stepCount,
  edificioId,
}) {
  const options = [
    { label: "No se menciona", value: 0 },
    { label: "Relevante", value: 1 },
    { label: "Muy relevante", value: 2 },
  ];
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [formData, setFormData] = useState({
    tiene_conocimiento: "",
    existe_estrategia_sostenibilidad: "",
    relevante_CEO: 0,
    relevante_equipo: 0,
    // indices_circularidad: false,
    // comunicacion_EC: false,
    // formacion_EC: false,
    edificioId: edificioId,
  });
  // const [edificio, setEdificio] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  // useEffect(() => {
  //   const loadEdificios = async () => {
  //     try {
  //       const res = await getEdificios("compromisoEconomiaCircular");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificios();
  // }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };

  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertCompromisoEconomiaCircular(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      console.log(error);
      setErrorMessage(errorHandler(error));
    }
  };
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Typography variant="h5">
            Compromiso hacia la Economía Circular
          </Typography>
          <FormControl fullWidth>
            <TextField
              error={
                errorMessage &&
                errorMessage.hasOwnProperty("tiene_conocimiento")
              }
              // error={false}
              id="tiene_conocimiento"
              name="tiene_conocimiento"
              label={
                "8. ¿La empresa tiene conocimiento sobre lo que es la Simbiosis Industrial, Economía Circular, Eco-diseño?"
              }
              type="text"
              value={formData.tiene_conocimiento}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={
                errorMessage &&
                errorMessage.hasOwnProperty("tiene_conocimiento") &&
                errorMessage.tiene_conocimiento
              }
            />
            <TextField
              error={
                errorMessage &&
                errorMessage.hasOwnProperty("existe_estrategia_sostenibilidad")
              }
              // error={false}
              id="existe_estrategia_sostenibilidad"
              name="existe_estrategia_sostenibilidad"
              label={
                "9. ¿Existe estrategia de sostenibilidad/Economía Circular en la empresa?"
              }
              type="text"
              value={formData.existe_estrategia_sostenibilidad}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={
                errorMessage &&
                errorMessage.hasOwnProperty(
                  "existe_estrategia_sostenibilidad"
                ) &&
                errorMessage.existe_estrategia_sostenibilidad
              }
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.tiene_conocimiento}
                  name="tiene_conocimiento"
                  id="tiene_conocimiento"
                  onChange={handleChange}
                />
              }
              label="¿La empresa tiene conocimiento sobre lo que es la Simbiosis Industrial, Economía Circular, Eco-diseño?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.existe_estrategia_sostenibilidad}
                  name="existe_estrategia_sostenibilidad"
                  id="existe_estrategia_sostenibilidad"
                  onChange={handleChange}
                />
              }
              label="¿Existe estrategia de sostenibilidad/Economía Circular en la empresa?"
            /> */}
            <DropDown
              id={"relevante_CEO"}
              label={
                "10. ¿Qué tan relevante es para el CEO promover la Economía Circular dentro de la empresa?"
              }
              value={formData.relevante_CEO}
              onChange={handleChange}
              data={options}
              size={"small"}
              variant="standard"
            />
            <DropDown
              id={"relevante_equipo"}
              label={"11. ¿y el equipo directivo?"}
              value={formData.relevante_equipo}
              onChange={handleChange}
              data={options}
              size={"small"}
              variant="standard"
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.indices_circularidad}
                  name="indices_circularidad"
                  id="indices_circularidad"
                  onChange={handleChange}
                />
              }
              label="¿Índices de circularidad?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.comunicacion_EC}
                  name="comunicacion_EC"
                  id="comunicacion_EC"
                  onChange={handleChange}
                />
              }
              label="¿Comunicación interna relacionada con E.C.?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.formacion_EC}
                  name="formacion_EC"
                  id="formacion_EC"
                  onChange={handleChange}
                />
              }
              label="¿Formación en E.C.?"
            /> */}
            {/* <DropDown
              id={"edificioId"}
              label={"Edificio"}
              value={formData.edificioId}
              onChange={handleChange}
              data={edificio.map((e) => ({ label: e.nick_name, value: e.id }))}
              size={"small"}
              variant="standard"
              helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
            /> */}
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions>
      <Button variant="outlined" color="info" onClick={handleSubmit}>
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setStepCount(stepCount + 1);
          }}
        >
          Omitir
        </Button>
        <Button variant="outlined" color="success" onClick={()=>{setStepCount(8)}}>
          Finalizar
        </Button>
      </CardActions>
    </>
  );
}

export default CompromisoEconomiaCircularForm;
