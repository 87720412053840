import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { blue, green } from "@mui/material/colors";

function StyledCard({ title = "Text", children }) {
  return (
    <Card sx={{ borderRadius: 3, width: "100%" }}>
      <CardHeader
        title={title}
        sx={{
          background: "white",
          color: blue[600],
          borderRadius: 3,
          px: 5,
        }}
      />
      <CardContent>
        <Box mx={0} my={2}>
          {children}
        </Box>
      </CardContent>
    </Card>
  );
}

export default StyledCard;
