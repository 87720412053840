import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import DropDown from "../../../../components/DropDown";
import { SaveAlt } from "@mui/icons-material";
import { useEffect, useState } from "react";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

function TransportationForm({
  formData,
  handleChange,
  SaveAction,
  facilityIds,
  years,
  activityTypes,
  emissionFactorDatasets,
  modeTransports,
  vehicleTypes,
  unitMeasurements,
  categorys,
  customEmisionFactors,
}) {
  const [disableCustomEmissionFactor, setDisableCustomEmissionFactor] =
    useState(true);
  const [disableVehicleType, setDisableVehicleType] = useState(false);

  useEffect(() => {
    const handleDropdowns = () => {
      const dataset = emissionFactorDatasets.find(
        (e) => e.id === formData.ef_dataset_id
      );
      if (dataset && dataset.name.toLowerCase() === "custom emission factor") {
        setDisableCustomEmissionFactor(false);
        setDisableVehicleType(true);
      } else {
        setDisableCustomEmissionFactor(true);
        setDisableVehicleType(false);
      }
    };
    handleDropdowns();
  }, [formData.ef_dataset_id]);
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <FormControl fullWidth>
            <DropDown
              id={"facilityId"}
              label={"Planta"}
              value={formData.facilityId}
              onChange={handleChange}
              data={facilityIds.map((f) => ({
                label: f.nick_name,
                value: f.id,
              }))}
              size={"small"}
            />
            <DropDown
              id={"yearId"}
              label={"Año"}
              value={formData.yearId}
              onChange={handleChange}
              data={years.map((u) => ({ label: u.year, value: u.id }))}
              size={"small"}
            />
            <TextField
              id="description"
              name="description"
              value={formData.description}
              label="Descripcion"
              onChange={handleChange}
              size="small"
            />
            <DropDown
              id={"category_id"}
              label={"Categoria"}
              value={formData.category_id}
              onChange={handleChange}
              data={categorys.map((f) => ({ label: f.name, value: f.id }))}
              size={"small"}
            />
            <DropDown
              id={"ef_dataset_id"}
              label={"Factor de emision"}
              value={formData.ef_dataset_id}
              onChange={handleChange}
              data={emissionFactorDatasets.map((f) => ({
                label: f.name,
                value: f.id,
              }))}
              size={"small"}
            />
            <DropDown
              id={"mode_transport_id"}
              label={"Modo de transporte"}
              value={formData.mode_transport_id}
              onChange={handleChange}
              data={modeTransports.map((f) => ({ label: f.name, value: f.id }))}
              size={"small"}
            />
            <DropDown
              id={"activity_type_id"}
              label={"Tipo de actividad"}
              value={formData.activity_type_id}
              onChange={handleChange}
              data={activityTypes.map((f) => ({ label: f.name, value: f.id }))}
              size={"small"}
            />
            <DropDown
              id={"custom_emission_factorID"}
              label={"Factor de emision personalizado"}
              value={formData.custom_emission_factorID}
              onChange={handleChange}
              data={customEmisionFactors.map((f) => ({
                label: f.name,
                value: f.id,
              }))}
              size={"small"}
              disabled={disableCustomEmissionFactor}
            />
            <DropDown
              id={"vehicle_typeId"}
              label={"Tipo de vehiculo"}
              value={formData.vehicle_typeId}
              onChange={handleChange}
              data={vehicleTypes
                .filter(
                  (v) => v.mode_transportation.id === formData.mode_transport_id
                )
                .filter(
                  (v) => v.emission_factor_dataset.id === formData.ef_dataset_id
                )
                .filter((v) =>
                  v.category.find((c) => c.id === formData.category_id)
                )
                .map((f) => ({ label: f.name, value: f.id }))}
              size={"small"}
              disabled={disableVehicleType}
            />
            <TextField
              error={false}
              id="amount_activity"
              name="amount_activity"
              label={"Monto del tipo de actividad"}
              type="number"
              value={formData.activity_amount}
              size={"small"}
              onChange={handleChange}
            />
            <DropDown
              id={"unit_measurement_id"}
              label={"Unidades"}
              value={formData.unitId}
              onChange={handleChange}
              data={unitMeasurements.map((f) => ({
                label: f.name,
                value: f.id,
              }))}
              size={"small"}
            />
          </FormControl>
          <Grid justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              sx={{ mx: 1 }}
              startIcon={<SaveAlt />}
              onClick={SaveAction}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TransportationForm;
