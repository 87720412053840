import { useContext, useEffect, useState } from "react";
import StyledCard from "../../../../components/StyledCard";
import PurchasedElectricityForm from "./PurchasedElectricityForm";
import Modal from "../../../../components/Modal";
import { Box, Button, Grid } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import LoadingContext from "../../../../context/LoadingContext";
import {
  deletePurchasedElectricity,
  getAllPurchasedElectricity,
  insertPurchasedElectricity,
  updatePurchasedElectricity,
} from "../../../../api/Emissions.api";
import { enqueueSnackbar } from "notistack";
import {
  getCalculationApproach,
  getEdificios,
  getEmissionFactors,
  getEnergyUnits,
  getInvetoryData,
  getTypeEmissionFactor,
} from "../../../../api/Core.api";
import { errorHandler } from "../../../../utils/ErrorHandler";
import { GridActionsCellItem } from "@mui/x-data-grid";

const newPurchasedElectricity = {
  id:"",
  yearId: "",
  facilityId: "",
  amount: 0.0,
  calc_approachId: "",
  type_EFId: "",
  custom_EFId: "",
  unitId:"",
}

function PurchasedElectricityTable() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [purchasedElectricityData, setPurchasedElectricityData] = useState([]);
  const [facilityIds, setFacilityIds] = useState([]);
  const [years, setYears] = useState([]);
  const [units, setUnits] = useState([]);
  const [typesEFs, setTypesEFs] = useState([]);
  const [typeEmissionFactors, setTypeEmissionFactors] = useState([]);
  const [calculationApproachs, setCalculationApproachs] = useState([]);
  const [customEmissionFactors, setCustomEmissionFactors] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [formData, setFormData] = useState(newPurchasedElectricity);

  const fetchPurchasedElectricityData = async () => {
    try {
      const res = await getAllPurchasedElectricity();
      const data = res.data;
      setPurchasedElectricityData(data);
    } catch (error) {
      throw error;
    }
  };
  const fetchYears = async () => {
    try {
      const res = await getInvetoryData();
      setYears(res.data.map((u) => ({ label: u.year, value: u.id })));
    } catch (error) {
      throw error;
    }
  };
  const fetchFacilityIds = async () => {
    try {
      const res = await getEdificios();
      setFacilityIds(
        res.data.map((f) => ({ label: f.nick_name, value: f.id }))
      );
    } catch (error) {
      throw error;
    }
  };
  const fetchUnits = async () => {
    try {
      const res = await getEnergyUnits();
      setUnits(res.data.map((f) => ({ label: f.name, value: f.id })));
    } catch (error) {
      throw error;
    }
  };
  const fetchCustomEF = async () => {
    try {
      const res = await getEmissionFactors({ is_customEF: true, scope: "S2" });
      setCustomEmissionFactors(
        res.data.map((f) => ({ label: f.name, value: f.id }))
      );
    } catch (error) {
      throw error;
    }
  };
  const fetchTypesEF = async () => {
    try {
      const res = await getTypeEmissionFactor();
      setTypesEFs(res.data);
    } catch (error) {
      throw error;
    }
  };
  const fetchCaclculationApproach = async () => {
    try {
      const res = await getCalculationApproach();
      setCalculationApproachs(
        res.data.map((f) => ({ label: f.name, value: f.id }))
      );
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const getAllData = async () => {
      startLoading("Cargando datos de Electricidad Comprada");
      try {
        await fetchFacilityIds();
        await fetchYears();
        await fetchUnits();
        await fetchTypesEF();
        await fetchCaclculationApproach();
        await fetchCustomEF();
        await fetchPurchasedElectricityData();
        stopLoading();
      } catch (error) {
        console.log(error);
        stopLoading();
        errorHandler(error)
      }
    };
    getAllData();
  }, []);

  const clearForm = () => {
    setFormData(newPurchasedElectricity);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    startLoading("Guardando datos");
    try {
      if (formData.id === "") {
        const res = await insertPurchasedElectricity(formData);
        const data = res.data;
        setPurchasedElectricityData([...purchasedElectricityData, data]);
      }else{
        const res = await updatePurchasedElectricity(formData);
        const data = res.data;
        const idx = purchasedElectricityData.findIndex((s) => s.id === data.id);
        const newPurchasedElectricity = purchasedElectricityData;
        Object.keys(newPurchasedElectricity[idx]).forEach((key) => {
          newPurchasedElectricity[idx][key] = data[key];
        });
        setPurchasedElectricityData(newPurchasedElectricity);
      }
      setOpenAddModal(false);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado existosamente",
      });
    } catch (error) {
      console.log(error);
      stopLoading();
      errorHandler(error)
    }
  };

  useEffect(() => {
    setTypeEmissionFactors(
      typesEFs
        .filter((f) => f.purchased_energy.id === formData.calc_approachId)
        .map((o) => ({ label: o.name, value: o.id }))
    );
  }, [formData.calc_approachId]);

  const columns = [
    {
      field:"id",
      headerName:"field",
    },
    {
      field: "year",
      headerName: "Año",
      valueGetter: (params) => {
        return `${params.row.year.year}`;
      },
    },
    {
      field: "facility_id",
      headerName: "Planta",
      valueGetter: (params) => {
        return `${params.row.facility_id.nick_name}`;
      },
    },
    {
      field: "amount",
      headerName: "Monto del consumo de energia",
    },
    {
      field: "unit",
      headerName: "Unidad",
      valueGetter: (params) => {
        return `${params.row.unit.name}`;
      },
    },
    {
      field: "calc_approach",
      headerName: "Metodo de calculo",
      valueGetter: (params) => {
        return `${params.row.calc_approach.name}`;
      },
    },
    {
      field: "type_EF",
      headerName: "Tipo de facturo de emision",
      valueGetter: (params) => {
        return `${params.row.type_EF.name}`;
      },
    },
    {
      field: "custom_EF",
      headerName: "Factor de emision personalizado",
      valueGetter: (params) => {
        return `${params.row.custom_EF.name}`;
      },
    },
    {
      field: "co2",
      headerName: "CO2",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "ch4",
      headerName: "CH4",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "n2o",
      headerName: "N2O",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "co2e",
      headerName: "CO2e",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "ef",
      headerName: "EF (kgCO2e/unit)",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "source",
      headerName: "Fuente",
      flex: 1,
    },
    {
      field: "action",
      sortable: false,
      headerName: "Acciones",
      autoHeight: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <GridActionsCellItem icon={<Edit />} label="Editar" onClick={()=>{
              const row = params.row
              const purchasedElectricityObject = {}
              Object.keys(formData).forEach(key=>{
                purchasedElectricityObject[key] = row[key]
              })
              setFormData(purchasedElectricityObject)
              setOpenAddModal(true);
            }}/>
            <GridActionsCellItem
              icon={<Delete />}
              label="Borrar"
              onClick={() => {
                handleClickDelete(params.id);
              }}
              color="error"
            />
          </>
        );
      },
    },
  ];


  const handleClickDelete = (id) => {
    setOpenDeleteModal(true);
    setDeleteRecord(id);
  };

  const handleDeleteRecord = async () => {
    startLoading("Eliminando registro");
    try {
      await deletePurchasedElectricity(deleteRecord);
      setPurchasedElectricityData(
        purchasedElectricityData.filter((data) => data.id !== deleteRecord)
      );
      stopLoading();
      setOpenDeleteModal(false);
      enqueueSnackbar({
        message: "Registro eliminado de manera exitosa",
        variant: "success",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error);
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteRecord(null);
    }
  }, [openDeleteModal]);

  useEffect(() => {
    if (!openAddModal) {
      clearForm();
    }
  }, [openAddModal]);

  return (
    <>
      <StyledCard title="Electricidad Comprada">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
            m: 2,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              setOpenAddModal(true);
            }}
            sx={{ flex: 0.1 }}
            startIcon={<Add />}
          >
            Agregar
          </Button>
        </Grid>
        {purchasedElectricityData.length > 0 && (
          <CustomDataGrid rows={purchasedElectricityData} columns={columns} sortModel={[{field:'year', sort:'desc'}]} />
        )}
      </StyledCard>
      <Modal
        openModal={openAddModal}
        title={"Agregar nuevo Purchased Electricity"}
        setOpenModal={setOpenAddModal}
      >
        <PurchasedElectricityForm
          facilityIds={facilityIds}
          typesEFs={typeEmissionFactors}
          units={units}
          years={years}
          calculationApproachs={calculationApproachs}
          customEmissionFactors={customEmissionFactors}
          formData={formData}
          handleChange={handleChange}
          cancelAction={() => {
            setOpenAddModal(false);
          }}
          SaveAction={handleSubmit}
        />
      </Modal>
      <Modal
        openModal={openDeleteModal}
        title={"Desea borrar este registo?"}
        setOpenModal={setOpenDeleteModal}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" color="error" onClick={handleDeleteRecord}>
            Confirmar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default PurchasedElectricityTable;
