import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        my: 8,
      }}
    >
      <Typography variant="subtitle2">
        Chihuahua Green Todos los derechos reservados. Desarrollado por{" "}
        <a href="https://rhinowb.com/" target="_blank" rel="noreferrer" style={{color:grey[500],textDecoration:"none"}}>
          Rhino Web Business
        </a>{" "}
      </Typography>
    </Box>
  );
}
