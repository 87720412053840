import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import {
  AcUnit,
  Assignment,
  BarChart,
  Bolt,
  Commute,
  Cottage,
  Dashboard,
  EmojiTransportation,
  Home,
  ListAlt,
  Map,
} from "@mui/icons-material";

export const homeListItem = (
  <List >
    <ListItem>
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary="Inicio"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);

export const polygonListItem = (
  <List >
    <ListItem>
      <ListItemButton component="a" href="/emission/maps">
        <ListItemIcon>
          <Map />
        </ListItemIcon>
        <ListItemText primary="Mapa de Emisiones"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);

export const emissionListItem = (
  <List  disablePadding sx={{ pl: 1 }}>
    <ListItem>
      <ListItemButton component="a" href="/emissions/stationary">
        <ListItemIcon>
          <Cottage />
        </ListItemIcon>
        <ListItemText primary="S1-Combustión Estacionaria"></ListItemText>
      </ListItemButton>
    </ListItem>
    <ListItem>
      <ListItemButton component="a" href="/emissions/mobile">
        <ListItemIcon>
          <EmojiTransportation />
        </ListItemIcon>
        <ListItemText primary="S1-Combustión Móvil"></ListItemText>
      </ListItemButton>
    </ListItem>
    <ListItem>
      <ListItemButton component="a" href="/emissions/refrigerant">
        <ListItemIcon>
          <AcUnit />
        </ListItemIcon>
        <ListItemText primary="S1-Refigerantes"></ListItemText>
      </ListItemButton>
    </ListItem>
    <ListItem>
      <ListItemButton component="a" href="/emissions/purchased-electrocity">
        <ListItemIcon>
          <Bolt />
        </ListItemIcon>
        <ListItemText primary="S2-Electricidad Comprada"></ListItemText>
      </ListItemButton>
    </ListItem>
    <ListItem>
      <ListItemButton component="a" href="/emissions/transport">
        <ListItemIcon>
          <Commute />
        </ListItemIcon>
        <ListItemText primary="S3-Transporte"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);

export const coreListItem = (
  <List >
    <ListItem>
      <ListItemButton component="a" href="/core/emission-factors">
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary="Emission Factors"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);

export const entregablesListItem = (
  <List >
    <ListItem>
      <ListItemButton component="a" href="/entregables">
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        <ListItemText primary="Entregables"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);

export const entregablesDashboardListItem = (
  <List>
    <ListItem>
      <ListItemButton component="a" href="/dashboard-green">
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Dashboard Entregables"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
)

export const formulariosListItem = (
  <List >
    <ListItem>
      <ListItemButton component="a" href="/formulario">
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        <ListItemText primary="Formularios"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);

export const formulariosDashboardListItem = (
  <List >
    <ListItem>
      <ListItemButton component="a" href="/dashboard/energia-circular">
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText primary="Dasboard Formularios"></ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);
