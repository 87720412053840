import { useState, useEffect, useContext } from "react";
import {
  deleteStationaryCombustion,
  getAllStationaryCombustion,
  insertStationaryCombustion,
  updateStationaryCombustion,
} from "../../../../api/Emissions.api";
import StationaryForm from "./StationaryForm";
import { Box, Button, Grid } from "@mui/material";
import StyledCard from "../../../../components/StyledCard";
import Modal from "../../../../components/Modal";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  getEdificios,
  getEnergyUnits,
  getInvetoryData,
  // getFuel,
  getEmissionFactors,
} from "../../../../api/Core.api";
import { enqueueSnackbar } from "notistack";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import LoadingContext from "../../../../context/LoadingContext";
import { errorHandler } from "../../../../utils/ErrorHandler";
import { GridActionsCellItem } from "@mui/x-data-grid";

const newStationary = {
  id:"",
  facilityId: "",
  yearId: "",
  is_custom_EF: false,
  fuelId: "",
  amount: 0,
  unitId: "",
}

function StationaryTable() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [stationaryData, setStationaryData] = useState([]);
  const [facilityIds, setFacilityIds] = useState(undefined);
  const [years, setYears] = useState(undefined);
  const [fuels, setFuels] = useState(undefined);
  const [units, setUnits] = useState(undefined);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);

  const columns = [
    {
      field: "id",
      headerName: "id",
    },
    {
      field: "facility_id",
      headerName: "Planta",
      valueGetter: (params) => {
        return `${params.row.facility_id.nick_name}`;
      },
      flex: 0.25,
    },
    {
      field: "year.year",
      headerName: "Año",
      valueGetter: (params) => {
        return `${params.row.year.year}`;
      },
    },
    {
      field: "is_custom_EF",
      headerName: "Es factor de emision personalizado",
      type: "boolean",
    },
    {
      field: "fuel",
      headerName: "Combustible",
      valueGetter: (params) => {
        return `${params.row.fuel.name}`;
      },
      flex: 0.25,
    },
    {
      field: "amount",
      headerName: "Monto de combustible",
    },
    {
      field: "unit",
      headerName: "Unidad",
      valueGetter: (params) => {
        return `${params.row.unit.name}`;
      },
    },
    {
      field: "co2",
      headerName: "CO2",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "ch4",
      headerName: "CH4",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "n2o",
      headerName: "N2O",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "co2e",
      headerName: "CO2e",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "biofuel_co2",
      headerName: "Biofuel CO2",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "ef",
      headerName: "EF (kgCO2e/unit)",
      type: "number",
      valueFormatter: (params) => {
        if (params.value === 0) {
          return params.value;
        }
        return params.value.toFixed(7);
      },
    },
    {
      field: "source",
      headerName: "Fuente",
      autoHeight: true,
      flex: 1,
    },
    {
      field: "action",
      sortable: false,
      headerName: "Acciones",
      autoHeight: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <GridActionsCellItem icon={<Edit />} label="Editar"  onClick={()=>{
              const row = params.row
              const stationaryObject = {}
              Object.keys(formData).forEach(key=>{
                stationaryObject[key] = row[key]
              })
              setFormData(stationaryObject)
              setOpenAddModal(true);
            }}/>
            <GridActionsCellItem
              icon={<Delete />}
              label="Borrar"
              onClick={() => {
                handleClickDelete(params.id);
              }}
              color="error"
            />
          </>
        );
      },
    },
  ];

  const [formData, setFormData] = useState(newStationary);

  const clearForm = () => {
    setFormData(newStationary);
  };

  const loadStationaryCombustions = async () => {
    try {
      const res = await getAllStationaryCombustion();
      setStationaryData(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchUnits = async () => {
    try {
      const res = await getEnergyUnits();
      setUnits(res.data.map((u) => ({ label: u.name, value: u.id })));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchFuels = async () => {
    const params = { is_customEF: true, scope: "S3" };
    try {
      const params = { scope: "S1", activity_type: "Stationary Combustion" };
      const res = await getEmissionFactors(params);
      setFuels(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchYears = async () => {
    try {
      const res = await getInvetoryData();
      setYears(res.data.map((u) => ({ label: u.year, value: u.id })));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const fetchFacilityIds = async () => {
    try {
      const res = await getEdificios();
      setFacilityIds(
        res.data.map((f) => ({ label: f.nick_name, value: f.id }))
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    const getAllData = async () => {
      startLoading("Cargando datos de Combustion Estacionaria");
      try {
        await fetchFacilityIds();
        await fetchFuels();
        await fetchUnits();
        await fetchYears();
        await loadStationaryCombustions();
        stopLoading();
      } catch (error) {
        stopLoading();
        errorHandler(error);
      }
    };
    getAllData();
  }, []);

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteRecord(null);
    }
  }, [openDeleteModal]);

  const handleChange = (event) => {
    if (event.target.name === "is_custom_EF") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.checked,
        fuelId: "",
      });
      return;
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    startLoading("Guardando");
    try {
      if(formData.id===""){
        const res = await insertStationaryCombustion(formData);
        const data = res.data;
        setStationaryData([...stationaryData, data]);
      }else{
        const res = await updateStationaryCombustion(formData);
        const data = res.data;
        const idx = stationaryData.findIndex(s=>s.id===data.id);
        const newStationary = stationaryData
        Object.keys(newStationary[idx]).forEach(key=>{
          newStationary[idx][key] = data[key];
        })
        setStationaryData(newStationary)
        // await loadStationaryCombustions()
      }
      setOpenAddModal(false);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchFuels();
  }, [formData.is_custom_EF]);

  useEffect(() => {
    if(!openAddModal){
      clearForm();
    }
  }, [openAddModal]);

  const handleClickDelete = (id) => {
    setOpenDeleteModal(true);
    setDeleteRecord(id);
  };

  const handleDeleteRecord = async () => {
    startLoading("Eliminando registro");
    try {
      await deleteStationaryCombustion(deleteRecord);
      setStationaryData(
        stationaryData.filter((data) => data.id !== deleteRecord)
      );
      stopLoading();
      setOpenDeleteModal(false);
      enqueueSnackbar({
        message: "Registro eliminado de manera exitosa",
        variant: "success",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error);
      setOpenDeleteModal(false);
    }
  };

  return (
    <>
      <StyledCard title="Combustion Estacionaria">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
            m: 2,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              setOpenAddModal(true);
            }}
            sx={{ flex: 0.1 }}
            startIcon={<Add />}
          >
            Agregar
          </Button>
        </Grid>
        {stationaryData.length > 0 && (
          <CustomDataGrid
            columns={columns}
            rows={stationaryData}
            sortModel={[{ field: "year.year", sort: "desc" }]}
          />
        )}
      </StyledCard>
      <Modal
        openModal={openAddModal}
        title={"Agregar nuevo Stationary"}
        setOpenModal={setOpenAddModal}
      >
        <StationaryForm
          fuels={fuels}
          units={units}
          years={years}
          facilityIds={facilityIds}
          formData={formData}
          handleChange={handleChange}
          cancelAction={() => {
            setOpenAddModal(false);
          }}
          saveAction={handleSubmit}
        />
      </Modal>

      <Modal
        openModal={openDeleteModal}
        title={"Desea borrar este registo?"}
        setOpenModal={setOpenDeleteModal}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" color="error" onClick={handleDeleteRecord}>
            Confirmar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default StationaryTable;
