import React, { useContext, useEffect, useState } from "react";
import { getEdificios } from "../../../api/Core.api";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DropDown from "../../../components/DropDown";
import LoadingContext from "../../../context/LoadingContext";
import { insertEnergiaElectrica } from "../../../api/EconomiaCircular.api";
import { enqueueSnackbar } from "notistack";
import { errorHandler } from "../../../utils/ErrorHandler";

function EnergiaElectricaForm({ setStepCount, stepCount, edificioId }) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [errorMessage, setErrorMessage] = useState({});
  const [formData, setFormData] = useState({
    voltaje_trasmision_CFE: "",
    tiene_subestacion: "",
    tipo_instalacion: "",
    kVAs: "",
    voltaje_corriente_CFE: "",
    instalacion: "",
    tiene_plano_ubicacion_subestacion: "",
    correspondientes: 0,
    planta: 0,
    cantidad_servicios: "",
    tipo_techo_condicion_actual: "",
    material_tipo_construccion: "",
    analisis_estructural_carga_soportada: "",
    tiene_planos_estacionamiento: "",
    diagrama_unifilar:null,
    edificioId: edificioId,
  });

  // const [edificio, setEdificio] = useState([]);

  // useEffect(() => {
  //   const loadEdificios = async () => {
  //     try {
  //       const res = await getEdificios("energiaElectrica");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificios();
  // }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };

  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertEnergiaElectrica(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      console.log(error);
      setErrorMessage(error.response?.data);
      errorHandler(error)
    }
  };
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Typography variant="h5">ENERGÍA ELÉCTRICA</Typography>
          <FormControl fullWidth>
            <Box sx={{my:4}}>
              <Typography variant="body2">95. Agregar ultimos 12 recibos de energia</Typography>
              <input type="file" name="recibos_energia" multiple accept="image/png, .jpeg, .jpg, image/gif"/>
            </Box>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("voltaje_trasmision_CFE")}
              helperText={errorMessage && errorMessage.hasOwnProperty("voltaje_trasmision_CFE") && errorMessage.voltaje_trasmision_CFE}
              id="voltaje_trasmision_CFE"
              name="voltaje_trasmision_CFE"
              label={"96. Voltaje de transmisión por parte de CFE"}
              type="text"
              value={formData.voltaje_trasmision_CFE}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("tipo_instalacion")}
              helperText={errorMessage && errorMessage.hasOwnProperty("tipo_instalacion") && errorMessage.tipo_instalacion}
              id="tipo_instalacion"
              name="tipo_instalacion"
              label={"97. Tipo de instalación"}
              type="text"
              value={formData.tipo_instalacion}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("kVAs")}
              helperText={errorMessage && errorMessage.hasOwnProperty("kVAs") && errorMessage.kVAs}
              id="kVAs"
              name="kVAs"
              label={"98. [kVAs]:"}
              type="text"
              value={formData.kVAs}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("voltaje_corriente_CFE")}
              helperText={errorMessage && errorMessage.hasOwnProperty("voltaje_corriente_CFE") && errorMessage.voltaje_corriente_CFE}
              id="voltaje_corriente_CFE"
              name="voltaje_corriente_CFE"
              label={
                "99. Voltaje y corriente del servicio actual (el que entrega CFE)"
              }
              type="text"
              value={formData.voltaje_corriente_CFE}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("instalacion")}
              helperText={errorMessage && errorMessage.hasOwnProperty("instalacion") && errorMessage.instalacion}
              id="instalacion"
              name="instalacion"
              label={"100. Indique si es Monofásico, Bifásico o Trifásico"}
              type="text"
              value={formData.instalacion}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cantidad_servicios")}
              helperText={errorMessage && errorMessage.hasOwnProperty("cantidad_servicios") && errorMessage.cantidad_servicios}
              id="cantidad_servicios"
              name="cantidad_servicios"
              label={"101. ¿Con cuántos servicios (recibos) de CFE cuenta el cliente?"}
              type="text"
              value={formData.cantidad_servicios}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Techado:
            </Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("tipo_techo_condicion_actual")}
              helperText={errorMessage && errorMessage.hasOwnProperty("tipo_techo_condicion_actual") && errorMessage.tipo_techo_condicion_actual}
              id="tipo_techo_condicion_actual"
              name="tipo_techo_condicion_actual"
              label={"102. ¿Cuál es el tipo de techo y condición actual de este?"}
              type="text"
              value={formData.tipo_techo_condicion_actual}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <Typography variant="subtitle1">
                Especificar:
            </Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("material_tipo_construccion")}
              helperText={errorMessage && errorMessage.hasOwnProperty("material_tipo_construccion") && errorMessage.material_tipo_construccion}
              id="material_tipo_construccion"
              name="material_tipo_construccion"
              label={"103. Material y tipo de construcción:"}
              type="text"
              value={formData.material_tipo_construccion}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("analisis_estructural_carga_soportada")}
              helperText={errorMessage && errorMessage.hasOwnProperty("analisis_estructural_carga_soportada") && errorMessage.analisis_estructural_carga_soportada}
              id="analisis_estructural_carga_soportada"
              name="analisis_estructural_carga_soportada"
              label={"104. ¿Se cuenta con un análisis estructural actual de la techumbre y de la carga que puede soportar?"}
              type="text"
              value={formData.analisis_estructural_carga_soportada}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Carport:
            </Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("tiene_planos_estacionamiento")}
              helperText={errorMessage && errorMessage.hasOwnProperty("tiene_planos_estacionamiento") && errorMessage.tiene_planos_estacionamiento}
              id="tiene_planos_estacionamiento"
              name="tiene_planos_estacionamiento"
              label={
                "105. En caso de buscar la opción de instalación de un Carport, ¿se tiene plano del estacionamiento (autocad)? ¿Existen los planos actualizados en que se muestren claramente y sea posible ubicar las instalaciones / servicios subterráneos?"
              }
              type="text"
              value={formData.tiene_planos_estacionamiento}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={7}
            />
            <Box sx={{my:4}}>
              <Typography variant="body2">106. Agregar diagrama unifilar</Typography>
              <input type="file" name="diagrama_unifilar" multiple accept="image/png, .jpeg, .jpg, image/gif"/>
            </Box>
            {/* <DropDown
              id={"edificioId"}
              label={"Edificio"}
              value={formData.edificioId}
              onChange={handleChange}
              data={edificio.map((e) => ({ label: e.nick_name, value: e.id }))}
              size={"small"}
              variant="standard"
              helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
            /> */}
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions>
      <Button variant="outlined" color="info" onClick={handleSubmit}>
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setStepCount(stepCount + 1);
          }}
        >
          Omitir
        </Button>
        <Button variant="outlined" color="success" onClick={()=>{setStepCount(8)}}>
          Finalizar
        </Button>
      </CardActions>
    </>
  );
}

export default EnergiaElectricaForm;
