import { createContext, useState } from "react";
// import AuthContext from "./AuthContext";

const LoadingContext = createContext();

export default LoadingContext;

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const startLoading = (message = null) => {
    setLoading(true);
    setMessage(message);
  };

  const stopLoading = () => {
    setLoading(false);
    setMessage(null);
  };

  const contextData = {
    loading: loading,
    message: message,
    startLoading: startLoading,
    stopLoading: stopLoading,
  };

  return (
    <LoadingContext.Provider value={contextData}>
      {children}
    </LoadingContext.Provider>
  );
};
