import { useContext, useEffect, useState } from "react";
import LoadingContext from "../../context/LoadingContext";
import { enqueueSnackbar } from "notistack";
import { Box, Button, Grid } from "@mui/material";
import { getEdificios, getEmpresa } from "../../api/Core.api";
import { getEmissionsPerBuilding } from "../../api/Entregables";
import DropDown from "../../components/DropDown";
import LeftAside from "./components/LeftAside";
import MainSection from "./components/MainSection";
import RightAside from "./components/RightAside";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../utils/ErrorHandler";

function Entregables() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const [empresas, setEmpresas] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [emissions, setEmissions] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [planta, setPlanta] = useState("");

  useEffect(() => {
    const loadData = async () => {
      startLoading();
      try {
        await fetchEmpresas();
        await fetchPlantas();
        stopLoading();
      } catch (error) {
        console.log(error);
        stopLoading();
        errorHandler(error)
        // enqueueSnackbar({message:error,variant:'error'})
      }
    };
    loadData();
  }, []);

  const fetchEmpresas = async () => {
    try {
      const res = await getEmpresa();
      setEmpresas(res.data);
    } catch (error) {
      console.log(error);
      throw error
    }
  };

  const fetchPlantas = async () => {
    try {
      const res = await getEdificios();
      setPlantas(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleChangeEmpresa = (e) => {
    e.preventDefault();
    setEmpresa(e.target.value);
    setPlanta("");
  };

  const handleChangePlanta = (e) => {
    e.preventDefault();
    setPlanta(e.target.value);
    fetchEmissions(e.target.value);
  };

  const fetchEmissions = async (planta) => {
    startLoading();
    const build = {
      build_id: planta,
    };
    try {
      const res = await getEmissionsPerBuilding(build);
      const data = res.data
      setEmissions({
        stationary:data.stationary.map(e=>({year:e.yearf,scope:'S1 Comb. Estacionaria',co2:Number(e.total_co2)||0,biofuel:Number(e.total_biofuel)||0,ch4:Number(e.total_ch4)||0,n2o:Number(e.total_n2o)||0})),
        mobile:data.mobile.map(e=>({year:e.yearf,scope:'S1 Comb. Movil',co2:Number(e.total_co2)||0,biofuel:Number(e.total_biofuel)||0,ch4:Number(e.total_ch4)||0,n2o:Number(e.total_n20)||0})),
        refrigerant:data.refrigerant.map(e=>({year:e.yearf,scope:'S1 Refrigerante',co2:Number(e.total_co2)||0,ch:0,biofuel:0,n2o:0})),
        purchaseElectricity:data.purchaseElectricity.map(e=>({year:e.yearf,scope:'S2 Electricidad',co2:Number(e.total_co2)||0,ch4:Number(e.total_ch4)||0,n2o:Number(e.total_n2o)||0})),
        transportation:data.transportation.map(e=>({year:e.yearf,scope:'S3 Transporte',co2:Number(e.total_co2)||0,biofuel:Number(e.total_biofuel)||0,ch4:Number(e.total_ch4)||0,n2o:Number(e.total_n2o)||0})),
      })
      stopLoading()
    }catch(error){
      console.log(error)
      stopLoading()
      throw error
    }
  };

  const handleClick = (e) => {
    navigate(`/download/${empresa}/${planta}`)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DropDown
            id={"empresa"}
            label={"Empresa"}
            value={empresa}
            onChange={handleChangeEmpresa}
            data={empresas.map((e) => ({
              value: e.id,
              label: e.nombre_comercial,
            }))}
            size={"small"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DropDown
            id={"planta"}
            label={"Planta"}
            value={planta || ""}
            onChange={handleChangePlanta}
            data={plantas
              .filter((e) => e.empresa_id === empresa)
              .map((e) => ({ value: e.id, label: e.nick_name }))}
            size={"small"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button variant="contained" onClick={handleClick}>Descargar entregable</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <LeftAside
                empresa={empresas.filter((e) => e.id === empresa)[0]}
                planta={plantas.filter((e) => e.id === planta)[0]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MainSection
              emissions={emissions}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RightAside emissions={emissions}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Entregables;
