import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";
import EmpresaForm from "./components/EmpresaForm";
import { useContext, useState } from "react";
import LoadingContext from "../../../context/LoadingContext";
import AuthContext from "../../../context/AuthContext";
import { enqueueSnackbar } from "notistack";
import { updateEmpresa } from "../../../api/Core.api";
import { errorHandler } from "../../../utils/ErrorHandler";

function Empresa({ data }) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState(data);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading("Guardando datos");
    try {
      const res = await updateEmpresa(formData);
      const data = res.data;
      setFormData(data);
      setOpenModal(false);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado existosamente",
        autoHideDuration: 8000,
      });
    } catch (error) {
      stopLoading();
      errorHandler(error)
    }
  };
  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Typography variant="h5" >
            {formData.nombre_comercial}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            RFC
          </Typography>
          <Typography>{formData.rfc}</Typography>
          <Typography variant="body2" color="text.secondary">
            Nombre fiscal
          </Typography>
          <Typography>{formData.nombre_fiscal}</Typography>
          <Typography variant="body2" color="text.secondary">
            Domicilio Fiscal
          </Typography>
          <Typography>{formData.dom_fiscal}</Typography>
          <Typography variant="body2" color="text.secondary">
            Contacto
          </Typography>
          <Typography>{formData.contacto}</Typography>
          <Typography variant="body2" color="text.secondary">
            Puesto
          </Typography>
          <Typography>{formData.puesto}</Typography>
          <Typography variant="body2" color="text.secondary">
            Cantidad de edificios
          </Typography>
          <Typography>{formData.cantidad_edificios}</Typography>
        </CardContent>
      </Card>
      {user?.permisos?.find((f) => f === "core.change_empresa") && (
        <CardActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Editar
          </Button>
        </CardActions>
      )}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Editar informacion de la empresa"
      >
        <EmpresaForm
          formData={formData}
          handleChange={handleChange}
          saveAction={handleSubmit}
        />
      </Modal>
    </>
  );
}
Empresa.defaultProps = {
  data: {},
};
Empresa.prototype = {
  data: PropTypes.object.isRequired,
};
export default Empresa;
