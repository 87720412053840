import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function FinalizarFormulario() {
  const navigate = useNavigate();
  useEffect(() => {
    const exit = () => {
      navigate("/formulario");
    };
    setTimeout(exit, 10000);
  }, []);

  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Typography variant="h5">Formulario completado.</Typography>
          <FormControl fullWidth>
            <Typography variant="subtitle1">
              Ha completado el formulario con éxito. Recuerde que si hay
              secciones que requieren ser llenadas, por favor regrese a
              terminarlas. Gracias.
            </Typography>
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" color="info" href="/formulario">
          Terminar
        </Button>
      </CardActions>
    </>
  );
}
