import { SaveAlt } from "@mui/icons-material";
import { Box, Button, Grid, TextField } from "@mui/material";

export default function ChangePasswordForm({
  formData,
  handleChange,
  handleSubmit,
  error,
  cancel,
}) {
  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            required
            fullWidth
            id="old_password"
            label="Contraseña anterior"
            name="old_password"
            onChange={handleChange}
            variant="standard"
            type="password"
            value={formData.old_password}
            error={error.hasOwnProperty("old_password")}
            helperText={error.hasOwnProperty("old_password") && error.old_password}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus
            required
            fullWidth
            id="new_password"
            label="Contraseña nueva"
            name="new_password"
            onChange={handleChange}
            variant="standard"
            type="password"
            value={formData.new_password}
            error={error.hasOwnProperty("new_password")}
            helperText={error.hasOwnProperty("new_password") && error.new_password}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus
            required
            fullWidth
            id="new_password2"
            label="Repetir contraseña nueva"
            name="new_password2"
            onChange={handleChange}
            autoComplete="username"
            variant="standard"
            type="password"
            value={formData.new_password2}
            error={error.hasOwnProperty("new_password2")}
            helperText={error.hasOwnProperty("new_password2") && error.new_password2}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          startIcon={<SaveAlt />}
          sx={{ mt: 3, mb: 2 }}
        >
          Guardar
        </Button>
        <Button
          type="cancel"
          fullWidth
          variant="outlined"
          color="error"
          sx={{ mt: 3, mb: 2 }}
          onClick={cancel}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
}
