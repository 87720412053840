import { SaveAlt } from "@mui/icons-material";
import { Box, Button, Grid, TextField } from "@mui/material";

export default function UserUpdateForm({
  formData,
  handleChange,
  handleSubmit,
  error,
  cancel,
}) {
  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            required
            fullWidth
            id="username"
            label="Nombre de usuario"
            name="username"
            onChange={handleChange}
            autoComplete="username"
            variant="standard"
            value={formData.username}
            error={error.hasOwnProperty("username")}
            helperText={error.hasOwnProperty("username") && error.username}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="Nombre"
            name="first_name"
            required
            fullWidth
            id="first_name"
            label="Nombre"
            onChange={handleChange}
            variant="standard"
            value={formData.first_name}
            error={error.hasOwnProperty("first_name")}
            helperText={error.hasOwnProperty("first_name") && error.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="last_name"
            label="Apellidos"
            name="last_name"
            onChange={handleChange}
            variant="standard"
            autoComplete="Apellidos"
            value={formData.last_name}
            error={error.hasOwnProperty("last_name")}
            helperText={error.hasOwnProperty("last_name") && error.last_name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Correo electronico"
            name="email"
            type="email"
            onChange={handleChange}
            variant="standard"
            autoComplete="email"
            value={formData.email}
            error={error.hasOwnProperty("email")}
            helperText={error.hasOwnProperty("email") && error.email}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", gap:3 }}>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          startIcon={<SaveAlt />}
          sx={{ mt: 3, mb: 2 }}
        >
          Guardar
        </Button>
        <Button
          type="cancel"
          fullWidth
          variant="outlined"
          color="error"
          sx={{ mt: 3, mb: 2 }}
          onClick={cancel}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
}
