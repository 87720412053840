import React, { useEffect, useState } from "react";
import MapsRender from "./components/MapsRender";
import { getAllPolygons, getAllStains } from "../../api/EmissionMaps";
import { Circle, InfoBox, Polygon } from "@react-google-maps/api";
import Stain from "./components/Stain";
import PolygonLabel from "./components/PolygonLabel";
import { Grid } from "@mui/material";
import { errorHandler } from "../../utils/ErrorHandler";

function EmissionsView() {
  const [polygons, setPolygons] = useState([]);
  const [stains, setStains] = useState([]);

  const getPolygon = async () => {
    try {
      const res = await getAllPolygons();
      setPolygons(res.data);
    } catch (error) {
      throw error;
    }
  };

  const getStains = async () => {
    try {
      const res = await getAllStains();
      setStains(res.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        await getPolygon();
        await getStains();
      } catch (error) {
        errorHandler(error)
      }
    };
    getData();
  }, []);  

  return (
    <>
      <Grid item xs={12}>
        <MapsRender>
          {stains.length > 0 &&
            stains.map((s) => (
              <Stain 
              key={s.id}
              center={{ lat: s.center_lat, lng: s.center_lng }}
              radius={s.co2_area}
              options={stainsOption}
              title={s.name}
              />
            ))}
          {polygons.length > 0 &&
            polygons.map((polygon) => (
              polygon.points.length > 0 &&
              <PolygonLabel
                key={polygon.id}
                paths={polygon.points.map((p) => ({ lat: p.lat, lng: p.lng }))}
                polygonsOption={polygonsOption}
                title={polygon.name}
                center={{lat:polygon.points[0].lat,lng:polygon.points[0].lng}}
              />
            ))}
        </MapsRender>
      </Grid>
    </>
  );
}

export default EmissionsView;

const stainsOption = {
  strokeOpacity: 0.2,
  strokeWeight: 1,
  strokeColor: "#1b344b",
  fillColor: "#1b344b",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};

const polygonsOption = {
  strokeOpacity: 0.2,
  strokeWeight: 1,
  strokeColor: "#b3b300",
  fillColor: "#b3b300",
  fillOpacity: 0.3,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 0,
};

