import {
  Button,
  Card,
  CardActions,
  CardContent,
  // Checkbox,
  FormControl,
  // FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
// import { getEdificios } from "../../../api/Core.api";
import DropDown from "../../../components/DropDown";
import LoadingContext from "../../../context/LoadingContext";
import { enqueueSnackbar } from "notistack";
import { insertAgua } from "../../../api/EconomiaCircular.api";
import { errorHandler } from "../../../utils/ErrorHandler";

function AguaForm({ setStepCount, stepCount, edificioId }) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [errorMessage, setErrorMessage] = useState({});
  const tipoAguaEntrada = [
    { label: "Tratada", value: 0 },
    { label: "Potable", value: 1 },
  ];
  const tipoFuenteAgua = [
    { label: "Potable", value: 0 },
    { label: "Regenerada", value: 1 },
  ];
  const [formData, setFormData] = useState({
    utiliza_agua_en_procesos: "",
    en_que_procesos: "",
    fuente_agua: "",
    agua_utilizada_mes: "",
    agua_residual_generada: "",
    donde_va_agua_residual: "",
    proceso_regeneracion: "",
    nombre_proceso_regeneracion: "",
    interesado_planta_tratadora: "",
    cuenta_plano_tuberias_agua: "",
    cuanto_gasta_agua: "",
    iniciativa_manejo_eficiente: "",
    antiguedad_instalaciones_hidraulicas: "",
    cuenta_croquis_sistema_hidraulico: "",
    estudio_balance_agua: "",
    usos_dados: "",
    abastecimiento_agua_caliente: "",
    abastecimiento_agua_fria: "",
    instalacion_fuentes_naturales: "",
    cuenta_planta_potabilizadora: "",
    cuenta_depositos_distribucion: "",
    cuantos_capacidad: "",
    redes_secundarias_tanque: "",
    identifica_hallazgos: "",
    utiliza_medio_termico_proceso_productivo: "",
    que_hace_con_agua_trasporte_termico: "",
    // recepcion_agua_pozos_red_distribucion: false,
    // demandas_extraordinarias: false,
    // tanques_almacenamiento: false,
    entrada_agua: "",
    usa_agua_tratada: "",
    necesita_calidad_agua: "",
    sistemas_osmosis_inversa: "",
    conectividad_agua_salida: "",
    medicion_parametros: "",
    edificioId: edificioId,
  });
  // const [edificio, setEdificio] = useState([]);

  // useEffect(() => {
  //   const loadEdificios = async () => {
  //     try {
  //       const res = await getEdificios("agua");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificios();
  // }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };

  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertAgua(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      console.log(error);
      setErrorMessage(error.response?.data);
      errorHandler(error);
    }
  };
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Typography variant="h5">AGUA</Typography>
          <FormControl fullWidth>
          <TextField
              error={errorMessage && errorMessage.hasOwnProperty("utiliza_agua_en_procesos")}
              id="utiliza_agua_en_procesos"
              name="utiliza_agua_en_procesos"
              label={"63. ¿Utilizas agua en tus procesos industriales?"}
              type="text"
              value={formData.utiliza_agua_en_procesos}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("utiliza_agua_en_procesos")&&errorMessage.utiliza_agua_en_procesos}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.utiliza_agua_en_procesos}
                  name="utiliza_agua_en_procesos"
                  id="utiliza_agua_en_procesos"
                  onChange={handleChange}
                />
              }
              label="¿Utilizas agua en tus procesos industriales?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("en_que_procesos")}
              helperText={errorMessage && errorMessage.hasOwnProperty("en_que_procesos") && errorMessage.en_que_procesos}
              id="en_que_procesos"
              name="en_que_procesos"
              label={
                "64. En caso de sí, ¿cuáles son tus procesos que utilizan agua?"
              }
              type="text"
              value={formData.en_que_procesos}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <DropDown
              id={"fuente_agua"}
              label={"65. ¿Cuál es la fuente de agua?"}
              value={formData.fuente_agua}
              onChange={handleChange}
              data={tipoFuenteAgua}
              size={"small"}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("agua_utilizada_mes")}
              helperText={errorMessage && errorMessage.hasOwnProperty("agua_utilizada_mes") && errorMessage.agua_utilizada_mes}
              id="agua_utilizada_mes"
              name="agua_utilizada_mes"
              label={"66. ¿Cuánta agua utilizas al mes?"}
              type="text"
              value={formData.agua_utilizada_mes}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("agua_residual_generada")}
              helperText={errorMessage && errorMessage.hasOwnProperty("agua_residual_generada") && errorMessage.agua_residual_generada}
              id="agua_residual_generada"
              name="agua_residual_generada"
              label={"67. ¿Cuánta agua residual generas?"}
              type="text"
              value={formData.agua_residual_generada}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("donde_va_agua_residual")}
              helperText={errorMessage && errorMessage.hasOwnProperty("donde_va_agua_residual") && errorMessage.donde_va_agua_residual}
              id="donde_va_agua_residual"
              name="donde_va_agua_residual"
              label={"68. ¿A dónde se va tu agua residual?"}
              type="text"
              value={formData.donde_va_agua_residual}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("proceso_regeneracion")}
              // error={false}
              id="proceso_regeneracion"
              name="proceso_regeneracion"
              label={"69. ¿Tienes algún proceso de regeneración de agua?"}
              type="text"
              value={formData.proceso_regeneracion}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("proceso_regeneracion")&&errorMessage.proceso_regeneracion}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.proceso_regeneracion}
                  name="proceso_regeneracion"
                  id="proceso_regeneracion"
                  onChange={handleChange}
                />
              }
              label="¿Tienes algún proceso de regeneración de agua?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("interesado_planta_tratadora")}
              // error={false}
              id="interesado_planta_tratadora"
              name="interesado_planta_tratadora"
              label={"70. ¿Estas interesado en poner una planta tratadora?"}
              type="text"
              value={formData.interesado_planta_tratadora}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("interesado_planta_tratadora")&&errorMessage.interesado_planta_tratadora}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.interesado_planta_tratadora}
                  name="interesado_planta_tratadora"
                  id="interesado_planta_tratadora"
                  onChange={handleChange}
                />
              }
              label="¿Estas interesado en poner una planta tratadora?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuenta_plano_tuberias_agua")}
              // error={false}
              id="cuenta_plano_tuberias_agua"
              name="cuenta_plano_tuberias_agua"
              label={"71. ¿Se cuenta con plano general de la planta, donde especifique las tuberías de agua que hay en la planta?"}
              type="text"
              value={formData.cuenta_plano_tuberias_agua}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("cuenta_plano_tuberias_agua")&&errorMessage.cuenta_plano_tuberias_agua}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.cuenta_plano_tuberias_agua}
                  name="cuenta_plano_tuberias_agua"
                  id="cuenta_plano_tuberias_agua"
                  onChange={handleChange}
                />
              }
              label="¿Se cuenta con plano general de la planta, donde especifique las tuberías de agua que hay en la planta?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuanto_gasta_agua")}
              helperText={errorMessage && errorMessage.hasOwnProperty("cuanto_gasta_agua") && errorMessage.cuanto_gasta_agua}
              id="cuanto_gasta_agua"
              name="cuanto_gasta_agua"
              label={"72. ¿Cuánto gastan en agua?"}
              type="text"
              value={formData.cuanto_gasta_agua}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={2}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("iniciativa_manejo_eficiente")}
              helperText={errorMessage && errorMessage.hasOwnProperty("iniciativa_manejo_eficiente") && errorMessage.iniciativa_manejo_eficiente}
              id="iniciativa_manejo_eficiente"
              name="iniciativa_manejo_eficiente"
              label={
                "73. ¿Tienes alguna iniciativa para manejo de agua eficiente en tu empresa? ¿Cuál?"
              }
              type="text"
              value={formData.iniciativa_manejo_eficiente}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("antiguedad_instalaciones_hidraulicas")}
              helperText={errorMessage && errorMessage.hasOwnProperty("antiguedad_instalaciones_hidraulicas") && errorMessage.antiguedad_instalaciones_hidraulicas}
              id="antiguedad_instalaciones_hidraulicas"
              name="antiguedad_instalaciones_hidraulicas"
              label={
                "74. Describe la antigüedad de las instalaciones hidráulicas de tu empresa"
              }
              type="text"
              value={formData.antiguedad_instalaciones_hidraulicas}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuenta_croquis_sistema_hidraulico")}
              // error={false}
              id="cuenta_croquis_sistema_hidraulico"
              name="cuenta_croquis_sistema_hidraulico"
              label={"75. ¿Cuentas con un croquis del sistema de hidráulico?"}
              type="text"
              value={formData.cuenta_croquis_sistema_hidraulico}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("cuenta_croquis_sistema_hidraulico")&&errorMessage.cuenta_croquis_sistema_hidraulico}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.cuenta_croquis_sistema_hidraulico}
                  name="cuenta_croquis_sistema_hidraulico"
                  id="cuenta_croquis_sistema_hidraulico"
                  onChange={handleChange}
                />
              }
              label="¿Cuentas con un croquis del sistema de hidráulico?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("estudio_balance_agua")}
              // error={false}
              id="estudio_balance_agua"
              name="estudio_balance_agua"
              label={"76. ¿Cuentas con algún estudio de balance de agua?"}
              type="text"
              value={formData.estudio_balance_agua}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("estudio_balance_agua")&&errorMessage.estudio_balance_agua}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.estudio_balance_agua}
                  name="estudio_balance_agua"
                  id="estudio_balance_agua"
                  onChange={handleChange}
                />
              }
              label="¿Cuentas con algún estudio de balance de agua?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("usos_dados")}
              helperText={errorMessage && errorMessage.hasOwnProperty("usos_dados") && errorMessage.usos_dados}
              id="usos_dados"
              name="usos_dados"
              label={
                "77. ¿Cuáles son los usos que se le dan al agua en tu empresa?"
              }
              type="text"
              value={formData.usos_dados}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={2}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("abastecimiento_agua_caliente")}
              helperText={errorMessage && errorMessage.hasOwnProperty("abastecimiento_agua_caliente") && errorMessage.abastecimiento_agua_caliente}
              id="abastecimiento_agua_caliente"
              name="abastecimiento_agua_caliente"
              label={"78. ¿En qué áreas o sistemas se abastece agua caliente?"}
              type="text"
              value={formData.abastecimiento_agua_caliente}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("abastecimiento_agua_fria")}
              helperText={errorMessage && errorMessage.hasOwnProperty("abastecimiento_agua_fria") && errorMessage.abastecimiento_agua_fria}
              id="abastecimiento_agua_fria"
              name="abastecimiento_agua_fria"
              label={"79. ¿Y agua fría?"}
              type="text"
              value={formData.abastecimiento_agua_fria}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("instalacion_fuentes_naturales")}
              // error={false}
              id="instalacion_fuentes_naturales"
              name="instalacion_fuentes_naturales"
              label={"80. ¿Existen instalaciones de captación de agua, como extracción de fuentes naturales como pozos?"}
              type="text"
              value={formData.instalacion_fuentes_naturales}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("instalacion_fuentes_naturales")&&errorMessage.instalacion_fuentes_naturales}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.instalacion_fuentes_naturales}
                  name="instalacion_fuentes_naturales"
                  id="instalacion_fuentes_naturales"
                  onChange={handleChange}
                />
              }
              label="¿Existen instalaciones de captación de agua, como extracción de fuentes naturales
              como pozos?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuenta_planta_potabilizadora")}
              // error={false}
              id="cuenta_planta_potabilizadora"
              name="cuenta_planta_potabilizadora"
              label={"81. ¿Su empresa cuenta con planta potabilizadora?"}
              type="text"
              value={formData.cuenta_planta_potabilizadora}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("cuenta_planta_potabilizadora")&&errorMessage.cuenta_planta_potabilizadora}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.cuenta_planta_potabilizadora}
                  name="cuenta_planta_potabilizadora"
                  id="cuenta_planta_potabilizadora"
                  onChange={handleChange}
                />
              }
              label="¿Su empresa cuenta con planta potabilizadora?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuenta_depositos_distribucion")}
              // error={false}
              id="cuenta_depositos_distribucion"
              name="cuenta_depositos_distribucion"
              label={"82. ¿Cuentan con tanques de depósito de agua para su distribución?"}
              type="text"
              value={formData.cuenta_depositos_distribucion}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("cuenta_depositos_distribucion")&&errorMessage.cuenta_depositos_distribucion}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.cuenta_depositos_distribucion}
                  name="cuenta_depositos_distribucion"
                  id="cuenta_depositos_distribucion"
                  onChange={handleChange}
                />
              }
              label="¿Cuentan con tanques de depósito de agua para su distribución?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuantos_capacidad")}
              helperText={errorMessage && errorMessage.hasOwnProperty("cuantos_capacidad") && errorMessage.cuantos_capacidad}
              id="cuantos_capacidad"
              name="cuantos_capacidad"
              label={"83. ¿Cuántos y de qué capacidad?"}
              type="text"
              value={formData.cuantos_capacidad}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("redes_secundarias_tanque")}
              helperText={errorMessage && errorMessage.hasOwnProperty("redes_secundarias_tanque") && errorMessage.redes_secundarias_tanque}
              id="redes_secundarias_tanque"
              name="redes_secundarias_tanque"
              label={
                "84. A partir de la red primaria, ¿cuántas redes secundarias se ramifican en el tanque de distribución?"
              }
              type="text"
              value={formData.redes_secundarias_tanque}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("identifica_hallazgos")}
              // error={false}
              id="identifica_hallazgos"
              name="identifica_hallazgos"
              label={"85. ¿Ha identificado goteos, humedad, pérdidas, baja presión u otros hallazgos de ineficiencia en el sistema?"}
              type="text"
              value={formData.identifica_hallazgos}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("identifica_hallazgos")&&errorMessage.identifica_hallazgos}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.identifica_hallazgos}
                  name="identifica_hallazgos"
                  id="identifica_hallazgos"
                  onChange={handleChange}
                />
              }
              label="¿Ha identificado goteos, humedad, pérdidas, baja presión u otros hallazgos de
              ineficiencia en el sistema?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuantos_capacidad")}
              helperText={errorMessage && errorMessage.hasOwnProperty("cuantos_capacidad") && errorMessage.cuantos_capacidad}
              id="cuantos_capacidad"
              name="cuantos_capacidad"
              label={"86. ¿Cuántos y de qué capacidad?"}
              type="text"
              value={formData.cuantos_capacidad}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("redes_secundarias_tanque")}
              helperText={errorMessage && errorMessage.hasOwnProperty("redes_secundarias_tanque") && errorMessage.redes_secundarias_tanque}
              id="redes_secundarias_tanque"
              name="redes_secundarias_tanque"
              label={
                "87. A partir de la red primaria, ¿cuántas redes secundarias se ramifican en el tanque de distribución?"
              }
              type="text"
              value={formData.redes_secundarias_tanque}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.recepcion_agua_pozos_red_distribucion}
                  name="recepcion_agua_pozos_red_distribucion"
                  id="recepcion_agua_pozos_red_distribucion"
                  onChange={handleChange}
                />
              }
              label="La recepción del agua por medio de la red de distribución, pozos y
              otras fuentes en la entrada principal de la empresa"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.demandas_extraordinarias}
                  name="demandas_extraordinarias"
                  id="demandas_extraordinarias"
                  onChange={handleChange}
                />
              }
              label="Áreas con demandas extraordinarias"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.tanques_almacenamiento}
                  name="tanques_almacenamiento"
                  id="tanques_almacenamiento"
                  onChange={handleChange}
                />
              }
              label="Tanques de almacenamiento (como cisternas, tanques, etc.)"
            /> */}
            <DropDown
              id={"entrada_agua"}
              label={
                "88. ¿El agua de entrada a la empresa es agua potable o agua tratada?"
              }
              value={formData.entrada_agua}
              onChange={handleChange}
              data={tipoAguaEntrada}
              size={"small"}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("usa_agua_tratada")}
              // error={false}
              id="usa_agua_tratada"
              name="usa_agua_tratada"
              label={"89. ¿Alguno de sus procesos usa agua tratada? En caso de que no, ¿podría usarla?"}
              type="text"
              value={formData.usa_agua_tratada}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("usa_agua_tratada")&&errorMessage.usa_agua_tratada}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.usa_agua_tratada}
                  name="usa_agua_tratada"
                  id="usa_agua_tratada"
                  onChange={handleChange}
                />
              }
              label="¿Alguno de sus procesos usa agua tratada? En caso de que no,
              ¿podría usarla?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("necesita_calidad_agua")}
              helperText={errorMessage && errorMessage.hasOwnProperty("necesita_calidad_agua") && errorMessage.necesita_calidad_agua}
              id="necesita_calidad_agua"
              name="necesita_calidad_agua"
              label={
                "90. Necesita una cierta calidad de agua para sus procesos que consumen mayor cantidad de agua? ¿Cuál?"
              }
              type="text"
              value={formData.necesita_calidad_agua}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("sistemas_osmosis_inversa")}
              helperText={errorMessage && errorMessage.hasOwnProperty("sistemas_osmosis_inversa") && errorMessage.sistemas_osmosis_inversa}
              id="sistemas_osmosis_inversa"
              name="sistemas_osmosis_inversa"
              label={
                "91. ¿Cuentan con sistemas de ósmosis inversa, suavizadores, etc.? Descríbelos."
              }
              type="text"
              value={formData.sistemas_osmosis_inversa}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("conectividad_agua_salida")}
              helperText={errorMessage && errorMessage.hasOwnProperty("conectividad_agua_salida") && errorMessage.conectividad_agua_salida}
              id="conectividad_agua_salida"
              name="conectividad_agua_salida"
              label={"92. ¿Qué conductividad tiene el agua de salida?"}
              type="text"
              value={formData.conectividad_agua_salida}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <Typography component={'span'} variant="body1">
              93. ¿Cuentan con la medición de los parámetros físico-químicos del
              agua? Por ejemplo:
              <ol>
                <li>Temperatura del agua</li>
                <li>Contenido de solidos disueltos y totales</li>
                <li>
                  Análisis fisicoquímico del agua para detección de
                  contaminantes de acuerdo con el giro o industria (detergentes,
                  solventes, etc.)
                </li>
              </ol>
            </Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("medicion_parametros")}
              helperText={errorMessage && errorMessage.hasOwnProperty("medicion_parametros") && errorMessage.medicion_parametros}
              id="medicion_parametros"
              name="medicion_parametros"
              label={"94. Descríbelos."}
              type="text"
              value={formData.medicion_parametros}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />

            {/* <DropDown
              id={"edificioId"}
              label={"Edificio"}
              value={formData.edificioId}
              onChange={handleChange}
              data={edificio.map((e) => ({ label: e.nick_name, value: e.id }))}
              size={"small"}
              variant="standard"
              helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
            /> */}
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions>
      <Button variant="outlined" color="info" onClick={handleSubmit}>
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setStepCount(stepCount + 1);
          }}
        >
          Omitir
        </Button>
        <Button variant="outlined" color="success" onClick={()=>{setStepCount(8)}}>
          Finalizar
        </Button>
      </CardActions>
    </>
  );
}

export default AguaForm;
