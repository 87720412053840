import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  // Checkbox,
  FormControl,
  // FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
// import { getEdificios } from "../../../api/Core.api";
import DropDown from "../../../components/DropDown";
import LoadingContext from "../../../context/LoadingContext";
import { insertMaterial } from "../../../api/EconomiaCircular.api";
import { enqueueSnackbar } from "notistack";
import { Add, Delete } from "@mui/icons-material";
import { errorHandler } from "../../../utils/ErrorHandler";

function MaterialesForm({ setStepCount, stepCount, edificioId }) {
  const certificacionIMMEX = [
    { label: "A", value: 0 },
    { label: "AA", value: 1 },
    { label: "AAA", value: 2 },
  ];
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [tiposMateriales, setTiposMateriales] = useState([
    {
      tipo_material: "",
      volumen: "",
      periodicidad: "",
      caracteristicas: "",
    },
  ]);
  const [formData, setFormData] = useState({
    transferencia_virtual_IMMEX: "",
    tipo_certificacion_IMMEX: 0,
    puede_facturar_desechos: "",
    clasifica_materiales: "",
    mezcla_materiales: "",
    que_mezclan: "",
    maquinaria_procesar_desecho: "",
    especifica_maquinaria: "",
    cuenta_espacio_maquinaria: "",
    especifica_espacio: "",
    almacena_materiales_resguardo: "",
    condiciones: "",
    razon_tiempo_almacenamiento: "",
    certificaciones_procesos: "",
    comentarios: "",
    edificioId: edificioId,
    tipos_materiales: [],
  });
  // const [edificio, setEdificio] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  // useEffect(() => {
  //   const loadEdificios = async () => {
  //     try {
  //       const res = await getEdificios("materiales");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificios();
  // }, []);

  useEffect(() => {
    const handleChildChange = () => {
      setFormData({ ...formData, tipos_materiales: tiposMateriales });
    };
    handleChildChange();
  }, [tiposMateriales]);

  const handleAdd = () => {
    const tipos_materiales = [
      ...tiposMateriales,
      {
        tipo_material: "",
        volumen: "",
        periodicidad: "",
        caracteristicas: "",
      },
    ];
    tipos_materiales.length > 1 && setDisableBtn(true);
    setTiposMateriales(tipos_materiales);
  };

  const handleChangeTiposMateriales = (e, i) => {
    const inputData = [...tiposMateriales];
    inputData[i][e.target.name] = e.target.value;
    setTiposMateriales(inputData);
  };

  const handleDeleteTiposMateriales = (i) => {
    const deleteTipoMaterial = [...tiposMateriales];
    deleteTipoMaterial.length > 1 && deleteTipoMaterial.splice(i, 1);
    deleteTipoMaterial.length < 2 && setDisableBtn(false);
    setTiposMateriales(deleteTipoMaterial);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };
  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertMaterial(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      console.log(error);
      setErrorMessage(error.response?.data);
      errorHandler(error);
    }
  };
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <Typography variant="h5">MATERIALES</Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("transferencia_virtual_IMMEX")}
              // error={false}
              id="transferencia_virtual_IMMEX"
              name="transferencia_virtual_IMMEX"
              label={"47. ¿Se realizan transferencias virtuales a través del programa IMMEX?"}
              type="text"
              value={formData.transferencia_virtual_IMMEX}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("transferencia_virtual_IMMEX")&&errorMessage.transferencia_virtual_IMMEX}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.transferencia_virtual_IMMEX}
                  name="transferencia_virtual_IMMEX"
                  id="transferencia_virtual_IMMEX"
                  onChange={handleChange}
                />
              }
              label="¿Se realizan transferencias virtuales a través del programa IMMEX?"
            /> */}
            <DropDown
              id={"tipo_certificacion_IMMEX"}
              label={"48. ¿Con qué tipo de certificación IMMEX cuenta la empresa? "}
              value={formData.tipo_certificacion_IMMEX}
              onChange={handleChange}
              data={certificacionIMMEX}
              size={"small"}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("puede_facturar_desechos")}
              // error={false}
              id="puede_facturar_desechos"
              name="puede_facturar_desechos"
              label={"49. ¿Puede facturar en México sus desechos?"}
              type="text"
              value={formData.puede_facturar_desechos}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("puede_facturar_desechos")&&errorMessage.puede_facturar_desechos}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.puede_facturar_desechos}
                  name="puede_facturar_desechos"
                  id="puede_facturar_desechos"
                  onChange={handleChange}
                />
              }
              label="¿Puede facturar en México sus desechos?"
            /> */}
            <Typography variant="subtitle1" fontWeight="bold">
              50. Materiales:
            </Typography>
            {tiposMateriales.map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "25px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <TextField
                    name="tipo_material"
                    label="Tipo de material"
                    type="text"
                    size="small"
                    value={data.tipo_material}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    onChange={(e) => {
                      handleChangeTiposMateriales(e, i);
                    }}
                  />
                  <TextField
                    name="volumen"
                    label="Volumen"
                    type="text"
                    size="small"
                    value={data.volumen}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    onChange={(e) => {
                      handleChangeTiposMateriales(e, i);
                    }}
                  />
                  <TextField
                    name="periodicidad"
                    label="Periodicidad"
                    type="text"
                    size="small"
                    value={data.periodicidad}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    onChange={(e) => {
                      handleChangeTiposMateriales(e, i);
                    }}
                  />
                  <TextField
                    name="caracteristicas"
                    label="Caracteristicas"
                    type="text"
                    size="small"
                    value={data.caracteristicas}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    onChange={(e) => {
                      handleChangeTiposMateriales(e, i);
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ flexGrow: 1 }}
                    onClick={(e) => {
                      handleDeleteTiposMateriales(i);
                    }}
                    disabled={!disableBtn}
                  >
                    <Delete />
                  </Button>
                </Box>
              );
            })}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                color="info"
                onClick={() => handleAdd()}
                sx={{ marginBottom: 5 }}
              >
                <Add />
                <Typography>Agregar tipo de material</Typography>
              </Button>
            </Box>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("clasifica_materiales")}
              // error={false}
              id="clasifica_materiales"
              name="clasifica_materiales"
              label={"51. ¿Clasificas tus materiales por composición físico-química?"}
              type="text"
              value={formData.clasifica_materiales}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("clasifica_materiales")&&errorMessage.clasifica_materiales}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.clasifica_materiales}
                  name="clasifica_materiales"
                  id="clasifica_materiales"
                  onChange={handleChange}
                />
              }
              label="¿Clasificas tus materiales por composición físico-química?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("mezcla_materiales")}
              // error={false}
              id="mezcla_materiales"
              name="mezcla_materiales"
              label={"52. En caso de que la respuesta sea NO, ¿se mezclan los materiales?"}
              type="text"
              value={formData.mezcla_materiales}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("mezcla_materiales")&&errorMessage.mezcla_materiales}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.mezcla_materiales}
                  name="mezcla_materiales"
                  id="mezcla_materiales"
                  onChange={handleChange}
                />
              }
              label="En caso de que la respuesta sea NO, ¿se mezclan los materiales?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("que_mezclan")}
              helperText={errorMessage && errorMessage.hasOwnProperty("que_mezclan") && errorMessage.que_mezclan}
              name="que_mezclan"
              label={"53. Indica con qué se mezclan:"}
              type="text"
              value={formData.que_mezclan}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("maquinaria_procesar_desecho")}
              // error={false}
              id="maquinaria_procesar_desecho"
              name="maquinaria_procesar_desecho"
              label={"54. ¿Cuenta con maquinaria para procesar el material de desecho?"}
              type="text"
              value={formData.maquinaria_procesar_desecho}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("maquinaria_procesar_desecho")&&errorMessage.maquinaria_procesar_desecho}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.maquinaria_procesar_desecho}
                  name="maquinaria_procesar_desecho"
                  id="maquinaria_procesar_desecho"
                  onChange={handleChange}
                />
              }
              label="¿Cuenta con maquinaria para procesar el material de desecho?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("especifica_maquinaria")}
              helperText={errorMessage && errorMessage.hasOwnProperty("especifica_maquinaria") && errorMessage.especifica_maquinaria}
              name="especifica_maquinaria"
              label={"55. Especifica:"}
              type="text"
              value={formData.especifica_maquinaria}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cuenta_espacio_maquinaria")}
              // error={false}
              id="cuenta_espacio_maquinaria"
              name="cuenta_espacio_maquinaria"
              label={"56. En caso de que la respuesta sea NO, ¿cuenta con espacio para poner maquinaria?"}
              type="text"
              value={formData.cuenta_espacio_maquinaria}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("cuenta_espacio_maquinaria")&&errorMessage.cuenta_espacio_maquinaria}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.cuenta_espacio_maquinaria}
                  name="cuenta_espacio_maquinaria"
                  id="cuenta_espacio_maquinaria"
                  onChange={handleChange}
                />
              }
              label="En caso de que la respuesta sea NO, ¿cuenta con espacio para poner maquinaria?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("especifica_espacio")}
              helperText={errorMessage && errorMessage.hasOwnProperty("especifica_espacio") && errorMessage.especifica_espacio}
              name="especifica_espacio"
              label={"57. En caso de que su respuesta sea SI, especificar espacio:"}
              type="text"
              value={formData.especifica_espacio}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("almacena_materiales_resguardo")}
              // error={false}
              id="almacena_materiales_resguardo"
              name="almacena_materiales_resguardo"
              label={"58. ¿Almacena sus materiales en un lugar resguardado?"}
              type="text"
              value={formData.almacena_materiales_resguardo}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("almacena_materiales_resguardo")&&errorMessage.almacena_materiales_resguardo}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.almacena_materiales_resguardo}
                  name="almacena_materiales_resguardo"
                  id="almacena_materiales_resguardo"
                  onChange={handleChange}
                />
              }
              label="¿Almacena sus materiales en un lugar resguardado?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("condiciones")}
              helperText={errorMessage && errorMessage.hasOwnProperty("condiciones") && errorMessage.condiciones}
              name="condiciones"
              label={"59. Si su respuesta es NO, ¿en qué condiciones están?"}
              type="text"
              value={formData.condiciones}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("razon_tiempo_almacenamiento")}
              helperText={errorMessage && errorMessage.hasOwnProperty("razon_tiempo_almacenamiento") && errorMessage.razon_tiempo_almacenamiento}
              name="razon_tiempo_almacenamiento"
              label={"60. Especificar razón y tiempo de almacenamiento:"}
              type="text"
              value={formData.razon_tiempo_almacenamiento}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("certificaciones_procesos")}
              helperText={errorMessage && errorMessage.hasOwnProperty("certificaciones_procesos") && errorMessage.certificaciones_procesos}
              name="certificaciones_procesos"
              label={
                "61. ¿Cuenta con algún tipo de certificaciones en sus procesos? ¿Cuáles?"
              }
              type="text"
              value={formData.certificaciones_procesos}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("comentarios")}
              helperText={errorMessage && errorMessage.hasOwnProperty("comentarios") && errorMessage.comentarios}
              name="comentarios"
              label={"62. Comentarios"}
              type="text"
              value={formData.comentarios}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={4}
            />
            {/* <DropDown
              id={"edificioId"}
              label={"Edificio"}
              value={formData.edificioId}
              onChange={handleChange}
              data={edificio.map((e) => ({ label: e.nick_name, value: e.id }))}
              size={"small"}
              variant="standard"
              helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
            /> */}
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions>
      <Button variant="outlined" color="info" onClick={handleSubmit}>
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setStepCount(stepCount + 1);
          }}
        >
          Omitir
        </Button>
        <Button variant="outlined" color="success" onClick={()=>{setStepCount(8)}}>
          Finalizar
        </Button>
      </CardActions>
    </>
  );
}

export default MaterialesForm;
