import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
// import GuiaFlujo from "../../../assets/guia_flujo.png";

export default function SistemaInternoCard({ formData, handleDelete }) {
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            {/* <Typography variant="h5">Sistema Interno</Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={GuiaFlujo}
                alt=""
                style={{ maxWidth: "700px", width: "fit-content" }}
              />
            </Box> */}
            <Typography variant="h6">Inputs | Entradas</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              12. Materias primas principales/críticas:
            </Typography>
            {formData.materias_primas?.map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "25px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <TextField
                    name="materia_prima"
                    label="Materia prima"
                    type="text"
                    size="small"
                    value={data.materia_prima}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="cantidad"
                    label="Cantidad"
                    type="text"
                    size="small"
                    value={data.cantidad}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="costo"
                    label="Costo"
                    type="text"
                    size="small"
                    value={data.costo}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="origen"
                    label="Origen"
                    type="text"
                    size="small"
                    value={data.origen}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                </Box>
              );
            })}
            <TextField
              id="materias_locales"
              name="materias_locales"
              label={"13. ¿Las materias primas podrían ser locales?"}
              type="text"
              value={formData.materias_locales}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="material_escaso"
              name="material_escaso"
              label={
                "14. ¿Alguna de las materias primas está considerado material escaso?"
              }
              type="text"
              value={formData.material_escaso}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="son_renovables"
              name="son_renovables"
              label={"15. ¿Las materias primas podrían ser renovables?"}
              type="text"
              value={formData.son_renovables}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="son_recicladas"
              name="son_recicladas"
              label={"16. ¿Las materias primas podrían ser recicladas?"}
              type="text"
              value={formData.son_recicladas}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="compradas_bajo_estrategia"
              name="compradas_bajo_estrategia"
              label={
                "17. ¿Se realizan compras bajo alguna estrategia de sostenibilidad?"
              }
              type="text"
              value={formData.compradas_bajo_estrategia}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Consumo de Agua*:
            </Typography>
            <TextField
              id="nivel_consumo_agua"
              name="nivel_consumo_agua"
              label={"18. ¿Qué nivel de consumo de agua tienen?"}
              type="text"
              value={formData.nivel_consumo_agua}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="caracteristicas_entrada_agua"
              name="caracteristicas_entrada_agua"
              label={"19. ¿Qué características debe tener el agua de entrada?"}
              type="text"
              value={formData.caracteristicas_entrada_agua}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="utiliza_agua_regenerada"
              name="utiliza_agua_regenerada"
              label={"20. ¿Podrían utilizar agua regenerada?"}
              type="text"
              value={formData.utiliza_agua_regenerada}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Energía Eléctrica*:
            </Typography>
            <TextField
              id="medidores_electricos"
              name="medidores_electricos"
              label={"21. Con cuántos servicios (medidores) se cuenta:"}
              type="text"
              value={formData.medidores_electricos}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="capacidad_subestacion_servicio"
              name="capacidad_subestacion_servicio"
              label={"22. Capacidad de subestación por servicio [kVAs]:"}
              type="text"
              value={formData.capacidad_subestacion_servicio}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="iniciativa_energia_renovable"
              name="iniciativa_energia_renovable"
              label={
                "23. ¿Existe interés o alguna iniciativa para el uso de energía renovable? ¿Cuál?"
              }
              type="text"
              value={formData.iniciativa_energia_renovable}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="monitoreo_consumo"
              name="monitoreo_consumo"
              label={
                "24. ¿Se cuenta actualmente con monitoreo en tiempo real de consumo?"
              }
              type="text"
              value={formData.monitoreo_consumo}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="codigo_RED"
              name="codigo_RED"
              label={"25. ¿Cuentan con Código de RED en orden?"}
              type="text"
              value={formData.codigo_RED}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <TextField
              id="movilidad_electrica"
              name="movilidad_electrica"
              label={"26. ¿Existe interés en la movilidad eléctrica?"}
              type="text"
              value={formData.movilidad_electrica}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Energía Térmica*:
            </Typography>
            <TextField
              id="fuente_energia_termica"
              name="fuente_energia_termica"
              label={"27. Fuente de energía"}
              type="text"
              value={formData.fuente_energia_termica}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="consumo_energia_termica"
              name="consumo_energia_termica"
              label={"28. Consumo"}
              type="text"
              value={formData.consumo_energia_termica}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="programa_eficiencia_termica"
              name="programa_eficiencia_termica"
              label={"29. ¿Hay algún programa de eficiencia? ¿Cuál?"}
              type="text"
              value={formData.programa_eficiencia_termica}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={2}
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Servicios:
            </Typography>
            <TextField
              id="servicios_externos_especializados"
              name="servicios_externos_especializados"
              label={
                "30. ¿Con qué servicios especializados externos dispone la empresa? (p.ej. tóner, impresoras, iluminación, transporte de empleados, gestión de la depuradora, etc.)"
              }
              type="text"
              value={formData.servicios_externos_especializados}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={4}
              disabled
            />
            <Typography variant="h6">Outputs | Salidas</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              31. Residuos:
            </Typography>
            {formData.residuos?.map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "25px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <TextField
                    name="tipo_residuo"
                    label="Tipo de residuo"
                    type="text"
                    size="small"
                    value={data.tipo_residuo}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="cantidad"
                    label="Cantidad"
                    type="text"
                    size="small"
                    value={data.cantidad}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="gestor"
                    label="Gestor"
                    type="text"
                    size="small"
                    value={data.gestor}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="coste"
                    label="Coste"
                    type="text"
                    size="small"
                    value={data.coste}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                </Box>
              );
            })}
            <TextField
              id="uso_residuos"
              name="uso_residuos"
              label={
                "32. ¿Se conoce el destino o uso que el gestor le da a los residuos? ¿Cuál es?"
              }
              type="text"
              value={formData.uso_residuos}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={4}
              disabled
            />
            <TextField
              id="iniciativas_distintas_gestion_actual"
              name="iniciativas_distintas_gestion_actual"
              label={
                "33. ¿Han pensado alguna vez iniciativas distintas a la gestión actual de estos residuos?"
              }
              type="text"
              value={formData.iniciativas_distintas_gestion_actual}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={4}
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              34. Agua residual:
            </Typography>
            {formData.aguas_residuales.map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "25px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <TextField
                    name="origen"
                    label="Origen"
                    type="text"
                    size="small"
                    value={data.origen}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="cantidad"
                    label="Cantidad"
                    type="text"
                    size="small"
                    value={data.cantidad}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="calidad"
                    label="Calidad"
                    type="text"
                    size="small"
                    value={data.calidad}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                  <TextField
                    name="coste"
                    label="Coste"
                    type="text"
                    size="small"
                    value={data.coste}
                    variant="standard"
                    sx={{ flexGrow: 10 }}
                    disabled
                  />
                </Box>
              );
            })}
            <Typography variant="subtitle1" fontWeight="bold">
              Calor residual:
            </Typography>
            <TextField
              id="existe_energia_termica_residual"
              name="existe_energia_termica_residual"
              label={"35. ¿Existen flujos de energía térmica residual?"}
              type="text"
              value={formData.existe_energia_termica_residual}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="donde_genera"
              name="donde_genera"
              label={"¿Dónde se genera esta energía?"}
              type="text"
              value={formData.donde_genera}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="cual_es_el_vector"
              name="cual_es_el_vector"
              label={"36. ¿Cuál es el vector (agua/gas/radiación...)?"}
              type="text"
              value={formData.cual_es_el_vector}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="realizo_estudio_eficiencia"
              name="realizo_estudio_eficiencia"
              label={
                "37. ¿Se ha realizado algún estudio de eficiencia energética?"
              }
              type="text"
              value={formData.realizo_estudio_eficiencia}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Espacio sobrante:
            </Typography>
            <TextField
              id="exedente_espacio"
              name="exedente_espacio"
              label={"38. ¿Existe algún excedente de espacio en la empresa?"}
              type="text"
              value={formData.exedente_espacio}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="tejados_desuso"
              name="tejados_desuso"
              label={"39. ¿Se dispone de cubiertas o tejados en desuso?"}
              type="text"
              value={formData.tejados_desuso}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              Logística:
            </Typography>
            <TextField
              id="exedente_logistico"
              name="exedente_logistico"
              label={"40. ¿Existe algún excedente logístico? ¿Cuál?"}
              type="text"
              value={formData.exedente_logistico}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="proceso_logistica_inversa"
              name="proceso_logistica_inversa"
              label={
                "41. ¿Se ha estudiado o se realiza algún proceso de logística inversa? ¿Cuál?"
              }
              type="text"
              value={formData.proceso_logistica_inversa}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <Typography variant="h6">Proceso</Typography>
            <TextField
              id="recursos_limitantes_in_out"
              name="recursos_limitantes_in_out"
              label={
                "42. De los recursos de entrada y salida ¿cuáles consideras que son los recursos limitantes?"
              }
              type="text"
              value={formData.recursos_limitantes_in_out}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="punto_critico"
              name="punto_critico"
              label={
                "43. ¿Dónde está el punto crítico? P.ej. volatilidad del precio de la energía eléctrica, el transporte del residuo..."
              }
              type="text"
              value={formData.punto_critico}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="mejorables"
              name="mejorables"
              label={"44. ¿Cuáles podrían ser mejorables?"}
              type="text"
              value={formData.mejorables}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <TextField
              id="minimizacion_recursos"
              name="minimizacion_recursos"
              label={
                "45. ¿El proceso productivo y el producto han sido ideados teniendo en cuenta la minimización en el uso de los recursos?"
              }
              type="text"
              value={formData.minimizacion_recursos}
              size={"small"}
              variant="standard"
              fullWidth
              disabled
            />
            <Typography variant="h6">46. Otros comentarios</Typography>
            <TextField
              id="comentarios"
              name="comentarios"
              label={""}
              type="text"
              value={formData.comentarios}
              size={"small"}
              variant="standard"
              fullWidth
              multiline
              minRows={4}
              disabled
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="sistema-interno"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
