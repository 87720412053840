import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../../utils/ErrorHandler";
import LoadingContext from "../../context/LoadingContext";
import { getGreenDashboard } from "../../api/Emissions.api";
import { green, grey } from "@mui/material/colors";
import { BarChart, LineChart, PieChart, useDrawingArea } from "@mui/x-charts";
import ResumenEmisiones from "./components/ResumenEmisiones";
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, RadarChart, ResponsiveContainer } from "recharts";
import { Radar } from "@mui/icons-material";
import AreasMejora from "./components/AreasMejora";

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 18,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2}
      sx={{ overflowWrap: "break-word" }}
    >
      {children}
    </StyledText>
  );
}

export default function DashboardAdministrativo() {
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const [empresas, setEmpresas] = useState(0);
  const [plantas, setPlantas] = useState(0);
  const [emissions, setEmissions] = useState([]);
  const [emissionSummary, setEmissionSummary] = useState(null);
  const [yearsArray, setYearsArray] = useState(null);
  const [scope, setScope] = useState(null);
  const [currentEmissions, setCurrentEmissions] = useState(null);
  const [radarData, setRadarData] = useState(null);
  const [radarKeys, setRadarKeys] = useState([]);
  const [arcData, setArcData] = useState(null);

  const fetchDashboard = async () => {
    try {
      const res = await getGreenDashboard();
      const data = res.data;
      setEmpresas(data.empresas);
      setPlantas(data.plantas);
      setEmissions({
        stationary: data.stationary.map((e) => ({
          year: e.yearf,
          scope: "S1 Comb. Estacionaria",
          co2: Number(e.total_co2) || 0,
          biofuel: Number(e.total_biofuel) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n2o) || 0,
        })),
        mobile: data.mobile.map((e) => ({
          year: e.yearf,
          scope: "S1 Comb. Movil",
          co2: Number(e.total_co2) || 0,
          biofuel: Number(e.total_biofuel) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n20) || 0,
        })),
        refrigerant: data.refrigerant.map((e) => ({
          year: e.yearf,
          scope: "S1 Refrigerante",
          co2: Number(e.total_co2) || 0,
          ch: 0,
          biofuel: 0,
          n2o: 0,
        })),
        purchaseElectricity: data.purchaseElectricity.map((e) => ({
          year: e.yearf,
          scope: "S2 Electricidad",
          co2: Number(e.total_co2) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n2o) || 0,
        })),
        transportation: data.transportation.map((e) => ({
          year: e.yearf,
          scope: "S3 Transporte",
          co2: Number(e.total_co2) || 0,
          biofuel: Number(e.total_biofuel) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n2o) || 0,
        })),
      });
    } catch (error) {
      throw error;
    }
  };

  const groupCurrentScope = (data = {}) => {
    let scope = { s1: 0, s2: 0 };
    const emissions = [];
    const summary = [];
    const years = [];
    Object.keys(data).forEach((key) => {
      data[key].forEach((el) => {
        summary.push(el);
        years.push(el.year);
      });
    });
    const year = Math.max.apply(Math, years);
    data.mobile
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s1 += e.co2;
        emissions.push(e);
      });
    data.stationary
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s1 += e.co2;
        emissions.push(e);
      });
    data.refrigerant
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s1 += e.co2;
        emissions.push(e);
      });
    data.purchaseElectricity
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s2 += e.co2;
        emissions.push(e);
      });
    setYearsArray([...new Set(years)]);
    setCurrentEmissions(emissions);
    setEmissionSummary(summary);
    setScope([scope]);
  };

  useEffect(() => {
    const loadData = async () => {
      startLoading("Cargando datos del Dashboard");
      try {
        await fetchDashboard();
        stopLoading();
      } catch (error) {
        stopLoading();
        errorHandler(error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (emissions) groupCurrentScope(emissions);
    if (emissions) getRadarData(emissions);
  }, [emissions]);

  const getRadarData = (data) => {
    if (!data || data.length < 1) return;
    const years = [];
    const radar = [
      { scope: "Combustion estacionaria", fullMark: 150 },
      { scope: "Refrigerante", fullMark: 150 },
      { scope: "Combustion movil", fullMark: 150 },
      { scope: "Electricidad", fullMark: 150 },
    ];
    Object.keys(data).forEach((key) => {
      data[key].forEach((el) => {
        years.push(el.year);
      });
    });
    data.stationary?.forEach((el) => {
      radar[3][el.year] = el.co2;
    });
    data.mobile?.forEach((el) => {
      radar[2][el.year] = el.co2;
    });
    data.refrigerant?.forEach((el) => {
      radar[1][el.year] = el.co2;
    });
    data.purchaseElectricity?.forEach((el) => {
      radar[0][el.year] = el.co2;
    });

    setRadarKeys([...new Set(years)]);
    setRadarData(radar);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} xl={6} sx={{ height: "auto" }}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Empresas
            </Typography>
            <Typography variant="h5" component="div">
              {empresas}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} xl={6}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Plantas
            </Typography>
            <Typography variant="h5" component="div">
              {plantas}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <ResumenEmisiones
              emissions={emissions}
              title="Reumen de emisiones"
            />
          </CardContent>
        </Card>
      </Grid>
      {scope?.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            // borderRadius: "10px",
            // backgroundColor: grey[100],
            mb: 2,
          }}
        >
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color={grey[600]} align="center">Emisiones por Scope</Typography>
              <Grid container spacing={4}>
                {scope && scope.length > 0 && (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 200,
                    }}
                  >
                    <Box>
                      {scope[0].s1 ? (
                        <>
                          <Typography
                            variant="subtitle1"
                            color={grey[600]}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {scope[0].s1.toLocaleString() + " Ton CO2"}
                          </Typography>
                          <PieChart
                            series={[
                              {
                                data: scope.map((e) => ({ value: e.s1 })),
                                innerRadius: 50,
                                outerRadius: 60,
                              },
                            ]}
                            margin={{
                              top: 20,
                              bottom: 20,
                              left: 30,
                              right: 30,
                            }}
                            width={200}
                            height={150}
                            colors={[green[500]]}
                          >
                            <PieCenterLabel>Scope 1</PieCenterLabel>
                          </PieChart>
                        </>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          color={grey[600]}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          No hay datos por mostrar en Scope 1
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )}
                {scope && scope.length > 0 && (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 200,
                    }}
                  >
                    <Box>
                      {scope[0].s2 ? (
                        <>
                          <Typography
                            variant="subtitle1"
                            color={grey[600]}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            {scope[0].s2.toLocaleString() + " Ton CO2"}
                          </Typography>
                          <PieChart
                            series={[
                              {
                                data: scope.map((e) => ({ value: e.s2 })),
                                innerRadius: 50,
                                outerRadius: 60,
                              },
                            ]}
                            margin={{
                              top: 20,
                              bottom: 20,
                              left: 30,
                              right: 30,
                            }}
                            width={200}
                            height={150}
                            colors={[green[500]]}
                          >
                            <PieCenterLabel>Scope 2</PieCenterLabel>
                          </PieChart>
                        </>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          color={grey[600]}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          No hay datos para este scope 2
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {currentEmissions && currentEmissions.length > 0 && (
        <Grid item xs={12} xl={6}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" color={grey[600]}>
                  Emisiones
                </Typography>
                <BarChart
                  slotProps={{
                    legend: {
                      labelStyle: { fontSize: 12 },
                      itemMarkWidth: 10,
                      itemMarkHeight: 10,
                    },
                  }}
                  colors={[green[300], green[500], green[700], green[900]]}
                  margin={{ left: 150, right: 50 }}
                  width={600}
                  height={150}
                  layout="horizontal"
                  dataset={currentEmissions}
                  yAxis={[{ scaleType: "band", dataKey: "scope" }]}
                  xAxis={[{ label: "Ton" }]}
                  series={[
                    { dataKey: "co2", label: "CO2" },
                    { dataKey: "ch4", label: "CH2" },
                    { dataKey: "n2o", label: "N2O" },
                    { dataKey: "biofuel", label: "Biofuel" },
                  ]}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
      {emissionSummary && emissionSummary.length > 0 && (
        <Grid item xs={12} xl={6}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                <Typography variant="subtitle1" color={grey[600]}>
                  Emisiones históricas
                </Typography>
                <LineChart
                  width={600}
                  height={150}
                  margin={{ left: 100, right: 100 }}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: { vertical: "top", horizontal: "middle" },
                      labelStyle: { fontSize: 12 },
                      itemMarkWidth: 10,
                      itemMarkHeight: 10,
                    },
                  }}
                  series={[
                    {
                      data: emissionSummary
                        .filter((m) => m.scope === "S1 Combustion Estacionaria")
                        .map((m) => m.co2),
                      label: "Combustion Estacionaria",
                      showMark: true,
                      stack: "total",
                      area: true,
                    },
                    {
                      data: emissionSummary
                        .filter((m) => m.scope === "S1 Combustion Movil")
                        .map((m) => m.co2),
                      label: "Combustion Movil",
                      showMark: true,
                      stack: "total",
                      area: true,
                    },
                    {
                      data: emissionSummary
                        .filter((m) => m.scope === "S1 Refrigerante")
                        .map((m) => m.co2),
                      label: "Refrigerantes",
                      showMark: true,
                      stack: "total",
                      area: true,
                    },
                    {
                      data: emissionSummary
                        .filter((m) => m.scope === "S2 Electricidad")
                        .map((m) => m.co2),
                      label: "Electricidad",
                      showMark: true,
                      stack: "total",
                      area: true,
                    },
                  ]}
                  colors={[green[300], green[500], green[700], green[900]]}
                  xAxis={[
                    {
                      scaleType: "point",
                      position: "bottom",
                      data: yearsArray,
                    },
                  ]}
                  sx={{ ".MuiLineElement-root": { display: "none" } }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <AreasMejora emissions={emissions} pieTitle={"Porcentaje año actual"} radarTitle={"Areas de mejora"} />
      </Grid>
      {/* {radarData && radarData.length > 0 && (
          <Grid
            item
            xs={6}
            // sx={{
            //   mb: 4,
            // }}
          >
            <Card>
              <CardContent>
              <Typography variant="subtitle1" color={grey[600]} align="center">Areas de mejora</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: 700,
                    height: 400,
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <RadarChart cx="50%" cy="50%" outerRadius='80%' data={radarData} >
                      <PolarGrid />
                      <PolarAngleAxis dataKey="scope" />
                      <PolarRadiusAxis angle={0} domain={['auto', 'auto']} tick={false}/>
                      {radarKeys.map((key, index) => (
                        <Radar
                          name={key}
                          dataKey={key}
                          stroke={green[index * 100 + 300]}
                          fill={green[index * 100 + 300]}
                          fillOpacity={0.6}
                        />
                      ))}
                      <Legend />
                    </RadarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
        {arcData && (
          <Grid
            item
            xs={6}
          >
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PieChart
                    colors={[green[300], green[500], green[700], green[900]]}
                    series={[
                      {
                        data: arcData,
                        innerRadius: 70,
                        outerRadius: 90,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontWeight: "bold",
                      },
                    }}
                    width={750}
                    height={250}
                  >
                    <PieCenterLabel1>Porcentaje</PieCenterLabel1>
                    <PieCenterLabel2>de</PieCenterLabel2>
                    <PieCenterLabel3>emisiones</PieCenterLabel3>
                  </PieChart>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )} */}
    </Grid>
  );
}
