import {
  Box,
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UserRegisterForm from "./components/UserRegisterForm";
import EmpresaRegisterForm from "./components/EmpresaRegisterForm";
import { insertEmpresa } from "../../api/Core.api";
import LoadingContext from "../../context/LoadingContext";
import { enqueueSnackbar } from "notistack";
import { register } from "../../api/Auth";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const steps = ["EMPRESA", "USUARIO"];

export default function Register() {
  const { user } = useContext(AuthContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [empresa, setEmpresa] = useState({
    rfc: null,
    nombre_comercial: null,
    nombre_fiscal: null,
    dom_fiscal: null,
    contacto: null,
    puesto: null,
  });
  const [usuario, setUsuario] = useState({
    username: null,
    first_name: null,
    last_name: null,
    password: null,
    pasword2: null,
    empresa_id: empresa.id,
  });
  const [errorEmpresa, setErrorEmpresa] = useState({});
  const [errorUsuario, setErrorUsuario] = useState({});
  const handleChangeEmpresa = (e) => {
    setEmpresa({ ...empresa, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const goHome = () => {
      if (user) navigate("/");
    };
    goHome();
  }, []);

  const handleSubmitEmpresa = async (e) => {
    e.preventDefault();
    startLoading("Guardando datos");
    try {
      const res = await insertEmpresa(empresa);
      const data = res.data;
      setEmpresa(data);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado existosamente",
        autoHideDuration: 8000,
      });
      setActiveStep(activeStep + 1);
    } catch (error) {
      stopLoading();
      if (error.response?.data) {
        console.log(error.response.data);
        setErrorEmpresa(error.response.data);
        Object.keys(error.response.data).forEach((k) => {
          enqueueSnackbar({
            variant: "error",
            message: `${k} : ${error.response.data[k]}`,
            autoHideDuration: 8000,
          });
        });
        return;
      }
      if (error.request) {
        console.log(error.request.response);
        enqueueSnackbar({
          variant: "error",
          message: error.request.response,
          autoHideDuration: 8000,
        });
        return;
      }
      console.log(error);
      enqueueSnackbar({
        variant: "error",
        message: "Ocurrio un error favor de verificar datos",
        autoHideDuration: 8000,
      });
    }
  };

  const handleChangeUsuario = (e) => {
    if(e.target.name === 'username' && !e.target.value.match(/^([a-zA-Z0-9_\-@\.\+]){1,150}$/)){
      setErrorUsuario({username:"Longitud máxima 150 caracteres alfanuméricos. Letras, dígitos y @/./+/-/_ únicamente."})
    }else{
      if(errorUsuario.hasOwnProperty("username")){
        delete errorUsuario.username
      }
    }
    if(e.target.name === 'password' && !e.target.value.match(/^([a-zA-Z0-9_\-@\.\+]){8,150}$/)){
      setErrorUsuario({password:"Debe contener al menos 8 caracteres y debe ser alfanumerica"})
    }else{
      if(errorUsuario.hasOwnProperty("password")){
        delete errorUsuario.password
      }
    }
    if(e.target.name === 'password2' && e.target.value !== usuario.password){
      console.log(e.target.value,usuario.password)
      setErrorUsuario({password:"",password2:"No coinciden la contraseña y su confirmacion"})
    }else{
      if(errorUsuario.hasOwnProperty("password2")){
        delete errorUsuario.password2
        delete errorUsuario.password
      }
    }
    setUsuario({
      ...usuario,
      ["empresa_id"]: empresa.id,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitUsuario = async (e) => {
    e.preventDefault();
    if(!usuario.username.match(/^([a-zA-Z0-9_\-@\.\+]){1,150}$/)){
      setErrorUsuario({username:"Longitud máxima 150 caracteres alfanuméricos. Letras, dígitos y @/./+/-/_ únicamente."})
      return
    }
    if(usuario.password !== usuario.password2){
      setErrorUsuario({password:"",password2:"No coinciden la contraseña y su confirmacion"})
      return
    }
    if(Object.keys(errorUsuario).length>0){
      return
    }
    startLoading("Guardando datos");
    try {
      const res = await register(usuario);
      const data = res.data;
      setUsuario(data);
      console.log(data);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado existosamente",
        autoHideDuration: 8000,
      });
      setActiveStep(activeStep + 1);
    } catch (error) {
      console.log(error)
      stopLoading();
      if (error.response?.data) {
        console.log(error.response.data);
        setErrorUsuario(error.response.data);
        Object.keys(error.response.data).forEach((k) => {
          enqueueSnackbar({
            variant: "error",
            message: `${k} : ${error.response.data[k]}`,
            autoHideDuration: 8000,
          });
        });
        return;
      }
      if (error.request) {
        console.log(error.request.response);
        enqueueSnackbar({
          variant: "error",
          message: error.request.response,
          autoHideDuration: 8000,
        });
        return;
      }
      console.log(error);
      enqueueSnackbar({
        variant: "error",
        message: "Ocurrio un error favor de verificar datos",
        autoHideDuration: 8000,
      });
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <EmpresaRegisterForm
            formData={empresa}
            handleChange={handleChangeEmpresa}
            handleSubmit={handleSubmitEmpresa}
            error={errorEmpresa}
          />
        );
      case 1:
        return (
          <UserRegisterForm
            formData={usuario}
            handleChange={handleChangeUsuario}
            handleSubmit={handleSubmitUsuario}
            error={errorUsuario}
          />
        );
      default:
        throw new Error("Paso desconocido");
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h1" variant="h5" align="center" my={3}>
          Registro
        </Typography>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography variant="h5" gutterBottom align="center" my={2}>
              Registro exitoso.
            </Typography>
            <Typography variant="body2" align="justified" my={2}>
              La empresa con R.F.C. <strong>{empresa.rfc}</strong> y el usuario{" "}
              <strong>{usuario.username}</strong> han sido registrados de manera
              exitosa. El siguiente paso es hacer login con tus credenciales
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Button href="/">Identificarse</Button>
            </Box>
          </>
        ) : (
          <>{getStepContent(activeStep)}</>
        )}
      </Paper>
    </Container>
  );
}
