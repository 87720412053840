import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import Logo from "../../assets/logocuugreen.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
// import { LineAxis } from "@mui/icons-material";

function Title() {
  return (
    <Box sx={{ display: "flex", gap: 5, justifyContent: "center" }}>
      {/* <Avatar src={Logo} /> */}
      <Typography fontSize={18}>BIenvenido a Calculadora CO2</Typography>
    </Box>
  );
}

function Login() {
  const { loginUser, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ username: "", password: "" });

  useEffect(() => {
    const goHome = () => {
      if (user) navigate("/");
    };
    goHome();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap:"wrap",
          flexDirection:"row",
          height: "88vh",
        }}
      >
        <Card sx={{ maxWidth: 600, width: "100%", textAlign: "center" }}>
          <img
            src={Logo}
            style={{ maxWidth: "300px" }}
            alt="Chihuahua green logo"
          />
          <form onSubmit={loginUser}>
            <Title />
            {/* <CardHeader
            title={<Title />}
            sx={{
              background: "#333",
              padding: 4,
              backgroundColor: "#fff",
            }}
          /> */}
            <CardContent sx={{ textAlign: "center" }}>
              <FormControl fullWidth>
                <TextField
                  label="Nombre de usuario"
                  type="text"
                  name="username"
                  value={formData.username}
                  sx={{ m: 2 }}
                  size="small"
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Contraseña"
                  type="password"
                  name="password"
                  value={formData.password}
                  sx={{ m: 2 }}
                  size="small"
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </CardContent>
            <CardActions>
              <Button
                type="submit"
                // size="small"
                color="primary"
                variant="contained"
                sx={{ marginLeft: "auto", marginRight: "auto" }}
                fullWidth
              >
                Identificarse
              </Button>
            </CardActions>
          </form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
              gap:4,
            }}
          >
            <Link href="/register">Registrar nuevo usuario</Link>
            <Link href="/accounts/password_reset/">Olvido su contraseña?</Link>
          </Box>
        </Card>
        <Footer/>
      </Box>
    </>
  );
}

export default Login;
