import { SaveAlt } from "@mui/icons-material";
import { Box, Button, FormControl, TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function EmpresaForm({ formData, handleChange, saveAction, error }) {
  return (
    <>
      <Box sx={{ my: "10px" }}>
        <FormControl
          fullWidth
          sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
        >
          <TextField
            id="nombre_comercial"
            name="nombre_comercial"
            value={formData.nombre_comercial}
            label="Nombre Comercial"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("nombre_comercial")}
            helperText={
              error.hasOwnProperty("nombre_comercial") && error.nombre_comercial
            }
          />
          <TextField
            id="nombre_fiscal"
            name="nombre_fiscal"
            value={formData.nombre_fiscal}
            label="Nombre Fiscal"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("nombre_fiscal")}
            helperText={
              error.hasOwnProperty("nombre_fiscal") && error.nombre_fiscal
            }
          />
          <TextField
            id="rfc"
            name="rfc"
            value={formData.rfc}
            label="RFC"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("rfc")}
            helperText={error.hasOwnProperty("rfc") && error.rfc}
          />
          <TextField
            id="dom_fiscal"
            name="dom_fiscal"
            value={formData.dom_fiscal}
            label="Domicilio Fiscal"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("dom_fiscal")}
            helperText={error.hasOwnProperty("dom_fiscal") && error.dom_fiscal}
          />
          <TextField
            id="contacto"
            name="contacto"
            value={formData.contacto}
            label="Contacto"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("contacto")}
            helperText={error.hasOwnProperty("contacto") && error.contacto}
          />
          <TextField
            id="puesto"
            name="puesto"
            value={formData.puesto}
            label="Puesto"
            onChange={handleChange}
            size="small"
            error={error.hasOwnProperty("puesto")}
            helperText={error.hasOwnProperty("puesto") && error.puesto}
          />
          <Button
            variant="outlined"
            sx={{ mx: 1 }}
            startIcon={<SaveAlt />}
            onClick={saveAction}
            size="small"
          >
            Guardar
          </Button>
        </FormControl>
      </Box>
    </>
  );
}

EmpresaForm.defaultProps = {
  error: {},
};

EmpresaForm.prototype = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default EmpresaForm;
