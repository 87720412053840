import { Route, Routes } from "react-router-dom";
// import Container from "./content/Container";

import Home from "./content/Home";
import Stationary from "./content/emissions/stationary/Stationary";
import Mobile from "./content/emissions/mobile_combustion/Mobile";
import { AuthProvider } from "./context/AuthContext";
import Login from "./content/Auth/Login";
import EmissionsView from "./content/polygons/EmissionsView";
import PrivateRoute from "./utils/PrivateRoute";
import { SnackbarProvider } from "notistack";
import * as locales from "@mui/material/locale";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { useMemo } from "react";
import { LoadingProvider } from "./context/LoadingContext";
import LoadingBackdrop from "./components/LoadingBackdrop";
import EmissionFactors from "./content/core/emissionFactors/EmissionFactors";
import Refrigerant from "./content/emissions/refrigerant/Refrigerant";
import Dashboard from "./content/Dashboard";
import PurchasedElectricity from "./content/emissions/purchased_electricity/PurchasedElectricity";
import Transportation from "./content/emissions/transportation/Transportation";
import Empresa from "./content/core/empresa/Empresa";
import Edificio from "./content/core/edificio/Edificio";
import Profile from "./content/core/Profile";
import Entregables from "./content/entregables/Entregables";
import Formularios from "./content/economia_cicular/Formularios";
import Disclaimer from "./content/economia_cicular/Disclaimer";
import Register from "./content/Auth/Register";
import Logout from "./content/Auth/Logout";
import EntregablesPDF from "./content/entregables/EntregablesPDF";
import DashboardAdministrativo from "./content/entregables/DashboardAdministrativo";
import DashboardEC from "./content/economia_cicular/DashboardEC";

function App() {
  const menuData = {
    Emissions: [
      {
        route: "/emissions/stationary",
        name: "Stationary Combustion",
      },
    ],
    Core: [],
  };

  const theme = useTheme();
  theme.typography = { fontFamily:'Poppins'}
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales["esES"]),
    [theme]
  );

  return (
    <>
      <ThemeProvider theme={themeWithLocale}>
        <SnackbarProvider maxSnack={13}>
          <LoadingProvider>
            <AuthProvider>
              <LoadingBackdrop />
              {/* <Container menu={menuData}> */}
              <Dashboard menu={menuData}>
                <Routes>
                  <Route path="/download/:inc/:facility" element={<EntregablesPDF />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/" element={<PrivateRoute />}>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/emission/maps" element={<EmissionsView />} />
                    <Route exact path="/core/empresa" element={<Empresa />} />
                    <Route exact path="/core/edificio" element={<Edificio />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/entregables" element={<Entregables />} />
                    <Route exact path="/dashboard-green" element={<DashboardAdministrativo/>} />
                    <Route exact path="/formulario" element={<Disclaimer />} />
                    <Route exact path="/formulario/add" element={<Formularios />} />
                    <Route exact path="/dashboard/energia-circular" element={<DashboardEC />} />
                    <Route
                      path="/emissions/stationary"
                      element={<Stationary />}
                    />
                    <Route path="/emissions/mobile" element={<Mobile />} />
                    <Route
                      path="/emissions/refrigerant"
                      element={<Refrigerant />}
                    />
                    <Route
                      path="/emissions/purchased-electrocity"
                      element={<PurchasedElectricity />}
                    />
                    <Route
                      path="/emissions/transport"
                      element={<Transportation />}
                    />
                    <Route
                      path="/core/emission-factors"
                      element={<EmissionFactors />}
                    />
                    <Route
                      path="/polygons/render"
                      element={<EmissionsView />}
                    />
                  </Route>
                </Routes>
              </Dashboard>
              {/* </Container> */}
            </AuthProvider>
          </LoadingProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
