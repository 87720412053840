import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Typography,Button, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

function ModalFullScreen({ title, children, openModal, setOpenModal, handleCloseModal }) {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('lg');
  return (
    <Dialog open={openModal} fullWidth={fullWidth} maxWidth={maxWidth}>
      <DialogTitle>
        <Box style={{display:'flex'}}>
        <Typography variant="h4" style={{flexGrow:1}}>
          {title}
        </Typography>
        <Button variant="text" onClick={()=>{setOpenModal(false)}}><Close /></Button>
        </Box>
      </DialogTitle>
      <DialogContent sx={{overflow:'auto'}}>{children}</DialogContent>
    </Dialog>
  );
}

ModalFullScreen.defaultProps = {
  open:false,
}
ModalFullScreen.prototype = {
  title: PropTypes.string.isRequired,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired
}
export default ModalFullScreen;