import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import PropTypes from "prop-types";

function CustomToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector/>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomDataGrid({ rows, columns, customToolBarComp, onRowDoubleClick, sortModel}) {
  return (
    <DataGrid
    initialState={{
      pagination:{paginationModel:{pageSize:25}},
      sorting:{sortModel},
      columns:{
        columnVisibilityModel:{source:false,id:false}
      }
    }}
      sx={{
        m: 2,
        fontSize: 12,
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
      columns={columns}
      rows={rows}
      slots={{ toolbar: customToolBarComp }}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }
      getRowHeight={() => "auto"}
      disableColumnMenu
      autoHeight={true}
      onRowDoubleClick={onRowDoubleClick}
    />
  );
}
CustomDataGrid.defaultProps = {
  customToolBarComp:CustomToolBar,
  onRowDoubleClick:()=>{},
  sortModel: [],
}
CustomDataGrid.prototype = {
  customToolBarComp: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  sortModel: PropTypes.array,
}

export default CustomDataGrid;
