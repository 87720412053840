import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

export default function DisipacionTermicaCard({ formData, handleDelete }) {
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" fontWeight="bold">
              I. Vapor
            </Typography>
            <Typography variant="body1">
              En caso de contar con procesos que utilicen calderas de vapor,
              conteste lo siguiente:
            </Typography>
            <TextField
              id="caballos_caldera"
              name="caballos_caldera"
              label={"107. Caballos caldera"}
              type="text"
              value={formData.caballos_caldera}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="combustible"
              name="combustible"
              label={"108. Combustible"}
              type="text"
              value={formData.combustible}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="costo_combustible"
              name="costo_combustible"
              label={"109. Costo de combustible"}
              type="text"
              value={formData.costo_combustible}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="ppm_alimentacion_caldera"
              name="ppm_alimentacion_caldera"
              label={"110. PPM de Agua de Alimentación de la Caldera"}
              type="text"
              value={formData.ppm_alimentacion_caldera}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="ppm_purga_superficie"
              name="ppm_purga_superficie"
              label={"111. PPM de Agua de la Caldera (Purga de Superficie)"}
              type="text"
              value={formData.ppm_purga_superficie}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="desc_proceso_vapor"
              name="desc_proceso_vapor"
              label={"112. Breve descripción del proceso"}
              type="text"
              value={formData.desc_proceso_vapor}
              size={"small"}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              II. Agua Torre – Chiller - Geotermia
            </Typography>
            <Typography variant="body1">
              Responda lo siguiente, si cuenta con sistema(s) de refrigeración:
            </Typography>
            <TextField
              id="capacidad_toneladas_refrigeracion"
              name="capacidad_toneladas_refrigeracion"
              label={"113. Capacidad instalada en toneladas de refrigeración"}
              type="text"
              value={formData.capacidad_toneladas_refrigeracion}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="temp_agua_retorno"
              name="temp_agua_retorno"
              label={"114. Temperatura de agua de retorno"}
              type="text"
              value={formData.temp_agua_retorno}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="temp_agua_suministro"
              name="temp_agua_suministro"
              label={"115. Temperatura de agua de suministro"}
              type="text"
              value={formData.temp_agua_suministro}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="desc_proceso_chiller"
              name="desc_proceso_chiller"
              label={"116. Breve descripción del proceso"}
              type="text"
              value={formData.desc_proceso_chiller}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <Typography variant="subtitle1" fontWeight="bold">
              III. Cogeneración
            </Typography>
            <Typography variant="body1">
              En caso de contar con sistema de cogeneración, conteste lo
              siguiente:
            </Typography>
            <TextField
              id="capacidad_generada"
              name="capacidad_generada"
              label={"117. Capacidad de generación [MW]"}
              type="text"
              value={formData.capacidad_generada}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="uso_exedente_termico"
              name="uso_exedente_termico"
              label={"118. Uso de excedentes térmicos"}
              type="text"
              value={formData.uso_exedente_termico}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="gases_escape_motor"
              name="gases_escape_motor"
              label={"119. Gases de escape del motor"}
              type="text"
              value={formData.gases_escape_motor}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="agua_caliente"
              name="agua_caliente"
              label={"120. Agua caliente"}
              type="text"
              value={formData.agua_caliente}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="absorcion"
              name="absorcion"
              label={"121. Absorción"}
              type="text"
              value={formData.absorcion}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="vapor"
              name="vapor"
              label={"122. Vapor"}
              type="text"
              value={formData.vapor}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="ht"
              name="ht"
              label={"123. HT"}
              type="text"
              value={formData.ht}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="lt"
              name="lt"
              label={"124. LT"}
              type="text"
              value={formData.lt}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="usa_exedente_termico"
              name="usa_exedente_termico"
              label={"125. ¿Usan los excedentes térmicos?"}
              type="text"
              value={formData.usa_exedente_termico}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
            <TextField
              id="disipan_aeroenfriadores"
              name="disipan_aeroenfriadores"
              label={"126. ¿HT y LT se disipan en aero-enfriadores?"}
              type="text"
              value={formData.disipan_aeroenfriadores}
              size={"small"}
              fullWidth
              variant="standard"
              disabled
            />
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="error"
          name="disipacion-termica"
          id={formData.id}
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </CardActions>
    </>
  );
}
