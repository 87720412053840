import { Grid } from '@mui/material'
import React from 'react'
import TransportationTable from './components/TransportationTable'

function Transportation() {
  return (
    <Grid container>
      <TransportationTable />
    </Grid>
  )
}

export default Transportation