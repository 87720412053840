import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack'

const action = (
    <>
        <Button color='info' size='small' href='/logout' sx={{color:'white'}}> CLICK AQUI PARA REINICIAR SESION</Button>
    </>
)

export const errorHandler = (error) => {
    const messages = error.response?.data?.messages ?? [];
    if(error.response?.status === 401){
        if(error.response?.data?.detail){
            const aEl = document.createElement('a')
            aEl.href = '/logout'
            aEl.text = 'reiniciar sesion'
            enqueueSnackbar({variant:'error',message:`${error.response.data.detail} ${aEl}`,autoHideDuration:null, persist:true, action:action})
        }
        return
    }
    if(error.response?.status === 403 || error.response?.status === 400){
        const data = error.response?.data
        if(data){
            Object.keys(data).forEach(err=>{
                enqueueSnackbar({variant:'error',message:`${err}: ${data[err]}`,autoHideDuration:8000})
            })
        }
        return
    }
    if(typeof error.response?.data === 'object'){
        const errorObj = error.response.data
        Object.keys(errorObj).forEach(e=>{
            enqueueSnackbar({variant:'error',message:`${e.toUpperCase()}: ${errorObj[e]} `,autoHideDuration:8000})
        })
        return errorObj;
    }
    messages.forEach(e=>{
        enqueueSnackbar({variant:'error',message:e.message,autoHideDuration:8000})
    })
}