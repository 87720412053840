import { Box, Grid } from "@mui/material";
import React from "react";
import MobileTable from "./components/MobileTable";

function Mobile() {
  return (
    <Grid container>
      <MobileTable />
    </Grid>
  );
}

export default Mobile;
