import axios from "axios";
import { errorValidation } from "../utils/Utils";


const emissionsUrl = `${window.location.origin.replace(/\:(?:[0-9]){4}/,':8000')}/maps/api/`

export const getAllPolygons = async() => {
    const url = `${emissionsUrl}polygon/`;
    try {
        const res = await axios.get(url);
        return res;
    } catch (error) {
        errorValidation(error);
        
    }
}

export const getAllStains = async() => {
    const url = `${emissionsUrl}stain/`;
    try {
        const res = await axios.get(url);
        return res;
    } catch (error) {
        errorValidation(error)
    }
}