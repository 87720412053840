import axios from "axios";
import { getToken } from "../utils/Utils";


const economiaCircularAPIUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/economia_circular/api/`;

const economiaCircularUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/economia_circular/`;

export const getAnalisisPreliminares = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}analisis-preliminar/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertAnalisisPreliminar = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}analisis-preliminar/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteAnalisisPreliminar = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}analisis-preliminar/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getCompromisoEconomiaCircular = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}compromiso-economia-circular/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertCompromisoEconomiaCircular = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}compromiso-economia-circular/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteCompromisoEconomiaCircular = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}compromiso-economia-circular/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getSistemaInterno = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}sistema-interno/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertSistemaInterno = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}sistema-interno/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteSistemaInterno = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}sistema-interno/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getMaterial = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}material/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertMaterial = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}material/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteMaterial = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}material/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getAgua = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}agua/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertAgua = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}agua/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteAgua = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}agua/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getEnergiaElectrica = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}energia-electrica/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertEnergiaElectrica = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}energia-electrica/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteEnergiaElectrica = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}energia-electrica/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getDisipacionEnergiaTermica = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}disipacion-energia-termica/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertDisipacionEnergiaTermica = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}disipacion-energia-termica/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteDisipacionEnergiaTermica = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}disipacion-energia-termica/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getBioenergia = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}bioenergia/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertBioenergia = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}bioenergia/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteBioenergia = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}bioenergia/${id}/`;
    const res = await axios.delete(url, config);
    return res;
  }

export const getTSEntrada = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}ts-entrada/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertTSEntrada = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}ts-entrada/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getTSSalida = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}ts-salida/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertTSSalida = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}ts-salida/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getTSOportunidad = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}ts-oportunidad/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertTSOportunidad = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}ts-oportunidad/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getEstrategiaCircular = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}estrategia-circular/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertEstrategiaCircular = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularAPIUrl}estrategia-circular/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getDashboardFormularios = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${economiaCircularUrl}dashboard-formularios`;
  const res = await axios.get(url, config);
  return res;
};
