import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DropDown from "../../../components/DropDown";
import { getEdificios } from "../../../api/Core.api";
import LoadingContext from "../../../context/LoadingContext";
import { insertDisipacionEnergiaTermica } from "../../../api/EconomiaCircular.api";
import { enqueueSnackbar } from "notistack";
import { errorHandler } from "../../../utils/ErrorHandler";

function DisipacionTermicaForm({ setStepCount, stepCount, edificioId }) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [errorMessage, setErrorMessage] = useState({});
  const [formData, setFormData] = useState({
    caballos_caldera: "",
    combustible: "",
    costo_combustible: "",
    ppm_alimentacion_caldera: "",
    ppm_purga_superficie: "",
    desc_proceso_vapor: "",
    capacidad_toneladas_refrigeracion: "",
    temp_agua_retorno: "",
    temp_agua_suministro: "",
    desc_proceso_chiller: "",
    capacidad_generada: "",
    uso_exedente_termico: "",
    gases_escape_motor: "",
    agua_caliente: "",
    absorcion: "",
    vapor: "",
    ht: "",
    lt: "",
    usa_exedente_termico: "",
    disipan_aeroenfriadores: "",
    edificioId: "",
  });
  // const [edificio, setEdificio] = useState([]);

  // useEffect(() => {
  //   const loadEdificios = async () => {
  //     try {
  //       const res = await getEdificios("disipacionTermica");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificios();
  // }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      edificioId:edificioId,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };

  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertDisipacionEnergiaTermica(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      console.log(error);
      setErrorMessage(error.response?.data);
      errorHandler(error);
    }
  };
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Typography variant="h5">DISIPACIÓN DE ENERGÍA TÉRMICA</Typography>
          <FormControl fullWidth>
            <Typography variant="subtitle1" fontWeight="bold">
              I. Vapor
            </Typography>
            <Typography variant="body1">
              En caso de contar con procesos que utilicen calderas de vapor,
              conteste lo siguiente:
            </Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("caballos_caldera")}
              helperText={errorMessage && errorMessage.hasOwnProperty("caballos_caldera") && errorMessage.caballos_caldera}
              id="caballos_caldera"
              name="caballos_caldera"
              label={"107. Caballos caldera"}
              type="text"
              value={formData.caballos_caldera}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("combustible")}
              helperText={errorMessage && errorMessage.hasOwnProperty("combustible") && errorMessage.combustible}
              id="combustible"
              name="combustible"
              label={"108. Combustible"}
              type="text"
              value={formData.combustible}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("costo_combustible")}
              helperText={errorMessage && errorMessage.hasOwnProperty("costo_combustible") && errorMessage.costo_combustible}
              id="costo_combustible"
              name="costo_combustible"
              label={"109. Costo de combustible"}
              type="text"
              value={formData.costo_combustible}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("ppm_alimentacion_caldera")}
              helperText={errorMessage && errorMessage.hasOwnProperty("ppm_alimentacion_caldera") && errorMessage.ppm_alimentacion_caldera}
              id="ppm_alimentacion_caldera"
              name="ppm_alimentacion_caldera"
              label={"110. PPM de Agua de Alimentación de la Caldera"}
              type="text"
              value={formData.ppm_alimentacion_caldera}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("ppm_purga_superficie")}
              helperText={errorMessage && errorMessage.hasOwnProperty("ppm_purga_superficie") && errorMessage.ppm_purga_superficie}
              id="ppm_purga_superficie"
              name="ppm_purga_superficie"
              label={"111. PPM de Agua de la Caldera (Purga de Superficie)"}
              type="text"
              value={formData.ppm_purga_superficie}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("desc_proceso_vapor")}
              helperText={errorMessage && errorMessage.hasOwnProperty("desc_proceso_vapor") && errorMessage.desc_proceso_vapor}
              id="desc_proceso_vapor"
              name="desc_proceso_vapor"
              label={"112. Breve descripción del proceso"}
              type="text"
              value={formData.desc_proceso_vapor}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              multiline
              minRows={6}
            />
            <Typography variant="subtitle1" fontWeight="bold">
              II. Agua Torre – Chiller - Geotermia
            </Typography>
            <Typography variant="body1">
              Responda lo siguiente, si cuenta con sistema(s) de refrigeración:
            </Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("capacidad_toneladas_refrigeracion")}
              helperText={errorMessage && errorMessage.hasOwnProperty("capacidad_toneladas_refrigeracion") && errorMessage.capacidad_toneladas_refrigeracion}
              id="capacidad_toneladas_refrigeracion"
              name="capacidad_toneladas_refrigeracion"
              label={"113. Capacidad instalada en toneladas de refrigeración"}
              type="text"
              value={formData.capacidad_toneladas_refrigeracion}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("temp_agua_retorno")}
              helperText={errorMessage && errorMessage.hasOwnProperty("temp_agua_retorno") && errorMessage.temp_agua_retorno}
              id="temp_agua_retorno"
              name="temp_agua_retorno"
              label={"114. Temperatura de agua de retorno"}
              type="text"
              value={formData.temp_agua_retorno}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("temp_agua_suministro")}
              helperText={errorMessage && errorMessage.hasOwnProperty("temp_agua_suministro") && errorMessage.temp_agua_suministro}
              id="temp_agua_suministro"
              name="temp_agua_suministro"
              label={"115. Temperatura de agua de suministro"}
              type="text"
              value={formData.temp_agua_suministro}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("desc_proceso_chiller")}
              helperText={errorMessage && errorMessage.hasOwnProperty("desc_proceso_chiller") && errorMessage.desc_proceso_chiller}
              id="desc_proceso_chiller"
              name="desc_proceso_chiller"
              label={"116. Breve descripción del proceso"}
              type="text"
              value={formData.desc_proceso_chiller}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <Typography variant="subtitle1" fontWeight="bold">
              III. Cogeneración
            </Typography>
            <Typography variant="body1">
              En caso de contar con sistema de cogeneración, conteste lo
              siguiente:
            </Typography>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("capacidad_generada")}
              helperText={errorMessage && errorMessage.hasOwnProperty("capacidad_generada") && errorMessage.capacidad_generada}
              id="capacidad_generada"
              name="capacidad_generada"
              label={"117. Capacidad de generación [MW]"}
              type="text"
              value={formData.capacidad_generada}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("uso_exedente_termico")}
              helperText={errorMessage && errorMessage.hasOwnProperty("agua_utilizada_mes") && errorMessage.uso_exedente_termico}
              id="uso_exedente_termico"
              name="uso_exedente_termico"
              label={"118. Uso de excedentes térmicos"}
              type="text"
              value={formData.uso_exedente_termico}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("gases_escape_motor")}
              helperText={errorMessage && errorMessage.hasOwnProperty("gases_escape_motor") && errorMessage.gases_escape_motor}
              id="gases_escape_motor"
              name="gases_escape_motor"
              label={"119. Gases de escape del motor"}
              type="text"
              value={formData.gases_escape_motor}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("agua_caliente")}
              helperText={errorMessage && errorMessage.hasOwnProperty("agua_caliente") && errorMessage.agua_caliente}
              id="agua_caliente"
              name="agua_caliente"
              label={"120. Agua caliente"}
              type="text"
              value={formData.agua_caliente}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("agua_utilizada_mes")}
              helperText={errorMessage && errorMessage.hasOwnProperty("agua_utilizada_mes") && errorMessage.agua_utilizada_mes}
              id="absorcion"
              name="absorcion"
              label={"121. Absorción"}
              type="text"
              value={formData.absorcion}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("agua_utilizada_mes")}
              helperText={errorMessage && errorMessage.hasOwnProperty("agua_utilizada_mes") && errorMessage.agua_utilizada_mes}
              id="vapor"
              name="vapor"
              label={"122. Vapor"}
              type="text"
              value={formData.vapor}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("ht")}
              helperText={errorMessage && errorMessage.hasOwnProperty("ht") && errorMessage.ht}
              id="ht"
              name="ht"
              label={"123. HT"}
              type="text"
              value={formData.ht}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("lt")}
              helperText={errorMessage && errorMessage.hasOwnProperty("lt") && errorMessage.lt}
              id="lt"
              name="lt"
              label={"124. LT"}
              type="text"
              value={formData.lt}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("usa_exedente_termico")}
              // error={false}
              id="usa_exedente_termico"
              name="usa_exedente_termico"
              label={"125. ¿Usan los excedentes térmicos?"}
              type="text"
              value={formData.usa_exedente_termico}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("usa_exedente_termico")&&errorMessage.usa_exedente_termico}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.usa_exedente_termico}
                  name="usa_exedente_termico"
                  id="usa_exedente_termico"
                  onChange={handleChange}
                />
              }
              label="¿Usan los excedentes térmicos?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("disipan_aeroenfriadores")}
              // error={false}
              id="disipan_aeroenfriadores"
              name="disipan_aeroenfriadores"
              label={"126. ¿HT y LT se disipan en aero-enfriadores?"}
              type="text"
              value={formData.disipan_aeroenfriadores}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("disipan_aeroenfriadores")&&errorMessage.disipan_aeroenfriadores}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.disipan_aeroenfriadores}
                  name="disipan_aeroenfriadores"
                  id="disipan_aeroenfriadores"
                  onChange={handleChange}
                />
              }
              label="¿HT y LT se disipan en aero-enfriadores?"
            /> */}
            {/* <DropDown
              id={"edificioId"}
              label={"Edificio"}
              value={formData.edificioId}
              onChange={handleChange}
              data={edificio.map((e) => ({
                label: e.nick_name,
                value: e.id,
              }))}
              size={"small"}
              variant="standard"
              helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
            /> */}
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions>
      <Button variant="outlined" color="info" onClick={handleSubmit}>
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setStepCount(stepCount + 1);
          }}
        >
          Omitir
        </Button>
        <Button variant="outlined" color="success" onClick={()=>{setStepCount(8)}}>
          Finalizar
        </Button>
      </CardActions>
    </>
  );
}

export default DisipacionTermicaForm;
