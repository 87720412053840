import axios from "axios";
import { errorValidation, getToken } from "../utils/Utils";

const coreUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/auth/api/`;

export const updateUser = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}user/${data.userId}/`;
  // try {
    const res = await axios.patch(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const changePassword = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${coreUrl}change_password`;
  // try {
    const res = await axios.patch(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};
