import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { PieChart, pieArcLabelClasses, useDrawingArea } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar,
  ResponsiveContainer,
  Legend,
} from "recharts";

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

const getPorcentage = (value, totalParam) => {
  return `${((value / totalParam) * 100).toFixed(2)} %`;
};

function PieCenterLabel1({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2.5}
      sx={{ overflowWrap: "break-word", maxWidth: 300 }}
    >
      {children}
    </StyledText>
  );
}
function PieCenterLabel2({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2}
      sx={{ overflowWrap: "break-word", maxWidth: 300 }}
    >
      {children}
    </StyledText>
  );
}
function PieCenterLabel3({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 1.6}
      sx={{ overflowWrap: "break-word", maxWidth: 300 }}
    >
      {children}
    </StyledText>
  );
}

export default function AreasMejora({ emissions, radarTitle, pieTitle }) {
  const [radarData, setRadarData] = useState(null);
  const [radarKeys, setRadarKeys] = useState([]);
  const [arcData, setArcData] = useState(null);
  const [arcTotal, setArcTotal] = useState([]);

  useEffect(() => {
    if (emissions) getRadarData(emissions);
    if (emissions) getArcData(emissions);
  }, [emissions]);

  const getRadarData = (data) => {
    if (!data || data.length < 1) return;
    const years = [];
    const radar = [
      { scope: "Combustion estacionaria", fullMark: 150000000 },
      { scope: "Refrigerante", fullMark: 150000000 },
      { scope: "Combustion movil", fullMark: 150000000 },
      { scope: "Electricidad", fullMark: 150000000 },
    ];
    Object.keys(data).forEach((key) => {
      data[key].forEach((el) => {
        years.push(el.year);
      });
    });
    data.stationary?.forEach((el) => {
      radar[3][el.year] = el.co2;
    });
    data.mobile?.forEach((el) => {
      radar[2][el.year] = el.co2;
    });
    data.refrigerant?.forEach((el) => {
      radar[1][el.year] = el.co2;
    });
    data.purchaseElectricity?.forEach((el) => {
      radar[0][el.year] = el.co2;
    });

    years.forEach(year=>{
        radar.forEach(r=>{
            if(!r[year]){
                r[year] = 0;
            }
        })
    })
    setRadarKeys([...new Set(years)]);
    setRadarData(radar);
  };
  const getArcData = (data) => {
    if (!data || data.length < 1) return;
    const arc = [
      { label: "Combustion estacionaria - ", id: 0 },
      { label: "Refrigerante - ", id: 1 },
      { label: "Combustion movil - ", id: 2 },
      { label: "Electricidad - ", id: 3 },
    ];
    let total = 0;
    arc[0]["value"] = data.stationary
      ?.map((el) => el.co2)
      .reduce((a, b) => a + b, 0);
    arc[1]["value"] = data.refrigerant
      ?.map((el) => el.co2)
      .reduce((a, b) => a + b, 0);
    arc[2]["value"] = data.mobile
      ?.map((el) => el.co2)
      .reduce((a, b) => a + b, 0);
    arc[3]["value"] = data.purchaseElectricity
      ?.map((el) => el.co2)
      .reduce((a, b) => a + b, 0);
    total = arc.map((el) => el.value).reduce((a, b) => a + b, 0);
    arc[0]["label"] = arc[0]["label"] + getPorcentage(arc[0]["value"], total);
    arc[1]["label"] = arc[1]["label"] + getPorcentage(arc[1]["value"], total);
    arc[2]["label"] = arc[2]["label"] + getPorcentage(arc[2]["value"], total);
    arc[3]["label"] = arc[3]["label"] + getPorcentage(arc[3]["value"], total);
    setArcData(arc);
    setArcTotal(total);
  };
  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            {radarData && radarData.length > 0 && (
              <Grid
                item
                xs={6}
                sx={{
                  mb: 4,
                }}
              >
                <Typography
                  variant="subtitle1"
                  color={grey[600]}
                  align="center"
                >
                  {radarTitle}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: 700,
                    height: 300,
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <RadarChart
                      cx="50%"
                      cy="50%"
                      outerRadius="80%"
                      data={radarData}
                    >
                      <PolarGrid />
                      <PolarAngleAxis dataKey="scope" />
                      <PolarRadiusAxis
                        angle={0}
                        domain={["auto", "auto"]}
                        tick={false}
                      />
                      {radarKeys.map((key, index) => (
                        <Radar
                          name={key}
                          dataKey={key}
                          stroke={green[index * 100 + 300]}
                          fill={green[index * 100 + 300]}
                          fillOpacity={0.6}
                        />
                      ))}
                      <Legend />
                    </RadarChart>
                  </ResponsiveContainer>
                </Box>
              </Grid>
            )}
            {arcData && (
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color={grey[600]}
                  align="center"
                >
                  {pieTitle}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PieChart
                    colors={[green[300], green[500], green[700], green[900]]}
                    series={[
                      {
                        data: arcData,
                        innerRadius: 70,
                        outerRadius: 90,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontWeight: "bold",
                      },
                    }}
                    width={750}
                    height={250}
                  >
                    <PieCenterLabel1>Porcentaje</PieCenterLabel1>
                    <PieCenterLabel2>de</PieCenterLabel2>
                    <PieCenterLabel3>emisiones</PieCenterLabel3>
                  </PieChart>
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
