import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TabPanel from "../../components/TabPanel";
import Edificio from "./edificio/Edificio";
import LoadingContext from "../../context/LoadingContext";
import { getEdificios, getEmpresa } from "../../api/Core.api";
import { enqueueSnackbar } from "notistack";
import Empresa from "./empresa/Empresa";
import AuthContext from "../../context/AuthContext";
import User from "../Auth/User";
import { Business, Factory, Person } from "@mui/icons-material";
import { errorHandler } from "../../utils/ErrorHandler";

function efProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function Profile() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [empresa, setEmpresa] = useState({});
  const [edificio, setEdificio] = useState({});
  const [value, setValue] = useState(0);

  useEffect(() => {
    const loadEmpresa = async () => {
      startLoading("Cargando");
      try {
        const res = await getEmpresa(user.empresaId);
        setEmpresa(res.data);
        stopLoading();
      } catch (error) {
        // if (error.response.data) {
        //   console.log(error.response.data);
        //   enqueueSnackbar({ variant: "error", message: error.response.data });
        //   return;
        // }
        // if (error.request) {
        //   console.log(error.request);
        //   enqueueSnackbar({ variant: "error", message: error.request });
        //   return;
        // }
        // console.error(error.respose?.status);
        errorHandler(error)
        stopLoading()
        return
        // enqueueSnackbar({ variant: "error", message: error.response?.data || error });
      }
    };
    const loadEdificio = async () => {
      startLoading("Cargando");
      try {
        const res = await getEdificios();
        setEdificio(res.data);
        stopLoading();
      } catch (error) {
        // if (error.response.data) {
        //   console.log(error.response.data);
        //   enqueueSnackbar({ variant: "error", message: error.response.data });
        //   return;
        // }
        // if (error.request) {
        //   console.log(error.request);
        //   enqueueSnackbar({ variant: "error", message: error.request });
        //   return;
        // }
        // console.log(error);
        errorHandler(error)
        stopLoading()
        return
        // enqueueSnackbar({ variant: "error", message: error.response?.data || error });
      }
    };
    loadEmpresa();
    loadEdificio();
  }, []);

  const handleChange = (evt, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="Perfil">
            <Tab
              label="Usuario"
              icon={<Person />}
              iconPosition="start"
              {...efProps(0)}
            />
            <Tab
              label="Empresa"
              icon={<Business />}
              iconPosition="start"
              {...efProps(1)}
            />
            <Tab
              label="Plantas"
              icon={<Factory />}
              iconPosition="start"
              {...efProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <User />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Empresa data={empresa} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Edificio edificios={edificio} empresa={empresa} />
        </TabPanel>
      </Box>
    </>
  );
}

export default Profile;
