import { useContext, useEffect, useState } from "react";
import TabPanel from "../../../components/TabPanel";
import { getEmissionFactors } from "../../../api/Core.api";
import LoadingContext from "../../../context/LoadingContext";
import { enqueueSnackbar } from "notistack";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import StationaryCombustionFactors from "./components/StationaryCombustionFactors";
import MobileCombustionFactors from "./components/MobileCombustionFactors";
import RefrigerantFactors from "./components/RefrigerantFactors";
import CustomEmissionFactors from "./components/CustomEmissionFactors";
import TransportFactors from "./components/TransportFactors";
import PurchasedElectricityFactors from "./components/PurchasedElectricityFactors";

function efProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function EmissionFactors() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [customEmissionFactors, setCustomEmissionFactors] = useState([]);
  const [stationaryCombustionData, setStationaryCombustionData] = useState([]);
  const [mobileCombustion, setMobileCombustion] = useState([]);
  const [refrigerant, setRefrigerant] = useState([]);
  const [purchasedElectricity, setPurchasedElectricity] = useState([]);
  const [transportation, setTransportation] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const getData = async () => {
      startLoading("Cargando Emission Factors");
      try {
        const res = await getEmissionFactors();
        const data = res.data;
        setStationaryCombustionData(
          data.filter(
            (d) =>
              d.activity_type.name === "Stationary Combustion" &&
              d.is_custom === false
          )
        );
        setMobileCombustion(
          data.filter(
            (d) =>
              d.activity_type.name === "Mobile Combustion" &&
              d.is_custom === false
          )
        );
        setRefrigerant(
          data.filter(
            (d) =>
              d.activity_type.name === "Refrigerants" && d.is_custom === false
          )
        );
        setPurchasedElectricity(
          data.filter(
            (d) =>
              d.activity_type.name === "Purchased Electricity" &&
              d.is_custom === false
          )
        );
        setTransportation(
          data.filter(
            (d) =>
              d.activity_type.name === "Transportation" && d.is_custom === false
          )
        );
        setCustomEmissionFactors(data.filter((d) => d.is_custom === true));
        stopLoading();
      } catch (error) {
        if (error.response.data) {
          console.log(error.response.data);
          enqueueSnackbar({ variant: "error", message: error.response.data });
          return;
        }
        if (error.request) {
          console.log(error.request);
          enqueueSnackbar({ variant: "error", message: error.request });
          return;
        }
        console.log(error);
        enqueueSnackbar({ variant: "error", message: error });
      }
    };
    getData();
  }, []);

  const handleChange = (evt, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Emission Factors"
          >
            <Tab label="S1 - Stationary Combustion" {...efProps(0)} />
            <Tab label="S1 - Mobile Combustion" {...efProps(1)} />
            <Tab label="S1 - Refrigerants" {...efProps(2)} />
            <Tab label="S2 - Purchased Electricity" {...efProps(3)} />
            <Tab label="S3 - Transport" {...efProps(4)} />
            <Tab label="Custom Emission Factors" {...efProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <StationaryCombustionFactors rows={stationaryCombustionData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MobileCombustionFactors rows={mobileCombustion} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <RefrigerantFactors rows={refrigerant} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PurchasedElectricityFactors rows={purchasedElectricity} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TransportFactors rows={transportation} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <CustomEmissionFactors rows={customEmissionFactors} />
        </TabPanel>
      </Box>
    </>
  );
}

export default EmissionFactors;
