import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from "@mui/x-data-grid"

function FilterDensityToolBar() {
  return (
    <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
  )
}

export default FilterDensityToolBar