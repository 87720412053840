import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getEdificios } from "../../../api/Core.api";
import DropDown from "../../../components/DropDown";
import { Add, Delete } from "@mui/icons-material";
import LoadingContext from "../../../context/LoadingContext";
import { enqueueSnackbar } from "notistack";
import {
  insertBioenergia,
  insertEstrategiaCircular,
} from "../../../api/EconomiaCircular.api";
import { errorHandler } from "../../../utils/ErrorHandler";

function BioenergiaForm({ setStepCount, stepCount, edificioId }) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [errorMessage, setErrorMessage] = useState({});
  const [formData, setFormData] = useState({
    tiene_agua_residual_alta_carga_organica: "",
    caudal_agua_residual: "",
    lodos_residuales: "",
    cantidad: "",
    tiene_reactor_digestor: "",
    tiene_ptar: "",
    ppm_entrada_ptar: "",
    ppm_salida_ptar: "",
    planta_tratamiento_agua_cercana: "",
    tratamiento_residuos: "",
    distancia_reciduos_organicos: "",
    tiempo_estimado_almacenamiento: "",
    localizacion_recibos: "",
    tipo_vehiculos_para_transporte: "",
    conoce_cuantificacion_emisiones: "",
    tiene_biodigestor: "",
    capacidad: "",
    toneladas_entreda: "",
    filtran_biogas: "",
    humedad: "",
    desc_proceso: "",
    comentarios: "",
    edificioId: "",
    residuos_organicos:[],
  });
  const [residuoOrganico, setResiduoOrganico] = useState([
    {
      tipo_residuo: "",
      cantidad: "",
      periodo: "",
    },
  ]);
  // const [edificio, setEdificio] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  // useEffect(() => {
  //   const loadEdificios = async () => {
  //     try {
  //       const res = await getEdificios("bioenergia");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificios();
  // }, []);

  const handleAddResiduo = () => {
    const residuo = [
      ...residuoOrganico,
      {
        tipo_residuo: "",
        cantidad: "",
        periodo: "",
      },
    ];
    residuo.length > 1 && setDisableBtn(true);
    setResiduoOrganico(residuo);
  };

  const handleChangeResiduo = (e, i) => {
    const inputData = [...residuoOrganico];
    inputData[i][e.target.name] = e.target.value;
    setResiduoOrganico(inputData);
  };

  const handleDeleteResiduo = (i) => {
    const deleteResiduo = [...residuoOrganico];
    deleteResiduo.length > 1 && deleteResiduo.splice(i, 1);
    deleteResiduo.length < 2 && setDisableBtn(false);
    setResiduoOrganico(deleteResiduo);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      edificioId:edificioId,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };

  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertBioenergia(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
        autoHideDuration: 8000,
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      console.log(error);
      setErrorMessage(error.response?.data);
      errorHandler(error);
    }
  };

  useEffect(() => {
    const handleChildChange = () => {
      setFormData({ ...formData, residuos_organicos: residuoOrganico });
    };
    handleChildChange();
  }, [residuoOrganico]);
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FormControl fullWidth>
            <Typography variant="h5">BIOENERGÍA </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              127. Residuos orgánicos que genera la empresa:{" "}
            </Typography>
            {residuoOrganico.map((data, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    gap: "10px 30px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <TextField
                    id="tipo_residuo"
                    name="tipo_residuo"
                    label={"Tipo de residuo"}
                    type="text"
                    size={"small"}
                    onChange={(e) => handleChangeResiduo(e, i)}
                    value={data.tipo_residuo}
                    variant="standard"
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    id="cantidad"
                    name="cantidad"
                    label={"Cantidad"}
                    type="text"
                    size={"small"}
                    onChange={(e) => handleChangeResiduo(e, i)}
                    value={data.cantidad}
                    variant="standard"
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    id="periodo"
                    name="periodo"
                    label={"Periodo"}
                    type="text"
                    size={"small"}
                    onChange={(e) => handleChangeResiduo(e, i)}
                    value={data.periodo}
                    variant="standard"
                    sx={{ flexGrow: 1 }}
                    helperText={"Ingresar fecha en formato YYYY-MM-DD"}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteResiduo(i)}
                    sx={{ flexFlow: 10 }}
                    disabled={!disableBtn}
                  >
                    <Delete />
                  </Button>
                </Box>
              );
            })}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                color="info"
                onClick={(e) => {
                  handleAddResiduo();
                }}
              >
                <Add />
                <Typography>Agregar residuo organico</Typography>
              </Button>
            </Box>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("tiene_agua_residual_alta_carga_organica")}
              // error={false}
              id="tiene_agua_residual_alta_carga_organica"
              name="tiene_agua_residual_alta_carga_organica"
              label={"128. ¿Generan aguas residuales con alta carga orgánica?"}
              type="text"
              value={formData.tiene_agua_residual_alta_carga_organica}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("tiene_agua_residual_alta_carga_organica")&&errorMessage.tiene_agua_residual_alta_carga_organica}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.tiene_agua_residual_alta_carga_organica}
                  name="tiene_agua_residual_alta_carga_organica"
                  id="tiene_agua_residual_alta_carga_organica"
                  onChange={handleChange}
                />
              }
              label="¿Generan aguas residuales con alta carga orgánica?"
            /> */}
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("caudal_agua_residual")}
            helperText={errorMessage && errorMessage.hasOwnProperty("caudal_agua_residual") && errorMessage.caudal_agua_residual}
              name="caudal_agua_residual"
              label="129. ¿Qué caudal de agua residual se genera y en qué periodo?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.caudal_agua_residual}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("lodos_residuales")}
              // error={false}
              id="lodos_residuales"
              name="lodos_residuales"
              label={"130. ¿Generan lodos residuales del tratamiento de agua?"}
              type="text"
              value={formData.lodos_residuales}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("lodos_residuales")&&errorMessage.lodos_residuales}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.lodos_residuales}
                  name="lodos_residuales"
                  id="lodos_residuales"
                  onChange={handleChange}
                />
              }
              label="¿Generan lodos residuales del tratamiento de agua?"
            /> */}
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("cantidad")}
            helperText={errorMessage && errorMessage.hasOwnProperty("cantidad") && errorMessage.cantidad}
              name="cantidad"
              label="131. ¿Qué cantidad y en qué periodo?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.cantidad}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("tiene_reactor_digestor")}
              // error={false}
              id="tiene_reactor_digestor"
              name="tiene_reactor_digestor"
              label={"132. ¿Cuentan con reactor digestor para tratamiento de agua?"}
              type="text"
              value={formData.tiene_reactor_digestor}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("tiene_reactor_digestor")&&errorMessage.tiene_reactor_digestor}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.tiene_reactor_digestor}
                  name="tiene_reactor_digestor"
                  id="tiene_reactor_digestor"
                  onChange={handleChange}
                />
              }
              label="¿Cuentan con reactor digestor para tratamiento de agua?"
            /> */}
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("caudal_agua_residual")}
            helperText={errorMessage && errorMessage.hasOwnProperty("caudal_agua_residual") && errorMessage.caudal_agua_residual}
              name="caudal_agua_residual"
              label="133. ¿De qué capacidad es?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.caudal_agua_residual}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("tiene_ptar")}
              // error={false}
              id="tiene_ptar"
              name="tiene_ptar"
              label={"134. ¿Se cuenta con PTAR?"}
              type="text"
              value={formData.tiene_ptar}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("tiene_ptar")&&errorMessage.tiene_ptar}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.tiene_ptar}
                  name="tiene_ptar"
                  id="tiene_ptar"
                  onChange={handleChange}
                />
              }
              label="¿Se cuenta con PTAR?"
            /> */}
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("ppm_entrada_ptar")}
            helperText={errorMessage && errorMessage.hasOwnProperty("ppm_entrada_ptar") && errorMessage.ppm_entrada_ptar}
              name="ppm_entrada_ptar"
              label="134. PPM a la entrada a PTAR"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.ppm_entrada_ptar}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("ppm_salida_ptar")}
            helperText={errorMessage && errorMessage.hasOwnProperty("ppm_salida_ptar") && errorMessage.ppm_salida_ptar}
              name="ppm_salida_ptar"
              label="136. PPM a la salida de PTAR"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.ppm_salida_ptar}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("planta_tratamiento_agua_cercana")}
            helperText={errorMessage && errorMessage.hasOwnProperty("planta_tratamiento_agua_cercana") && errorMessage.planta_tratamiento_agua_cercana}
              name="planta_tratamiento_agua_cercana"
              label="137. ¿Cuál es la planta de tratamiento de agua más cercana a la empresa?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.planta_tratamiento_agua_cercana}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("tratamiento_residuos")}
            helperText={errorMessage && errorMessage.hasOwnProperty("tratamiento_residuos") && errorMessage.tratamiento_residuos}
              name="tratamiento_residuos"
              label="138. ¿Se les da algún tratamiento a estos residuos? P. ej. secado al sol"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.tratamiento_residuos}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("distancia_reciduos_organicos")}
            helperText={errorMessage && errorMessage.hasOwnProperty("distancia_reciduos_organicos") && errorMessage.distancia_reciduos_organicos}
              name="distancia_reciduos_organicos"
              label="139. ¿A qué distancia se encuentra la fuente(s) de los residuos orgánicos del lugar de
              disposición? ¿hay algún almacenamiento previo a su disposición?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.distancia_reciduos_organicos}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("tiempo_estimado_almacenamiento")}
            helperText={errorMessage && errorMessage.hasOwnProperty("tiempo_estimado_almacenamiento") && errorMessage.tiempo_estimado_almacenamiento}
              name="tiempo_estimado_almacenamiento"
              label="140. ¿Hay algún tiempo estimado de almacenamiento de los residuos orgánicos para
              su posterior disposición? ¿Esto se realiza a cielo abierto o como se realiza?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.tiempo_estimado_almacenamiento}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("localizacion_recibos")}
            helperText={errorMessage && errorMessage.hasOwnProperty("localizacion_recibos") && errorMessage.localizacion_recibos}
              name="localizacion_recibos"
              label="141. ¿Cuál es la localización del lugar donde se disponen estos residuos? ¿implica un
              costo extra esta disposición?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.localizacion_recibos}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("tipo_vehiculos_para_transporte")}
            helperText={errorMessage && errorMessage.hasOwnProperty("tipo_vehiculos_para_transporte") && errorMessage.tipo_vehiculos_para_transporte}
              name="tipo_vehiculos_para_transporte"
              label="142. ¿Qué tipo de vehículo y cuantas unidades utilizan para transportar los residuos
              orgánicos? ¿con que periodicidad?"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.tipo_vehiculos_para_transporte}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("conoce_cuantificacion_emisiones")}
            helperText={errorMessage && errorMessage.hasOwnProperty("conoce_cuantificacion_emisiones") && errorMessage.conoce_cuantificacion_emisiones}
              name="conoce_cuantificacion_emisiones"
              label="143. ¿Conocen la cuantificación de emisiones (medidas) del proceso de producción
              por mes? Por ej. Emisiones de gases medidos, agua residual al alcantarillado,
              emisiones al suelo (polvos, residuos, etc.)"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.conoce_cuantificacion_emisiones}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("tiene_biodigestor")}
              // error={false}
              id="tiene_biodigestor"
              name="tiene_biodigestor"
              label={"144. ¿Se cuenta con biodigestor?"}
              type="text"
              value={formData.tiene_biodigestor}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("tiene_biodigestor")&&errorMessage.tiene_biodigestor}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.tiene_biodigestor}
                  name="tiene_biodigestor"
                  id="tiene_biodigestor"
                  onChange={handleChange}
                />
              }
              label="¿Se cuenta con biodigestor?"
            /> */}
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("capacidad")}
            helperText={errorMessage && errorMessage.hasOwnProperty("capacidad") && errorMessage.capacidad}
              name="capacidad"
              label="145. Capacidad [m3]"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.capacidad}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("toneladas_entreda")}
            helperText={errorMessage && errorMessage.hasOwnProperty("toneladas_entreda") && errorMessage.toneladas_entreda}
              name="toneladas_entreda"
              label="146. Indicar Toneladas o m3 diarios de entrada"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.toneladas_entreda}
              variant="standard"
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("filtran_biogas")}
              // error={false}
              id="filtran_biogas"
              name="filtran_biogas"
              label={"147. ¿Filtran el biogás? (eliminan ácido sulfhídrico)"}
              type="text"
              value={formData.filtran_biogas}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("filtran_biogas")&&errorMessage.filtran_biogas}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.filtran_biogas}
                  name="filtran_biogas"
                  id="filtran_biogas"
                  onChange={handleChange}
                />
              }
              label="¿Filtran el biogás? (eliminan ácido sulfhídrico) "
            /> */}
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("temperatura_biogas")}
            helperText={errorMessage && errorMessage.hasOwnProperty("temperatura_biogas") && errorMessage.temperatura_biogas}
              name="temperatura_biogas"
              label="148. Temperatura del biogás"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.temperatura_biogas}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("humedad")}
            helperText={errorMessage && errorMessage.hasOwnProperty("humedad") && errorMessage.humedad}
              name="humedad"
              label="149. Humedad"
              type="number"
              size={"small"}
              onChange={handleChange}
              value={formData.humedad}
              variant="standard"
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("desc_proceso")}
            helperText={errorMessage && errorMessage.hasOwnProperty("desc_proceso") && errorMessage.desc_proceso}
              name="desc_proceso"
              label="150. Breve descripción del proceso"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.desc_proceso}
              variant="standard"
              multiline
              minRows={6}
            />
            <TextField
            error={errorMessage && errorMessage.hasOwnProperty("comentarios")}
            helperText={errorMessage && errorMessage.hasOwnProperty("comentarios") && errorMessage.cantidad}
              name="comentarios"
              label="151. Comentarios"
              type="text"
              size={"small"}
              onChange={handleChange}
              value={formData.comentarios}
              variant="standard"
              multiline
              minRows={6}
            />
            {/* <DropDown
              id={"edificioId"}
              label={"Edificio"}
              value={formData.edificioId}
              onChange={handleChange}
              data={edificio.map((e) => ({ label: e.nick_name, value: e.id }))}
              size={"small"}
              variant="standard"
              helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
            /> */}
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions sx={{display:'flex', gap:1}}>
        <Button variant="outlined" color="success" onClick={handleSubmit}>
          Finalizar
        </Button>
        <Button variant="outlined" color="error" onClick={()=>{setStepCount(8)}}>
          Omitir y finalizar
        </Button>
      </CardActions>
    </>
  );
}

export default BioenergiaForm;
