import React from 'react'
import StyledCard from '../../../../components/StyledCard';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import FilterDensityToolBar from '../../../../components/FilterDensityToolBar';

const columns = [
    {
      field: "name",
      headerName: "Mobile Fuel",
      flex:0.5
    },
    {
      field: "co2",
      headerName: "CO2 Factor\n\r(kg / unit)",
    },
    {
      field: "ch4",
      headerName: "CH4 Factor\n\r(kg / unit)",
    },
    {
      field: "n2o",
      headerName: "N2O Factor\n\r(kg / unit)",
    },
    {
      field: "biofuel_co2",
      headerName: "Biogenic CO2 Factor\n\r(kg Biogenic CO2 per mmBtu)",
    },
    {
      field: "ar4",
      headerName: "AR4\n\r(kgCO2e)",
    },
    {
      field: "ar5",
      headerName: "AR5\n\r(kgCO2e)",
    },
    {
      field: "num_unit",
      headerName: "Standarized unit",
    },
    {
      field: "source",
      headerName: "Source",
      flex:1
    },
  ];

function MobileCombustionFactors({rows}) {
  return (
    <StyledCard title="Mobile Combustion">
      <CustomDataGrid rows={rows} columns={columns} customToolBarComp={FilterDensityToolBar}/>
    </StyledCard>
  )
}

export default MobileCombustionFactors