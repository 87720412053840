import { Box, Typography } from "@mui/material";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import StyledCard from "../../../../components/StyledCard";
import FilterDensityToolBar from "../../../../components/FilterDensityToolBar";

const columns = [
  {
    field: "name",
    headerName: "Fuel Type",
    flex:0.5,
  },
  {
    field: "formila",
    headerName: "Formula",
    flex: 0.25,
  },
  {
    field: "chemical_name",
    headerName: "Chemical Name",
    flex: 0.25,
  },
  {
    field: "co2",
    headerName: "CO2 Factor\n\r(kg CO2 per mmBtu)",
    flex: 0.25,
  },
  {
    field: "ch4",
    headerName: "CH4 Factor\n\r(g CH4 per mmBtu)",
    flex: 0.25,
  },
  {
    field: "n2o",
    headerName: "N2O Factor\n\r(g N2O per mmBtu)",
    flex: 0.25,
  },
  {
    field: "biofuel_co2",
    headerName: "Biogenic CO2 Factor\n\r(kg Biogenic CO2 per mmBtu)",
    flex: 0.25,
  },
  {
    field: "ar4",
    headerName: "AR4\n\r(kgCO2e)",
    flex: 0.25,
  },
  {
    field: "ar5",
    headerName: "AR5\n\r(kgCO2e)",
    flex: 0.25,
  },
  {
    field: "num_unit",
    headerName: "Units",
  },
  {
    field: "source",
    headerName: "Source",
    flex: 1,
  },
];

function RefrigerantFactors({ rows }) {
  return (
    <StyledCard title="Refrigerants">
      <CustomDataGrid columns={columns} rows={rows} customToolBarComp={FilterDensityToolBar}/>
      <Box sx={{my:3}}>
        <Typography variant="h6">Notes:</Typography>
        <Typography variant="body2" align="justify">
          * Refrigerant blend GWPs are calculated using a weighted average from
          the blend composition and the IPCC GWP values. The blend compositions
          are from ASHRAE Standard 34-2013.
        </Typography>
        <Typography variant="body2" align="justify">
          **It is customary to exclude CFCs, HCFCs, and halons from GHG
          inventories because they are regulated and are being phased out under
          the Montreal Protocol. These ozone depleting substances may be
          documented as memo items reported as total release of gases, but no
          GWPs are applied and they do not contribute to the CO2e emissions
          inventory.
        </Typography>
        <Typography variant="body2" align="justify">
          ***Based on centrifugal chillers (70% of installed capacity) using:
          R-11 or R-123 - 65%; R-12 or R-134a – 12%; R-22 – 23% (Sand, J., S.
          Fisher, V. Baxter, “Energy and Global Warming Impacts of HFC
          Refrigerants and Emerging Technologies,” ORNL 1997.) Could also
          conservatively assume the refrigerant type to be HFC-134a. (The World
          Bank Group, "Greenhouse Gas Emissions Inventory Management Plan for
          Internal Business Operations 2014," 2015.) If equipment type is known,
          see Tables 2 and 4 on Refrigerant Factors tab for commonly used
          refrigerants.
        </Typography>
      </Box>
      <Box sx={{my:3}}>
        <Typography variant="h6">Sources:</Typography>
        <Typography variant="body2" align="justify">
          -Intergovernmental Panel on Climate Change (IPCC), "Climate Change
          2007: IPCC Fourth Assessment Report (AR4)." 2007.
          (http://195.70.10.65/pdf/assessment-report/ar4/syr/ar4_syr.pdf) GWPs:
          IPCC's Working Group I, "Climate Change 2007: The Physical Science
          Basis." Ch. 2, p. 212-215, Tables 2.14 and 2.15, 2007.
          (http://195.70.10.65/pdf/assessment-report/ar4/wg1/ar4-wg1-chapter2.pdf)
        </Typography>
        <Typography variant="body2" align="justify">
          -Intergovernmental Panel on Climate Change (IPCC), "Climate Change
          2013: IPCC Fifth Assessment Report (AR5)." 2013.
          (http://www.climatechange2013.org/images/report/WG1AR5_ALL_FINAL.pdf).
          GWPs: IPCC's Working Group I, "Climate Change 2013: The Physical
          Science Basis." Ch. 8, p. 731-738, Table 8.A.1, 2013.
          (https://www.ipcc.ch/pdf/assessment-report/ar5/wg1/WG1AR5_Chapter08_FINAL.pdf)
        </Typography>
        <Typography variant="body2" align="justify">
          "-ASHRAE Standard 34 / World Resources Institute, ""Calculating HFC
          and PFC Emissions from the Manufacturing, Servicing, and/or Disposal
          of Refrigeration and Air-Conditioning Equipment, Version 1.0.""
          January 2005.
          (http://www.ghgprotocol.org/calculation-tools/refrigeration) "
        </Typography>
        <Typography variant="body2" align="justify">
          -The Climate Registry, "2015 Climate Registry Default Emission
          Factors," Tables B.1, B.2
          (http://www.theclimateregistry.org/wp-content/uploads/2015/04/2015-TCR-Default-EF-April-2015-FINAL.pdf)
        </Typography>
      </Box>
    </StyledCard>
  );
}

export default RefrigerantFactors;
