import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { login, refreshToken } from "../api/Auth";
import { useSnackbar } from "notistack";
import LoadingContext from "./LoadingContext";
import { jwtDecode } from "jwt-decode";
import { errorHandler } from "../utils/ErrorHandler";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  // const [token, setToken] = useState(localStorage.getItem("Token"));
  const [user, setUser] = useState(() =>
    localStorage.getItem("User")
      ? JSON.parse(localStorage.getItem("User"))
      : null
  );

  const decodeToken = (token = null) => {
    try {
      const aToken = authTokens?.access || token;
      const decoded = jwtDecode(aToken);
      const userData = {
        userId: decoded.user_id,
        username: decoded.username,
        name: decoded.name,
        first_name: decoded.name,
        last_name: decoded.last_name,
        email: decoded.email,
        fullName: decoded.full_name,
        empresaId: decoded.empresa_id,
        empresa: decoded.empresa,
        permisos: decoded.permissions,
      };
      return userData;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const loginUser = async (e) => {
    e.preventDefault();
    startLoading("Esperando respuesta");
    try {
      const res = await login({
        username: e.target.username.value,
        password: e.target.password.value,
      });
      setAuthTokens(res.data);
      setUser(decodeToken(res.data.access));
      localStorage.setItem(
        "User",
        JSON.stringify(decodeToken(res.data.access))
      );
      localStorage.setItem("authTokens", JSON.stringify(res.data));
      stopLoading();
      navigate("/");
    } catch (error) {
      stopLoading();
      errorHandler(error)
    }
  };

  const logoutUser = () => {
    localStorage.clear();
    setAuthTokens(localStorage.getItem("authTokens"));
    setUser(localStorage.getItem("User"));
    window.location = "/login"
  };

  const profile = () => {
    navigate("/profile");
  };

  const updateUser = (data) => {
    const userData = {
      ...user,
      username: data.username,
      name: data.first_name,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      fullName: `${data.first_name} ${data.last_name}`,
    };
    setUser(userData);
    localStorage.setItem("User", JSON.stringify(userData));
  };

  const updateToken = async () => {
    try {
      const res = await refreshToken(authTokens?.refresh);
      setAuthTokens(res.data);
      localStorage.setItem("authTokens", JSON.stringify(res.data));
    } catch (error) {
      console.log(error);
      logoutUser();
    }
  };

  useEffect(() => {
    let fourMinutes = 1000 * 60 * 4;

    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [authTokens]);

  const contextData = {
    user: user,
    token: authTokens?.access,
    loginUser: loginUser,
    logoutUser: logoutUser,
    profile: profile,
    refreshUser: updateUser,
    refreshTokens: updateToken,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
