import { Box, Grid, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { LineChart } from "recharts";
import PropTypes from "prop-types";

export default function EmisionesHistoricas({emissions,xs}) {
  const [emissionSummary, setEmissionSummary] = useState(null);
  const [yearsArray, setYearsArray] = useState(null);

  useEffect(()=>{
    if(emissions) formatData(emissions);
  },[emissions])

  const formatData = (data)=>{
    const summary = [];
    const years = [];
    Object.keys(data).forEach((key) => {
        data[key].forEach((el) => {
          summary.push(el);
          years.push(el.year);
        });
      });
      setYearsArray([...new Set(years)]);
      setEmissionSummary(summary);
  }
  return (
    <>
    {console.log("component",emissionSummary)}
      {emissionSummary && emissionSummary.length > 0 && (
        <Grid
          item
          xs={xs}
          sx={{ borderRadius: "10px", backgroundColor: grey[100] }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <Typography variant="subtitle1" color={grey[600]}>
              Emisiones históricas
            </Typography>
            <LineChart
              width={600}
              height={150}
              margin={{ left: 100, right: 100 }}
              slotProps={{
                legend: {
                  direction: "row",
                  position: { vertical: "top", horizontal: "middle" },
                  labelStyle: { fontSize: 12 },
                  itemMarkWidth: 10,
                  itemMarkHeight: 10,
                },
              }}
              series={[
                {
                  data: emissionSummary
                    .filter((m) => m.scope === "S1 Combustion Estacionaria")
                    .map((m) => m.co2),
                  label: "Combustion Estacionaria",
                  showMark: true,
                  stack: "total",
                  area: true,
                },
                {
                  data: emissionSummary
                    .filter((m) => m.scope === "S1 Combustion Movil")
                    .map((m) => m.co2),
                  label: "Combustion Movil",
                  showMark: true,
                  stack: "total",
                  area: true,
                },
                {
                  data: emissionSummary
                    .filter((m) => m.scope === "S1 Refrigerante")
                    .map((m) => m.co2),
                  label: "Refrigerantes",
                  showMark: true,
                  stack: "total",
                  area: true,
                },
                {
                  data: emissionSummary
                    .filter((m) => m.scope === "S2 Electricidad")
                    .map((m) => m.co2),
                  label: "Electricidad",
                  showMark: true,
                  stack: "total",
                  area: true,
                },
              ]}
              colors={[green[300], green[500], green[700], green[900]]}
              xAxis={[
                {
                  scaleType: "point",
                  position: "bottom",
                  data: yearsArray,
                },
              ]}
              sx={{ ".MuiLineElement-root": { display: "none" } }}
            />
          </Box>
        </Grid>
      )}
    </>
  );
}


EmisionesHistoricas.defaultProps = {
    xs: 12,
    emissions:[]
  };
  
  EmisionesHistoricas.prototype = {
    xs: PropTypes.number,
    emissions: PropTypes.array
  };