import React from "react";
import StyledCard from "../../../../components/StyledCard";
import CustomDataGrid from "../../../../components/CustomDataGrid";
import FilterDensityToolBar from "../../../../components/FilterDensityToolBar";

const columns = [
  {
    field: "name",
    headerName: "Name of Custom EF",
  },
  {
    field: "scope",
    headerName: "Scope",
  },
  {
    field: "activity_type",
    headerName: "Activity Type",
    valueGetter: (params) => {
      return `${params.row.activity_type.name}`;
    },
    flex: 1,
  },
  {
    field: "source",
    headerName: "Source of Emission Factor",
    flex: 1,
  },
  {
    field: "co2",
    headerName: "Fosil CO2",
    flex: 0.5,
  },
  {
    field: "ch4",
    headerName: "CH4",
    flex: 0.5,
  },
  {
    field: "n2o",
    headerName: "N2O",
    flex: 0.5,
  },
  {
    field: "biofuel_co2",
    headerName: "Biofuel CO2",
    flex: 0.5,
  },
  {
    field: "co2e",
    headerName: "CO2e",
    flex: 0.5,
  },
  {
    field: "num_unit",
    headerName: "Unit of Emission Factors (numerator unit)",
    flex: 0.5,
  },
  {
    field: "den_unit",
    headerName: "Unit of Emission Factors (denominator unit)",
    flex: 0.5,
  },
  {
    field: "notes",
    headerName: "Notes",
    flex: 1,
  },
];

function CustomEmissionFactors({ rows }) {
  return (
    <StyledCard title="Custom Emission Factors">
      <CustomDataGrid columns={columns} rows={rows} customToolBarComp={FilterDensityToolBar}/>
    </StyledCard>
  );
}

export default CustomEmissionFactors;
