import axios from "axios";
import { errorValidation, getToken } from "../utils/Utils";


const emissionsApiUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/emissions/api/`;
const emissionsUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/emissions/`;

export const getAllStationaryCombustion = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}stationary-combustion/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertStationaryCombustion = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}stationary-combustion/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getAllMobileCombustions = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}mobile-combustion/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertMobileCombustions = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}mobile-combustion/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getAllRefrigerant = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}refrigerant/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertRefrigerant = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}refrigerant/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getAllPurchasedElectricity = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}purchased-electricity/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertPurchasedElectricity = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}purchased-electricity/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getAllTransportation = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}transportation/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const insertTransportation = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}transportation/`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getDisaggregation = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}disaggregation/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const getEmissionSummary = async () => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}emission-summary/`;
  // try {
    const res = await axios.get(url, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
};

export const deleteStationaryCombustion = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}stationary-combustion/${id}/`;
    const res = await axios.delete(url, config);
    return res;
};

export const deleteMobileCombustion = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}mobile-combustion/${id}/`;
    const res = await axios.delete(url, config);
    return res;
};

export const deleteRefrigerant = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}refrigerant/${id}/`;
    const res = await axios.delete(url, config);
    return res;
};

export const deletePurchasedElectricity = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}purchased-electricity/${id}/`;
    const res = await axios.delete(url, config);
    return res;
};

export const deleteTransportation = async (id) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}transportation/${id}/`;
    const res = await axios.delete(url, config);
    return res;
};

export const updateStationaryCombustion = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}stationary-combustion/${data.id}/`;
    const res = await axios.patch(url, data, config);
    return res;
};

export const updateMobileCombustion = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}mobile-combustion/${data.id}/`;
    const res = await axios.patch(url, data, config);
    return res;
};

export const updateRefrigerant = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}refrigerant/${data.id}/`;
    const res = await axios.patch(url, data, config);
    return res;
};

export const updatePurchasedElectricity = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}purchased-electricity/${data.id}/`;
    const res = await axios.patch(url, data, config);
    return res;
};

export const updateTransportation = async (data) => {
  const config = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };
  const url = `${emissionsApiUrl}transportation/${data.id}/`;
    const res = await axios.patch(url, data, config);
    return res;
};

export const getGreenDashboard = async () => {
  const config = {
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const url = `${emissionsUrl}green-dashboard`;
  const res = await axios.get(url, config);
  return res;
}