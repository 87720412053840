import { Box, Grid, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import {
  BarPlot,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer,
  axisClasses,
} from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function ResumenEmisiones({ emissions, xs, title }) {
  const [co2History, setCo2History] = useState(null);

  useEffect(() => {
    if (emissions) co2Summary(emissions);
  }, [emissions]);

  const co2Summary = (data) => {
    const co2Obj = [];
    Object.keys(data).forEach((key) => {
      data[key].forEach((el) => {
        co2Obj.push({ co2: el.co2, year: el.year });
      });
    });
    const res = Array.from(
      co2Obj.reduce(
        (m, { year, co2 }) => m.set(year, (m.get(year) || 0) + co2),
        new Map()
      ),
      ([year, co2]) => ({ year, co2 })
    );
    setCo2History(res);
  };
  return (
    <>
      {co2History && co2History.length > 0 && (
        <Grid item xs={xs} sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: 200,
            }}
          >
            <Typography variant="subtitle1" color={grey[600]}>
              {title}
            </Typography>
            <ResponsiveChartContainer
              dataset={co2History}
              xAxis={[
                {
                  dataKey: "year",
                  scaleType: "band",
                  id: "years",
                },
              ]}
              yAxis={[
                {
                  id: "co2e",
                },
              ]}
              series={[
                {
                  type: "bar",
                  dataKey: "co2",
                  yAxisKey: "co2e",
                  color: green[500],
                  categoryGapRatio: 0.6,
                  label: "Emisiones",
                },
                {
                  type: "line",
                  dataKey: "co2",
                  color: green[800],
                  label: "Linea base",
                },
              ]}
              margin={{ left: 150, right: 150 }}
              sx={{
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  transform: "translate(-25px, 0)",
                },
                [`.${axisClasses.right} .${axisClasses.label}`]: {
                  transform: "translate(30px, 0)",
                },
              }}
            >
              <LinePlot />
              <BarPlot />
              <ChartsXAxis position="bottom" axisId="years" />
              <ChartsYAxis axisId="co2e" position="left" />
            </ResponsiveChartContainer>
          </Box>
        </Grid>
      )}
    </>
  );
}

ResumenEmisiones.defaultProps = {
  xs: 12,
  emissions: [],
  title: "",
};

ResumenEmisiones.prototype = {
  xs: PropTypes.number,
  emissions: PropTypes.array,
  title: PropTypes.string,
};

export default ResumenEmisiones;
