import { GoogleMap, useGoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useRef, useState, memo } from "react";
import mapStyles from "../../../mapStyles";

const containerStyle = {
    width: '97vw',
    height: '80vh'
}

const center = {
    lat: 28.639106006376863,
    lng: -106.07329128181523
}

function MapsRender({children}) {
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'polygons-script',
        googleMapsApiKey: "AIzaSyDS7B0ipY1sGfZ73n2F0M8JMECPygPzLcM"
    })

    const [map, setMap] = useState(null)

    // const onLoad = useCallback(function callback(map){
    //     const bounds = new window.google.maps.LatLngBounds(center)
    //     map.setZoom(9)
    //     setMap(map)
    // },[])

    // const onUnmount = useCallback(function callback(map) {
    //     setMap(null)
    // },[])

  return isLoaded ? (
    <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={mapOptions}
        onRightClick={(map)=>{console.log(map.latLng.lat(),map.latLng.lng())}}
        zoom={14}
    >
        {children}
    </GoogleMap>
  ) : <></>
}

const mapOptions = {
    styles:mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    zoom:12,
    minZoom: 6,
    maxZoom: 18
}

export default memo(MapsRender)