import axios from "axios";
import { errorValidation, getToken } from "../utils/Utils";


const entregablesUrl = `${window.location.origin.replace(
  /\:(?:[0-9]){4}/,
  ":8000"
)}/entregables/`;

export const getEmissionsPerBuilding = async (data) => {
    const config = {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      };
      const url = `${entregablesUrl}emissions-per-building`;
  // try {
    const res = await axios.post(url, data, config);
    return res;
  // } catch (error) {
  //   errorValidation(error);
  // }
}