import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DropDown from "../../../components/DropDown";
import { getEdificios } from "../../../api/Core.api";
import LoadingContext from "../../../context/LoadingContext";
import { insertAnalisisPreliminar } from "../../../api/EconomiaCircular.api";
import { enqueueSnackbar } from "notistack";
import { errorHandler } from "../../../utils/ErrorHandler";

function AnalisisPreliminarForm({ setStepCount, stepCount, edificioId }) {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [formData, setFormData] = useState({
    nombre: "",
    num_empleados: "",
    facturacion: "",
    actividad_principal: "",
    pertence_organismo_empresarial: "",
    relacionado_otros_negocios: "",
    cual_relacion: "",
    diagrama_proceso_productivo: null,
    edificioId: "",
  });
  // const [edificio, setEdificio] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  // useEffect(() => {
  //   const loadEdificios = async () => {
  //     try {
  //       const res = await getEdificios("analisisPreliminar");
  //       setEdificio(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   loadEdificios();
  // }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      edificioId:edificioId,
      [e.target.name]: e.target.hasOwnProperty("checked")
        ? e.target.checked
        : e.target.value,
    });
  };

  const handleSubmit = async () => {
    startLoading("Guardando datos");
    try {
      const res = await insertAnalisisPreliminar(formData);
      stopLoading();
      enqueueSnackbar({
        variant: "success",
        message: "Registro guardado exitosamente",
      });
      setStepCount(stepCount + 1);
    } catch (error) {
      stopLoading();
      console.log(error);
      setErrorMessage(errorHandler(error));
    }
  };
  return (
    <>
      <CardContent>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Typography variant="h5">Informacion General de la Empresa</Typography>
          <FormControl fullWidth>
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("nombre")}
              // error={false}
              id="nombre"
              name="nombre"
              label={"1. Nombre"}
              type="text"
              value={formData.nombre}
              size={"small"}
              onChange={handleChange}
              fullWidth
              variant="standard"
              helperText={errorMessage && errorMessage.hasOwnProperty("nombre")&&errorMessage.nombre}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("num_empleados")}
              // error={false}
              id="num_empleados"
              name="num_empleados"
              label={"2. Numero de empleados"}
              type="number"
              value={formData.num_empleados}
              size={"small"}
              onChange={handleChange}
              variant="standard"
              fullWidth
              helperText={errorMessage && errorMessage.hasOwnProperty("num_empleados")&&errorMessage.num_empleados}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("facturacion")}
              // error={false}
              id="facturacion"
              name="facturacion"
              label={"3. Facturación"}
              type="text"
              value={formData.facturacion}
              size={"small"}
              onChange={handleChange}
              variant="standard"
              fullWidth
              helperText={errorMessage && errorMessage.hasOwnProperty("facturacion")&&errorMessage.facturacion}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("actividad_principal")}
              // error={false}
              id="actividad_principal"
              name="actividad_principal"
              label={"4. Actividad Principal"}
              type="text"
              value={formData.actividad_principal}
              size={"small"}
              onChange={handleChange}
              variant="standard"
              fullWidth
              helperText={errorMessage && errorMessage && errorMessage.hasOwnProperty("actividad_principal")&&errorMessage.actividad_principal}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("pertence_organismo_empresarial")}
              // error={false}
              id="pertence_organismo_empresarial"
              name="pertence_organismo_empresarial"
              label={"5. ¿Pertenece la empresa a algún organismo empresarial?"}
              type="text"
              value={formData.pertence_organismo_empresarial}
              size={"small"}
              onChange={handleChange}
              variant="standard"
              fullWidth
              helperText={errorMessage && errorMessage && errorMessage.hasOwnProperty("pertence_organismo_empresarial")&&errorMessage.pertence_organismo_empresarial}
            />
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("relacionado_otros_negocios")}
              // error={false}
              id="relacionado_otros_negocios"
              name="relacionado_otros_negocios"
              label={"6. ¿La empresa tiene relación relevante con otros negocios en la zona?"}
              type="text"
              value={formData.relacionado_otros_negocios}
              size={"small"}
              onChange={handleChange}
              variant="standard"
              fullWidth
              helperText={errorMessage && errorMessage && errorMessage.hasOwnProperty("relacionado_otros_negocios")&&errorMessage.relacionado_otros_negocios}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.pertence_organismo_empresarial}
                  name="pertence_organismo_empresarial"
                  id="pertence_organismo_empresarial"
                  onChange={handleChange}
                />
              }
              label="¿Pertenece la empresa a algún organismo empresarial?"
            /> */}
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={formData.relacionado_otros_negocios}
                  name="relacionado_otros_negocios"
                  id="relacionado_otros_negocios"
                  onChange={handleChange}
                />
              }
              label="¿La empresa tiene relación relevante con otros negocios en la zona?"
            /> */}
            <TextField
              error={errorMessage && errorMessage.hasOwnProperty("cual_relacion")}
              // error={false}
              id="cual_relacion"
              name="cual_relacion"
              label={"7. ¿Cuál?"}
              type="textarea"
              value={formData.cual_relacion}
              size={"small"}
              onChange={handleChange}
              variant="standard"
              fullWidth
              multiline
              minRows={4}
              helperText={errorMessage && errorMessage.hasOwnProperty("cual_relacion")&&errorMessage.cual_relacion}
            />
            {/* <DropDown
              // error={errorMessage.hasOwnProperty("edificioId")}
              error={false}
              id={"edificioId"}
              label={"Edificio"}
              value={formData.edificioId}
              onChange={handleChange}
              data={edificio.map((e) => ({ label: e.nick_name, value: e.id }))}
              size={"small"}
              variant="standard"
              helperText="*Si el edificio no aparece en el listado es porque ya existe un registro con dicho edificio"
            /> */}
          </FormControl>
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant="outlined" color="info" onClick={handleSubmit}>
          Continuar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setStepCount(stepCount + 1);
          }}
        >
          Omitir
        </Button>
        <Button variant="outlined" color="success" onClick={()=>{setStepCount(8)}}>
          Finalizar
        </Button>
      </CardActions>
    </>
  );
}

export default AnalisisPreliminarForm;
