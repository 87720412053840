import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import BarraColores from "../components/BarraColores";
import CuuLogo from "../assets/principal/greencity-white.png";
import backgroundImg from "../assets/principal/fondo-verde.png";
import pcImg from "../assets/principal/pc.png";
import comFosilImg from "../assets/principal/comfosil.png";
import energiaImg from "../assets/principal/energia.png";
import residuosImg from "../assets/principal/residuos.png";
// import energyIcon from "../assets/principal/energia.png"

export default function Home() {
  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        sx={{
          // backgroundColor: green[500],
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#FFFFFF",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          gap: "2rem",
          px: "5rem",
          py: "3rem",
        }}
      >
        <Grid container spacing={3} gap={4}>
          <Grid item xs={12} sx={{mx:4,textAlign:{xs:"center",sm:"left"}}}>
            <img src={CuuLogo}style={{width:"100%", height:"auto", maxWidth:200}} alt="logo" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">
              ¡Bienvenido a Calculadora de emisiones de CO2 "Chihuahua Green"!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="justify">
              Esta innovadora calculadora te ayudara a medir, dar seguimiento y
              gestionar de manera sencilla y eficiente las emisiones de gases de
              efecto invernadero (GEI), de tu empresa u organizacion, para
              reducir sus impactos negativos hacia el medio ambiente, fomentando
              asi la sostenibilidad en nuestro estado.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" textAlign="center" p={4}>
          La información que debes brindar para obtener estos beneficios es:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={3}
          alignContent="center"
          justifyContent="center"
          gap={4}
        >
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              mx:{xs:4},
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "#FFFFFF",
              borderRadius: "15px",
              p: 4,
              backgroundColor: "#01AED9",
              gap:4,
            }}
          >
            <img src={energiaImg} alt="" style={{width:"100%",height:"auto",maxWidth:100}}></img>
            <Typography variant="h5" align="center">
              Consumo de energia
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              mx:{xs:4},
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "#FFFFFF",
              borderRadius: "15px",
              p: 4,
              backgroundColor: "#E01483",
              gap:4,
            }}
          >
            <img src={comFosilImg} alt="" style={{width:"100%",height:"auto",maxWidth:100}}></img>
            <Typography variant="h5" align="center">
              Consumo de combustibles fosiles
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              mx:{xs:4},
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "#FFFFFF",
              borderRadius: "15px",
              p: 4,
              backgroundColor: "#F89C29",
              gap:4,
            }}
          >
            <img src={residuosImg} alt="" style={{width:"100%",height:"auto",maxWidth:100}}></img>
            <Typography variant="h5" align="center">
              Consumo en trasporte y gestion de residuos
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center" p={4}>
          Así obtendrás informes personalizados, claros y concisos, para que
          tengas claro, año con año, cuáles han sido tus acciones para reducción
          de emisiones. Esta información estará resguardada bajo los más altos
          estándares de confidencialidad.
        </Typography>
      </Grid>
      <BarraColores />
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} sx={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
            <img src={pcImg} alt="pc" style={{width:"100%",height:"auto",maxWidth:600}} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          >
            <Typography variant="h3" sx={{textAlign:{xs:'center',sm:'left'}}}>¿Qué obtienes?</Typography>
            <Typography variant="h4" sx={{textAlign:{xs:'center',sm:'left'}}}>Reporte final</Typography>
            <Typography variant="body1" sx={{textAlign:{xs:'justify',sm:'justify'}}}>
              El reporte (entregable) final te servirá para{" "}
              <strong>
                comunicar tus logros medioambientales a tus “stakeholders”
              </strong>{" "}
              y al público en general; así demostrarás tu compromiso con la
              sostenibilidad, de manera transparente y confiable, elevando así
              la reputación y nivel de aceptación de tu compañía respecto a sus
              clientes.
            </Typography>
            <Typography variant="body1" sx={{textAlign:{xs:'justify',sm:'justify'}}}>
              Estamos listos para ayudarte a transitar hacia actividades
              responsables con el medio ambiente.
            </Typography>
            <Typography variant="h5" sx={{textAlign:{xs:'justify',sm:'justify'}}}>¡Enhorabuena!</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="success"
            href="/emissions/stationary"
          >
            Empezar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
