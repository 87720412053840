import { Circle, Marker } from "@react-google-maps/api";

function Stain({ title, center, radius, options }) {
  return (
    <>
      <Circle center={center} radius={radius} options={options} />
      <Marker position={center} label={title} icon={'deleted'}/>
    </>
  );
}

export default Stain;
